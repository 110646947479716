<form class="example-form">
    <mat-form-field class="example-full-width" appearance="outline" [ngClass]="{'mat-form-field-invalid': isInvalid}">
        <mat-label>{{placeholder | translate}}</mat-label>
        <input matInput [matAutocomplete]="auto" #cityHotelSearchInput id="searchInput" name="searchInput" [value]="cityHotel?.name" (change)="onChanged()" [(ngModel)]='inputValue' placeholder="{{inputplaceholder | translate}}">
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete">
        <div *ngIf="isOpenAutoComplete">
            <ng-container *ngIf="searchedCityHotels.length > 0; else noItems">
                <mat-option class="multiline-mat-option point-search" *ngFor="let city of searchedCityHotels;  let i = index" (onSelectionChange)="selectCity(city)">
                    <a class="icon-type" [ngSwitch]="city.type">
                        <span *ngSwitchCase="1" class="material-icons-round app-f25 app-grey"> apartment </span>
                        <span *ngSwitchCase="2" class="material-icons-round app-f25 app-grey"> place </span>
                        <span *ngSwitchCase="3" class="material-icons-outlined app-f25 app-grey"> king_bed </span>
                    </a>
                    <div class="point-search-city">
                        <span> {{ city?.name | truncate}} </span>
                        <small *ngIf="city.description">{{city.description}}</small>
                    </div>
                </mat-option>
            </ng-container>
            <ng-template #noItems>
                <mat-option disabled>
                    <span>
                        {{'autocomplete-components.no-city-found' | translate}}
                    </span>
                </mat-option>
            </ng-template>
        </div>
    </mat-autocomplete>
</form>