import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
import { NavigationService } from '@shared/services/navigation.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Institution } from 'src/app/shared/models/institution.model';
import Messages from '../enums/messages.enum';
import { NotificationService } from '../services/notification.service';
import { Currencie } from './../../shared/models/currencie.model';
import { UserSession } from './../models/user-session.model';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  public isMobile = '1';
  resquestCount = 0;

  constructor(
    private translateService: TranslateService,
    private loaderService: NgxSpinnerService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private langaugeService: LanguageService,
    protected deviceDetectorService: DeviceDetectorService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userSession: UserSession = JSON.parse(localStorage.getItem('userSessionToken'));
    const institution: Institution = JSON.parse(sessionStorage.getItem('institution'));
    const currencie: Currencie = JSON.parse(sessionStorage.getItem('currencie'));
    const aplicationName = JSON.parse(sessionStorage.getItem('aplicationName'));
    this.isMobile = (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) ? '2' : '1';

    let languageIdGlobal = this.langaugeService.CurrentilyLanguageId ?? 1;


    if (request.url !== 'https://jsonip.com') {
      const clonedReq = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${!!userSession ? userSession.token : ''}`)
          .set('Language', !!languageIdGlobal ? `${languageIdGlobal}` : '1')
          .set('Currencie', !!currencie ? `${currencie?.currencyTypeId}` : '1')
          .set('Platform', 'WEBAPP')
          .set('InstituitionId', !!institution ? `${institution.id}` : '')
          .set('AgentId', !!institution ? `${institution.agent.id}` : '')
          .set('ApplicationAccessType', this.isMobile)
          .set('ApplicationName', aplicationName ? aplicationName : '')
      });
      this.resquestCount++;
      return this.verifyRequest(clonedReq, next);
    } else {
      return next.handle(request);
    }

    // if (request.method === 'POST' || request.method === 'PUT') {
    //   this.shiftDates(request.body);
    // }
  }

  private verifyRequest(requisicao: HttpRequest<any>, handlerHttp: HttpHandler): any {
    return handlerHttp.handle(requisicao).pipe(tap(res => {
      if (res instanceof HttpResponse) {
        this.resquestCount--;
        if (res.url.includes('/api/hotel/search')) {
          setTimeout(() => {
            this.loaderService.hide();
          }, 3200);
        } else {
          if (this.resquestCount === 0) {
            this.loaderService.hide();
          }
        }
      }
    }),
      catchError(err => {
        this.resquestCount--;
        this.loaderService.hide();
        if (!!err && !!err.error.Error) {
          switch (err.error.Error) {
            case 'NullReferenceException':
              this.notificationService.messageWarn(this.translateService.instant(Messages.ERROR_INTERNAL));
              this.navigationService.navigation(['/home'], true);
              break;
            case 'BadRequestException':
              this.notificationService.messageWarn(this.translateService.instant(Messages.ERROR_INTERNAL));
              // this.navigationService.navigation(['/home'], true);
              break;
            case 'InternalServerError':
              this.notificationService.messageWarn(this.translateService.instant(Messages.ERROR_INTERNAL));
              this.navigationService.navigation(['/home'], true);
              break;
            // case 'NotFoundException':
            //   this.notificationService.messageWarn(this.translateService.instant(Messages.NOT_FOUND));
            //   this.navigationService.navigation(['/home'], true);
            //   break;
            default:
              break;
          }
        }

        throw err;
      })
    );
  }

  shiftDates(body): any {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = new Date(
          Date.UTC(value.getFullYear(),
            value.getMonth(),
            value.getDate(),
            value.getHours(),
            value.getMinutes(),
            value.getSeconds()
          ));
      } else if (typeof value === 'object') {
        this.shiftDates(value);
      }
    }
  }

}
