<div class="modal-header">
  <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{ 'modal-confirmation.share-cart.title' | translate }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float mb-3">
  <div class="column">
    <div class="col-sm-12" *ngIf="hasCopied">
      <div class="alert alert-success" role="alert">
        {{'messages.success.cart-copied' | translate}}
      </div>
    </div>
    <div class="col-sm-12">
      <div class="text-center app-f18 text-justify font-weight-bold">
        {{ 'modal-confirmation.share-cart.text' | translate }}
      </div>
    </div>
    <div class="mt-3 mb-2">
      <button
        class="btn app-f12 app-btn app-btn-round w-100 d-flex justify-content-between align-items-center app-h40"
        (click)="shareAgency()">
        {{ link }}
        <span class="pe-7s-copy-file app-f22"></span>
      </button>
    </div>
  </div>
</div>