import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
export class DateUtil {

  public static getDaysBetweenDates(start: Date, end: Date): number {
    const diffInMs = Math.abs(end.getTime() - start.getTime());
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  public static convertDateStringToDate(date: string, format: string, placeholderFormat?: string): Date {
    const splitDate: string[] = date.split('/');

    if (splitDate.length > 1) {
      if (format === '00/00/0000') {
        if (placeholderFormat && placeholderFormat === 'mm/dd/yyyy') {
          return new Date(+splitDate[2], +splitDate[0] - 1, +splitDate[1], 0, 0);
        } else {
          return new Date(+splitDate[2], +splitDate[1] - 1, +splitDate[0], 0, 0);
        }
      } else {
        return new Date(+splitDate[0], +splitDate[1] - 1, +splitDate[2], 0, 0);
      }
    } else {
      if (format === '00/00/0000') {
        if (placeholderFormat && placeholderFormat === 'mm/dd/yyyy') {
          const month: any = splitDate[0].slice(0, 2);
          const day: any = splitDate[0].slice(2, 4);
          const year: any = splitDate[0].slice(4, 8);
          return new Date(+year, +month - 1, +day, 0, 0);
        } else {
          const day: any = splitDate[0].slice(0, 2);
          const month: any = splitDate[0].slice(2, 4);
          const year: any = splitDate[0].slice(4, 8);
          return new Date(+year, +month - 1, +day, 0, 0);
        }
      } else {
        const day: any = splitDate[0].slice(0, 4);
        const month: any = splitDate[0].slice(4, 6);
        const year: any = splitDate[0].slice(6, 8);
        return new Date(+year, +month - 1, +day, 0, 0);
      }

    }
  }

  public static convertYearMonthDayToDate(year: number, month: number, day: number): Date {
    return new Date(year, month - 1, day, 0, 0);
  }

  public static ngbDateToJsDate(date: NgbDateStruct): Date {
    const jsDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
    jsDate.setUTCFullYear(date.year);
    return jsDate;
  }

  public static jsDateToUTC(date): Date {
    const d = new Date(date);
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
  }

  public static jsDateToNgbDate(date: Date): NgbDateStruct {
    return { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1, day: date.getUTCDate() };
  }

  public static jsDateToString(date: Date, format: string, placeholderFormat?: string): string {

    if (date) {
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const yyyy = date.getFullYear();
      if (format === '00/00/0000') {
        if (placeholderFormat && placeholderFormat === 'mm/dd/yyyy') {
          return mm + '/' + dd + '/' + yyyy;
        } else {
          return dd + '/' + mm + '/' + yyyy;
        }
      } else {
        return yyyy + '/' + mm + '/' + dd;
      }
    }
  }

}
