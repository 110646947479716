import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DateUtil } from 'src/app/core/utils/date-util';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
@Component({
  selector: 'app-modal-map-mobile',
  templateUrl: './modal-map-mobile.component.html'
})
export class ModalMapMobileComponent extends BaseComponent implements OnInit {

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  center: google.maps.LatLngLiteral;
  type: google.maps.MapTypeId.HYBRID;
  markers: any[] = [];
  filter: any;
  filterSearch: any;

  hotelMapInfo: {
    name?: string,
    totalPerNight?: number,
    total?: number,
    numberOfNigth?: number
  };

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  orderSummaryPackage: any;
  packageSearch: any;
  packageCartId: string;
  hotelSearchRange: any;
  isPackage = false;

  ngOnInit(): void {

    this.orderSummaryPackage = JSON.parse(sessionStorage.getItem('orderSummaryPackage'));
    this.packageSearch = JSON.parse(sessionStorage.getItem('packageSearch'));
    if (!!this.packageSearch) {
      this.packageCartId = JSON.parse(sessionStorage.getItem('packageCartId'));
      this.hotelSearchRange = JSON.parse(sessionStorage.getItem('hotelSearchRange'));
      this.isPackage = true;
    }

    if (this.markers.length > 0) {
      this.center = {
        lat: this.markers[0].position.lat,
        lng: this.markers[0].position.lng,
      };
    }
    this.bsModalRef.setClass(this.modalCss);
  }

  getNumTravelers(): any {
    if (this.orderSummaryPackage) {
      return this.orderSummaryPackage.summaryOfStay.numberOfAdults +
        this.orderSummaryPackage.summaryOfStay.numberOfChild;
    }
  }

  openInfoWindow(marker: MapMarker, info: any): void {
    this.hotelMapInfo = info;
    this.infoWindow.open(marker);
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  search(): void {
    this.bsModalRef.content.callback(true);
  }

  convertJsDateToUTC(date: Date): Date {
    return DateUtil.jsDateToUTC(date);
  }

}
