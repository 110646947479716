<div class="modal-header">
  <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">
    {{ 'checkout-cart.pricing-details' | translate }}
  </h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float mb-3" style="min-height: 400px;">
  <div class="d-flex flex-column">
    <ng-container *ngFor="let farePassenger of price.farePassengerPrices">
      <div class="d-flex justify-content-between align-items-center mt-4">
        <label class="mb-0 app-f16 app-black">
          Tarifa {{ 'general.for' | translate }} {{ labelPassenger(farePassenger.passengerTypeCode) | translate }}
        </label>
        <label class="mb-0 app-f16 app-black">
          {{ farePassenger.priceByType | currencyFormat:currencie.country }}
        </label>
      </div>
      <!-- <div class="d-flex justify-content-between align-items-center mt-1">
        <label class="mb-0 app-f14 app-black font-weight-normal">
          Taxa por {{ labelPassenger(farePassenger.passengerTypeCode) }}
        </label>
        <label class="mb-0 app-f14 app-black font-weight-normal">
          {{ farePassenger.taxByType | currencyFormat:currencie.country }}
        </label>
      </div> -->
    </ng-container>
    <div class="d-flex justify-content-between align-items-center mt-4">
      <label class="mb-0 app-f16 app-black font-weight-bold app-pointer" [ngbPopover]="popTax" placement="right">
        {{ 'checkout-cart.tax' | translate }} <span class="icon-info-circled-alt app-blue"></span>
      </label>
      <ng-template #popTax>
        <div class="d-flex flex-column">
          {{ 'checkout-cart.tax-fare' | translate }}
        </div>
      </ng-template>
      <label class="mb-0 app-f16 app-black font-weight-bold">
        {{ price.tax | currencyFormat:currencie.country }}
      </label>
    </div>
    <div class="app-divider mt-4"></div>
    <div class="d-flex justify-content-between align-items-center mt-3">
      <label class="mb-0 app-f18 app-black font-weight-bold">
        {{ 'checkout-cart.final-value' | translate }}
      </label>
      <label class="mb-0 app-f18 app-black font-weight-bold">
        {{ price.totalPrice | currencyFormat:currencie.country }}
      </label>
    </div>
  </div>
</div>