export let productsMockNational = [
  {
    benefits: [
      {
        coverageValue: 150000,
        description: 'Despesas médicas e/ou hospitalares até',
        value: 'R$100mil',
        hasCoverage: false,
        id: true,
        order: 1,
      },
      {
        coverageValue: 1500,
        description: 'Despesas odontológicas até',
        value: 'R$1,2mil',
        hasCoverage: false,
        id: true,
        order: 2,
      },
      {
        coverageValue: 30000,
        description: 'Despesa médica por Covid-19 até',
        value: 'R$56,4mil',
        hasCoverage: false,
        id: true,
        order: 3,
      },
      {
        coverageValue: 60000,
        description: 'Traslado médico até',
        value: 'R$50mil',
        hasCoverage: false,
        id: true,
        order: 4,
      },
      {
        coverageValue: 1500,
        description: 'Despesas farmacêuticas até',
        value: 'R$1,5mil',
        hasCoverage: false,
        id: true,
        order: 5,
      },
      {
        coverageValue: 2500,
        description: 'Retorno atencipado do segurado até',
        value: 'R$2mil',
        hasCoverage: false,
        id: true,
        order: 6,
      },
      {
        coverageValue: 1500,
        description: 'Perda de bagagem em viagem até ',
        value: 'R$2mil',
        hasCoverage: false,
        id: true,
        order: 7,
      },
    ],
    fare: true,
    fareByDays: true,
    id: '5376719',
    name: 'Com seguro Nacional+',
    totalBookingWithInsurance: true,
    installments: []
  },
  {
    benefits: [
      {
        coverageValue: 800000,
        description: 'Despesas médicas e/ou hospitalares até',
        value: 'R$30mil',
        hasCoverage: false,
        id: true,
        order: 1,
      },
      {
        coverageValue: 800,
        description: 'Despesas odontológicas até',
        value: 'R$600',
        hasCoverage: false,
        id: true,
        order: 2,
      },
      {
        coverageValue: 30000,
        description: 'Sem cobertura de despesa médica por Covid-19',
        value: '',
        hasCoverage: false,
        id: false,
        order: 3,
      },
      {
        coverageValue: 40000,
        description: 'Traslado médico até',
        value: 'R$30mil',
        hasCoverage: false,
        id: true,
        order: 4,
      },
      {
        coverageValue: 1000,
        description: 'Despesas farmacêuticas até',
        value: 'R$800',
        hasCoverage: false,
        id: true,
        order: 5,
      },
      {
        coverageValue: 2000,
        description: 'Retorno atencipado do segurado até',
        value: 'R$1,5mil',
        hasCoverage: false,
        id: true,
        order: 6,
      },
      {
        coverageValue: 1500,
        description: 'Perda de bagagem em viagem',
        value: 'R$1mil',
        hasCoverage: false,
        id: true,
        order: 7,
      },
    ],
    fare: true,
    fareByDays: true,
    id: '5376725',
    name: 'Com seguro Nacional',
    totalBookingWithInsurance: true,
    installments: []
  }
];

