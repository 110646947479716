import { InstitutionService } from 'src/app/shared/services/institution.service';
import { Component, Inject, Injector, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import ConfigurationEnum from 'src/app/core/enums/configuration.enum';
import { AuthService } from '../../services/auth.service';
import { CurrencieService } from '../../services/currencie.service';
import { NavigationService } from '../../services/navigation.service';
import { ModalLanguageCurrencyComponent } from './../../components/modal-language-currency/modal-language-currency.component';
import { ModalLoginComponent } from './../../components/modal-login/modal-login.component';
import { Currencie } from './../../models/currencie.model';
import { Institution } from './../../models/institution.model';
import { agentConfiguration } from '@shared/agentConfiguration';
import { LanguageModel } from '@shared/models/language.model';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from '@shared/services/language.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() institution: Institution;
  @Input() currencie: Currencie;

  currentNavigate = '';
  modalRef: BsModalRef;
  defaultBackgroundHeader = true;
  isLoggedIn = false;
  logoHeader: string;
  colorHeader: string;
  agentImgProfile: string;
  isHome = false;
  private userSub: Subscription;
  private cartSubscription: Subscription;
  backgroundProfile = true;

  currencies: Currencie[] = [];
  languageId: string;
  languages: LanguageModel[] = [];
  language: LanguageModel = { codeIso: '' };
  currencieId: number;
  cartLink: string;
  isOwner = false;
  userJwt: any;
  token: string;
  urlRestrictArea: string;
  disableCurrency = false;
  disableCurrencyConfig = false;

  skeletonLoader = false;

  configs: any;
  instiSubscription: Subscription;
  disableCurrencySubscription: Subscription;
  currencieSubscription: Subscription;

  showTemplateInstitution = false;
  showTemplateAgent = false;

  constructor(
    injector: Injector,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private navigationService: NavigationService,
    private bsLocaleService: BsLocaleService,
    private authService: AuthService,
    private currencieService: CurrencieService,
    private instiService: InstitutionService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.institution = JSON.parse(sessionStorage.getItem('institution'));
    this.loadingPresentationInstitutionAgent(this.institution);

    setTimeout(() => {
      this.skeletonLoader = true;
      this.currencie = JSON.parse(sessionStorage.getItem('currencie'));
    }, 2500);

    this.authService.autoLogin();
    this.token = this.authService.getToken();
    this.userJwt = this.authService.decodePayloadJWT();
    if (this.userJwt) {
      if (this.userJwt.IsAgencyOwner === 'True') {
        this.isOwner = true;
        this.checkTermsAndConditions();
      }
      this.userJwt.ImageProfile = encodeURI(this.userJwt.ImageProfile);
      this.urlRestrictArea = 'http://localhost:4201' + '/login/authenticated/' + this.authService.getToken();
    }

    this.instiSubscription = this.instiService.getInstitutionPropagate().subscribe(institution => {
      this.configs = agentConfiguration(institution.agent.configurations);
      if (this.configs.AllowChangeCurrency === 'false') {
        this.disableCurrency = true;
      } else {
        this.disableCurrency = false;
      }
    });

    this.disableCurrencySubscription = this.instiService.getDisableCurrencyPropagate().subscribe(disable => {
      if (disable) {
        this.disableCurrency = true;
      } else {
        this.disableCurrency = false;
      }
    });

    this.userSub = this.authService.user.subscribe(user => {
      this.isOwner = false;
      this.isLoggedIn = !!user ? true : false;

      this.token = this.authService.getToken();
      this.userJwt = this.authService.decodePayloadJWT();
      if (this.userJwt) {
        if (this.userJwt.IsAgencyOwner === 'True') {
          this.isOwner = true;
        }
        this.userJwt.ImageProfile = encodeURI(this.userJwt.ImageProfile);
        this.urlRestrictArea = 'http://localhost:4201' + '/login/authenticated/' + this.authService.getToken();
      }
    });

    this.currencies = this.currencieService.loadCurrencies();
    this.language.codeIso = this.translateService.getDefaultLang();
    this.languages = JSON.parse(sessionStorage.getItem('languages'));
    this.languages?.map(item => item.icon = `assets/img/${item.icon}`);
    this.language = this.languages.find(language => language.codeIso === this.language.codeIso);

    this.currencieSubscription = this.currencieService.subscCurrencie.subscribe((result: any) => {
      this.currencie = result;
    });
  }

  checkTermsAndConditions(): void {
    const user = this.authService.decodePayloadJWT();
    if (user.IsAgencyOwner === 'True' && user.AcceptedTermsAndContract === 'False') {
      this.authService.logout();
      this.navigationService.navigation(['/home'], true);
    }
  }

  private loadingPresentationInstitutionAgent(institution): void {
    if (!institution?.loadAgentTemplate) {
      this.showTemplateInstitution = true;
      this.showTemplateAgent = false;
    } else if (institution?.loadAgentTemplate && !institution?.agent.isDefaultAgent) {
      this.showTemplateInstitution = false;
      this.showTemplateAgent = true;
    } else if (institution?.loadAgentTemplate && institution?.agent.isDefaultAgent) {
      this.showTemplateInstitution = true;
      this.showTemplateAgent = false;
    }
  }

  private loadingConfInstitution(): void {
    this.logoHeader = this.institution.configurations.find(element => element.name === ConfigurationEnum.LOGO_HEADER).value;
    this.colorHeader = this.institution.configurations.find(element => element.name === ConfigurationEnum.HEADER_COLOR).value;
    this.agentImgProfile = this.institution.agent.profilePictureUrl;
  }

  reloadPage(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([location.pathname]);
  }

  redirectHome(): void {
    this.navigationService.navigation(['/home'], true);
  }

  redirectUserArea(): void {
    this.navigationService.navigation(['/user-area'], true);
  }

  redirectUserReservations(): void {
    this.navigationService.navigation(['/user-reservations'], true);
  }

  changeLanguage(language: LanguageModel): void {
    this.language = language;
    this.currencie = this.currencie;
    this.currencie.defaultLanguage = language?.codeIso;
    this.translateService.use(language?.codeIso);
    this.translateService.setDefaultLang(language?.codeIso);
    this.bsLocaleService.use(language?.codeIso);
    sessionStorage.setItem('currencie', JSON.stringify(this.currencie));
    this.languageService.setLanguageId(language?.id);
    localStorage.setItem('lang', this.language.codeIso);
    this.document.documentElement.lang = language?.codeIso;
    this.redirectHome();
  }

  changeCurrencie(currencie: any): void {
    this.currencie = currencie;
    this.language = this.language;
    this.currencie.defaultLanguage = this.language?.codeIso;
    this.translateService.use(this.language?.codeIso);
    this.translateService.setDefaultLang(this.language?.codeIso);
    this.bsLocaleService.use(this.language?.codeIso);
    sessionStorage.setItem('currencie', JSON.stringify(currencie));
    this.currencieService.propagateCurrencieToSearch(this.currencie);
  }

  goCart(): void {
    this.navigationService.navigation([this.cartLink]);
  }

  openModalLogin(): void {
    this.modalRef = this.modalService.show(ModalLoginComponent,
      {
        backdrop: false,
        ignoreBackdropClick: true,
        initialState: {
          languages: this.languages,
          language: this.language,
          callback: (result: boolean, language: LanguageModel) => {
            if (result) {
              this.isLoggedIn = true;
              this.modalRef.hide();
            }
            if (language) {
              this.language = language;
            }
          }
        }
      });
  }

  openModalLanguageCurrency(): void {
    this.modalRef = this.modalService.show(ModalLanguageCurrencyComponent,
      {
        backdrop: false,
        ignoreBackdropClick: true,
        initialState: {
          language: this.language?.codeIso,
          currencie: this.currencie,
          callback: (currencie: Currencie, language: LanguageModel) => {
            if (!!currencie || !!language) {
              this.language = language;
              this.currencie = currencie;
              this.currencie.defaultLanguage = language.codeIso;
              this.translateService.use(language.codeIso);
              this.translateService.setDefaultLang(language.codeIso);
              this.bsLocaleService.use(language.codeIso);
              sessionStorage.setItem('currencie', JSON.stringify(currencie));
              this.reloadPage();
            }
            this.modalRef.hide();
          }
        }
      });
  }

  onLogout(): void {
    this.isLoggedIn = false;
    this.isOwner = false;
    this.authService.logout();
    this.navigationService.navigation(['/home'], true);
  }

  ngOnChanges(): void {
    if (!!this.institution) {
      this.loadingConfInstitution();
    }
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
    this.cartSubscription.unsubscribe();
    this.instiSubscription.unsubscribe();
    this.disableCurrencySubscription.unsubscribe();
    this.currencieSubscription.unsubscribe();
  }
}
