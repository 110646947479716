import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'src/app/core/components/base/base-component';

@Component({
  selector: 'app-modal-cancellation-info',
  templateUrl: './modal-cancellation-info.component.html',
  styleUrls: ['./modal-cancellation-info.component.css']
})
export class ModalCancellationInfoComponent extends BaseComponent implements OnInit {

  informations: string[];
  title: string;
  img:string;

  constructor(injector: Injector, public bsModalRef: BsModalRef) {
    super(injector);
   }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
    //this.img = this.informations[0];
    console.log(this.informations)
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null, null);
    this.bsModalRef.hide();
  }

}
