<div class="container">

  <div class="card-corner">
      <div class="flight-description">
          <span class="icon-flight-1" [ngClass]="{'reverse': journey?.journeyNumber > 1}"> </span>
          <span>{{ (journey?.journeyNumber === 1 ? 'flight-list.label.go' : 'flight-list.label.back') | translate }}</span>
      </div>
      <span>{{ dateConverted | dateLanguageFormat: currencie.defaultLanguage: 'EEE d MMM'}}</span>
  </div>

  <div class="company-logo">
      <span *ngIf="!journey.marketingAirline.pathLogo">{{ journey?.marketingAirline?.name }}</span>
      <span *ngIf="journey.marketingAirline.pathLogo">
        <img [src]="journey?.marketingAirline.pathLogo">
      </span>
  </div>

  <div class="more-detail app-pointer" (click)="expandModalFlightDetailContent()">
      <span> {{ 'flight-list.label.flight-detail-label' | translate }} </span>
      <span class="icon-angle-down"></span>
  </div>

  <div class="departure-column">
      <div class="airport-station">
          <span class="city">{{journey?.departure.city}}</span>
          <span class="iata">{{journey?.departure.iata}}</span>
      </div>
      <span class="flight-time">{{journey?.departure.time.hour | number:'2.0'}}:{{journey?.departure.time.minute | number:'2.0' }}</span>
  </div>

  <div class="flight-column" [ngbPopover]="popConnection" triggers="mouseenter:mouseleave" placement="top">
      <div>
          <label *ngIf="journey?.numberOfStops >= 1">
              {{ journey?.numberOfStops }}
              {{ journey?.numberOfStops == 1 ? ('flight-list.label.connection' | translate) :  ('flight-list.label.connections' | translate) }}
          </label>
          <label *ngIf="journey?.numberOfStops == 0">
              {{ 'flight-list.label.direct-flight' | translate }}
          </label>
      </div>
      <span class="line-connection"></span>
      <span>{{journey?.flyingTime.hour}}h:{{journey?.flyingTime.minute | number:'2.0'}}m</span>
  </div>

  <div class="arrival-column" [ngbPopover]="daysDiffPop" [disablePopover]="showDaysDiffPop" triggers="mouseenter:mouseleave">
      <div class="airport-station">
          <span class="city">{{journey?.destination.city}}</span>
          <span class="iata">{{journey?.destination.iata}}</span>
      </div>
      <span class="flight-time">
          {{journey?.destination.time.hour | number:'2.0'}}:{{journey?.destination.time.minute | number:'2.0'}} 
          <span class="days-diff">{{daysDiff > 0 ? '+'+daysDiff : ''}}</span>
      </span>
  </div>
  <!-- 
<div class="up-sell">
  <button class="btn app-btn-outline-blue font-weight-normal app-f14-16 w-100" (click)="openModalFlightFare()" [disabled]="!journey.allowUpsell">
    {{ 'flight-fare-modal.buttons.add-more' | translate }}
    <span class="icon-angle-right"></span>
  </button>
</div> -->

  <div class="baggages-column" [ngbPopover]="baggageAllowed" triggers="mouseenter:mouseleave">
      <div class="baggages-icon">
          <app-custom-icon name="backpack" [size]="isMobile ? 20 : 30" [ngClass]="{'active': handBag}"></app-custom-icon>
          <app-custom-icon name="baggage" [size]="isMobile ? 30 : 50" [ngClass]="{'active': baggage}"></app-custom-icon>
      </div>
      <span class="icon-info-circled-alt"></span>
      {{ bagUnitType}} {{ bagTypeDescription }}
  </div>

  <div class="detail-column" *ngIf="flightDetailExpanded">
      <div class="flight-detail">
          <app-flight-detail-content [path]="path" [journey]="journey"></app-flight-detail-content>
      </div>
  </div>

</div>

<ng-template #daysDiffPop>
  <div class="popover-container">
      <span>{{ 'flight-list.label.will-arrive' | translate }} {{ daysDiffDate | dateLanguageFormat: currencie.defaultLanguage: 'EEE d MMM'}}</span>
  </div>
</ng-template>

<ng-template #baggageAllowed>
  <div class="popover-container">
      <div *ngFor="let bag of bagList" [ngClass]="bag.iconName">
          <app-custom-icon [name]="bag.iconName" size="25" [ngClass]="{'active': bag.quantity > 0 || bag.weight > 0}"></app-custom-icon>
          <div>
              <span>{{bag.quantity}} {{ bag.description }}</span>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #popConnection>
  <div class="d-flex flex-column">
      <ng-container *ngFor="let segment of journey.segments;let i = index;let first = first;">
          <div class="d-flex justify-content-center align-items-center mt-3 mb-3" *ngIf="segment.connectionTime">
              <label class="mb-0 app-blue app-f22 font-weight-normal">
                  <span class="icon-info-circled-alt"></span>
              </label>
              <label class="mb-0 app-black app-f10-12">
                  {{ 'flight-list.label-pop-connection.connection-in' | translate }}
                  <b> {{ segment.departure.name }} </b>
                  {{ 'flight-list.label-pop-connection.waiting-for' | translate }}
                  <b> {{ segment.connectionTime.hour }}h {{ segment.connectionTime.minute }}m </b>
              </label>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-3 mb-3" *ngIf="!segment.connectionTime && !first">
              <label class="app-blue app-f10-12 text-center text-decoration-underline ml-1 mr-1">
                  {{ 'flight-list.label-pop-connection.warning' | translate }}
              </label>
          </div>
          <div class="d-flex">
              <label class="app-black app-f10-12 mb-0">
                  {{ 'flight-list.label-pop-connection.flight' | translate }}
                  {{ segment.operatingAirline.iata }}{{ segment.flightNumber }} -
                  {{ segment.operatingAirline.icao }}
              </label>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-1">
              <label class="app-black app-f10-12 font-weight-bold mb-0">
                  {{ segment.departure.iata }} > {{ segment.destination.iata }}
              </label>
          </div>
      </ng-container>
  </div>
</ng-template>