import { Injectable, Injector, Output } from '@angular/core';
import { Institution } from '@shared/models/institution.model';
import { Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService extends BaseService<Institution, number> {

  institution: Institution;
  disableCurrencySubject = new Subject<any>();
  institutionSubject = new Subject<Institution>();

  constructor(protected injector: Injector) {
    super(injector, '/institution');
  }

  public getConfigs(url: string, ip?: string): Observable<any> {
    return this.get(`/configuration?url=${url}&ip=${ip}`, true);
  }

  public getCountries(): Observable<any> {
    return this.get('/countries', true);
  }

  public getAgents(): Observable<any> {
    const clientIp = localStorage.getItem('client-ip');
    return this.get(`/agents?ip=${clientIp}`, true);
  }

  public getAgentsPaginated(search: string, hashOrder: string, page: number): Observable<any> {
    return this.get(`/agents-paginated?hashOrder=${hashOrder}&page=${page}&search=${search}`, true);
  }

  sendDisableCurrencyPropagate(disable: boolean): any {
    this.disableCurrencySubject.next(disable);
  }

  getDisableCurrencyPropagate(): Observable<any> {
    return this.disableCurrencySubject.asObservable();
  }

  sendInstitutionPropagate(institution: Institution): any {
    this.institution = institution;
    this.institutionSubject.next(institution);
  }

  getInstitutionPropagate(): Observable<any> {
    return this.institutionSubject.asObservable();
  }

  getInstitution(): Institution {
    return this.institution;
  }

}
