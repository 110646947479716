<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{'modal-send-code.title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="form-group">
      <label>{{'modal-send-code.label.description' | translate}}</label>
      <input type="email" class="form-control" [(ngModel)]="email" placeholder="{{'modal-send-code.placeholder.email' | translate}}"
        name="email" id="email">
      <!-- <i class="icon_mail_alt"></i> -->
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary ml-1" (click)="sendEmail()">{{'modal-send-code.button.send' | translate}}</button>
</div>
