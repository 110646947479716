import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;
@Component({
  selector: 'app-modal-fare-rule',
  templateUrl: './modal-fare-rule.component.html',
  styleUrls: ['./modal-fare-rule.component.css']
})
export class ModalFareRuleComponent extends BaseComponent implements OnInit, AfterViewInit {

  informations: any[];
  tempInformations: any[];
  
  constructor(injector: Injector, public bsModalRef: BsModalRef) {
    super(injector);
  }

  ngOnInit(): void {
    this.tempInformations = this.informations;
    this.informations = [];
    for(var i=0;i<this.tempInformations.length; i++){
      if(this.tempInformations[i].type == 'Flight' && (this.tempInformations[i].linkToAirlineFareRules != null && this.tempInformations[i].linkToAirlineFareRules != "")){
        this.informations.push(this.tempInformations[i])
      }
    }
    for(var i=0;i<this.tempInformations.length; i++){
      if(this.tempInformations[i].type == "Hotel"){
        this.informations.push(this.tempInformations[i])
      }
    }
    const sizeModal = this.isMobile ? 'modal-dialog-centered full-screen-modal' : 'modal-dialog-centered modal-lg';
    this.bsModalRef.setClass(sizeModal);
  }

  ngAfterViewInit(): void {
    $('.modal').on('scroll', () => {
      const threshold = 60;

      if ($('.modal').scrollTop() > threshold) {
        $('.fixed-header').addClass('affixed');
      }
      else {
        $('.fixed-header').removeClass('affixed');
      }
    });

    $('.modal').on('hide.bs.modal', (e) => {
      $('.fixed-header').removeClass('affixed');
    });

    $('.fixed-header button').click(() => {
      $('.modal').modal('hide');
    });
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null, null);
    this.bsModalRef.hide();
  }
}
