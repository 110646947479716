<div class="row" *ngIf="!!hotel && !!filter">
    <div class="col-sm-12">
        <div class="card mb-4 p-sm-3 ml-1 mr-1" [ngClass]="{'specific-hotel-card': specificHotelSelected}">

            <div *ngIf="specificHotelSelected" class="specific-hotel-label">
                {{'hotel-list.label.interest-hotel' | translate}}
            </div>

            <div class="card-header p-0 mt-2 mb-2 ml-3 mr-3">
                <div class="d-flex w-100" style="text-align: start;">
                    <p class="label-hotel-name font-weight-bold">{{ hotel.name }}</p>
                </div>
                <div class="d-flex flex-row justify-content-center align-items-center w-100">
                    <p class="label-hotel-address font-weight-bold m-0 app-grey text-left">
                        <i class="pe-7s-map-marker"></i> {{ hotel.address.addressLine }}
                    </p>
                    <div class="cat_star ml-auto w-50 text-right"><i class="app-icon-star app-icon-star-w15" *ngFor="let star of numberArray(hotel.stars)"></i>
                    </div>
                </div>
            </div>

            <!-- card body -->
            <div class="card-body p-0">

                <!-- carousel mobile -->
                <ngx-skeleton-loader count="1" animation="progress" appearance="circle" [theme]="{ width: '100%', height: '200px', 'border-radius': '0px', margin: '0px', 'margin-bottom': '10px' }" *ngIf="!skeletonLoader">
                </ngx-skeleton-loader>
                <div class="container-carousel d-xl-none d-lg-none d-md-none d-sm-flex d-xs-flex">
                    <a class="btn prev" id="prev-{{hotel.hotelId}}">
                        <span class="carrousel-previous-next d-flex align-items-center justify-content-center">
                            <span class="carousel-control-prev-icon" *ngIf="skeletonLoader"></span>
                        </span>
                    </a>
                    <a class="btn next" id="next-{{hotel.hotelId}}">
                        <span class="carrousel-previous-next ml-auto d-flex align-items-center justify-content-center">
                            <span class="carousel-control-next-icon" *ngIf="skeletonLoader"></span>
                        </span>
                    </a>
                    <div id="owl-carousel-{{hotel.hotelId}}" class="owl-carousel owl-theme">
                        <div class="item" *ngFor="let image of hotel.images | slice:0:10; let i = index">
                            <a class="grid_item" *ngIf="skeletonLoader">
                                <figure class="figure-destination">
                                    <img [src]="image.url" class="img-fluid" alt="" width="800" height="600">
                                </figure>
                            </a>
                        </div>
                    </div>
                </div> <!--  fim carousel mobile -->

                <!-- tabs dos quartos -->
                <div class="tab-content d-xl-none d-lg-none d-md-none d-sm-flex d-xs-flex" id="pills-tabContent">
                    <!-- tab 1 principal -->
                    <ng-container *ngFor="let item of filterRooms; let index = index;">
                        <div class="tab-pane fade show" [ngClass]="{'active': index === activeIndex}" id="h-{{item.externalRoomId}}" role="tabpanel" aria-labelledby="h-{{item.externalRoomId}}-tab">
                            <!-- conteudo quarto -->
                            <div class="ml-3 mr-3" style="margin-top: -10px;">
                                <div class="d-flex flex-row w-100 mt-sm-4">
                                    <p class="app-f18 font-weight-bold text-left">{{ item.roomName | titlecase }}</p>
                                </div>

                                <div class="d-flex flex-row w-100 mt-1">
                                    <p class="app-f12 font-weight-500 app-blue app-pointer">
                                        {{'hotel-card.label.accommodates' | translate}} {{item.occupancy?.MaxAdults}}
                                        {{'hotel-card.label.adult' | translate}}(s)
                                        {{'hotel-card.label.and' | translate}}
                                        {{item.occupancy?.MaxChild}}
                                        {{'hotel-card.label.child' | translate}}
                                    </p>
                                </div>

                                <div class="d-flex flex-row justify-content-between w-100 mt-2">
                                    <div class="col">
                                        <div class="row mt-1" [ngSwitch]="item.mealPlan">
                                            <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50" *ngSwitchCase="1">
                                                {{'hotel-card.label.allinclusive' | translate}}
                                            </div>
                                            <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50" *ngSwitchCase="2">
                                                {{'hotel-card.label.breakfast' | translate}}
                                            </div>
                                            <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50" *ngSwitchCase="3">
                                                {{'hotel-card.label.fullboard' | translate}}
                                            </div>
                                            <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50" *ngSwitchCase="4">
                                                {{'hotel-card.label.halfboard' | translate}}
                                            </div>
                                        </div>
                                        <div class="row mt-1">
                                            <div class="border-box-blue app-btn-round-8 app-f12 font-weight-500 w-65">{{item.numberRooms}}
                                                {{'hotel-card.label.bedroom' | translate}}{{item.numberRooms > 1 ? 's' : ''}}</div>
                                        </div>
                                        <div class="row mt-1" (click)="openModalAmenities(item.amenities, item.roomDescription, item)">
                                            <label class="app-f12 font-weight-500 app-blue ml-1 mt-1 app-pointer">
                                                {{'hotel-card.label.view-more' | translate}} <i class="icon-angle-down"></i>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col d-flex flex-column justify-content-end align-items-end" style="margin-top: -8px;">
                                        <div class="row">
                                            <label class="app-black m-0">
                                                <span class="app-f26 font-weight-bold" *ngIf="!orderSummaryPackage">
                                                    {{ item?.price?.totalPerNight | currencyFormat:currencie.country }}
                                                </span>
                                            </label>
                                        </div>
                                        <div class="row" *ngIf="!orderSummaryPackage">
                                            <label class="app-f12 font-weight-normal app-black m-0">
                                                {{'hotel-card.label.per-night' | translate}}{{'hotel-list.label.slash-room' | translate}}
                                            </label>
                                        </div>
                                        <div class="row">
                                            <label class="app-f12 app-orange text-right text-decoration-underline mt-1 w-100" (click)="openModalInformation(item.cancelPenalties.description)" *ngIf="item?.cancelPenalties?.daysBeforeCheckinFreeCancellation > 0 && item?.cancelPenalties?.freeCacelation">
                                                <span class="icon-info-circled-alt"></span> {{'hotel-card.label.partially-refundable' |
                        translate}}
                                            </label>
                                            <label class="app-f12 app-orange text-right text-decoration-underline mt-1 w-100" (click)="openModalInformation(item.cancelPenalties.description)" *ngIf="item?.cancelPenalties?.daysBeforeCheckinFreeCancellation === 0 && !item?.cancelPenalties?.freeCacelation">
                                                <span class="icon-info-circled-alt"></span> {{'hotel-card.label.non-refundable' | translate}}
                                            </label>
                                            <label class="app-f12 app-green text-right text-decoration-underline mt-1 w-100" (click)="openModalInformation(item.cancelPenalties.description)" *ngIf="item?.cancelPenalties?.daysBeforeCheckinFreeCancellation === 0 && item?.cancelPenalties?.freeCacelation">
                                                <span class="icon-info-circled-alt"></span> {{'hotel-card.label.free-cancellation' | translate}}
                                            </label>
                                            <label class="app-f12 app-blue text-right text-decoration-underline mt-1 w-100" (click)="openModalHostingPolicies()">
                                                <span class="icon-info-circled-alt"></span> {{'hotel-card.label.hosting-detail' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- fim conteudo quarto -->

                        </div> <!-- fim tab 1 principal -->
                    </ng-container>

                </div> <!-- fim tabs dos quartos -->

                <!-- controle tabs quartos -->
                <div class="row mt-3 d-xl-none d-lg-none d-md-none d-sm-flex d-xs-flex" *ngIf="filterRooms.length > 1">
                    <div class="col-sm-12 d-flex justify-content-center align-items-center">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <ng-container *ngFor="let item of filterRooms; let i = index;">
                                <li class="nav-item">
                                    <a class="nav-link" [ngClass]="{'active': i === activeIndex}" id="h-{{item.externalRoomId}}-tab" data-toggle="pill" href="#h-{{item.externalRoomId}}" role="tab" aria-controls="h-{{item.externalRoomId}}" aria-selected="false" (click)="changeTab(i)">{{i+1}}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div> <!-- controle tabs quartos -->

            </div> <!-- fim card body -->

            <!-- card footer -->
            <div class="card-footer d-xl-none d-lg-none d-md-none d-sm-flex d-xs-flex">
                <div class="d-flex flex-row justify-content-center align-items-center w-100">
                    <p class="app-f12 mr-auto">
                        <span class="font-weight-bold" *ngIf="!orderSummaryPackage">{{ roomRateTotalPrice | currencyFormat:currencie.country }}</span>
                        <span class="font-weight-bold" *ngIf="orderSummaryPackage">
                            {{ (roomRateTotalPrice - orderSummaryPackage?.priceHotel) >= 0 ? '+' : '' }}
                            {{ (roomRateTotalPrice - orderSummaryPackage?.priceHotel) | currencyFormat:currencie.country }}
                        </span>
                        {{'hotel-card.label.for' | translate}}
                        <span class="app-blue font-weight-bold">
                            {{ roomRateNumberOfNigth }} {{'hotel-card.label.night' | translate}}{{ roomRateNumberOfNigth > 1 ? 's' : '' }}
                        </span>
                    </p>
                    <p class="app-f12 ml-auto">
                        {{'hotel-card.label.taxes-and-fees' | translate}}
                    </p>
                </div>

                <div class="d-flex flex-row justify-content-between align-items-center w-100 mt-3">
                    <button class="app-btn w-100 mr-1 app-f12 font-weight-bold p-0 app-h30" (click)="redirectHotelDetail(hotel)">
                        {{'hotel-card.button.choose-another' | translate}}
                    </button>
                    <button class="app-btn app-btn-orange ml-1 w-100 app-f12 font-weight-bold p-0 app-h30" (click)="redirectCheckout(hotel)">
                        {{'hotel-card.button.reserve-option' | translate}}
                    </button>
                </div>
            </div> <!-- fim card footer -->

        </div>
    </div>
</div>
