import { BlockedFilters } from './../../models/blocked-filters';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IFlightSearchKey } from '@shared/models/flight/flight-search-key.model';

@Component({
  selector: 'app-modal-flight-filter',
  templateUrl: './modal-flight-filter.component.html'
})
export class ModalFlightFilterComponent extends BaseComponent implements OnInit {

  modal = 1;
  flightSearchKey: IFlightSearchKey;
  marketingAirlinesAvailable: any;
  arrivalAirportsAvailable: any;
  departureAirportsAvailable: any;
  outboundFlight: any;
  blockedFilters: BlockedFilters;
  showFiltersOutbound: any;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  clear(): void {
    this.bsModalRef.content.callback(this.flightSearchKey, true);
    this.bsModalRef.hide();
  }

  search(): void {
    this.bsModalRef.content.callback(this.flightSearchKey, false);
    this.bsModalRef.hide();
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

}
