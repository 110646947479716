import { BaseComponent } from 'src/app/core/components/base/base-component';
import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import Messages from 'src/app/core/enums/messages.enum';
import { Airport } from '../../models/airport.model';
import { AirportService } from '../../services/airport.service';

@Component({
  selector: 'app-autocomplete-airport',
  templateUrl: './autocomplete-airport.component.html',
  styleUrls: ['./autocomplete-airport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutoAirportCompleteComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild('airportSearchInput', { static: false }) airportSearchInput: ElementRef;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() inputplaceholder: string;
  @Input() isDeparture: boolean;
  @Input() isPresentLocation: boolean;
  @Input() isInvalid: boolean;

  @Output() selectAirport = new EventEmitter();

  airport: Airport;
  searchedAirports: Airport[] = [];
  isFetchingAirports = false;
  isOpenAutoComplete = false;
  isSelected = false;
  @ViewChildren('anchor') anchorList: QueryList<ElementRef>;
  @ViewChildren('anchorSub') anchorListSub: QueryList<ElementRef>;
  lastIndex: number;

  constructor(
    private airportService: AirportService,
    private translateService: TranslateService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void { }

  focusOnAirport(e): void {
    this.anchorList.first.nativeElement.focus();
    e.preventDefault();
  }

  focusListAirport(e, i): void {
    const elementRef = this.anchorList.find((item, index) => index === i);
    if (elementRef) {
      elementRef.nativeElement.focus();
      e.preventDefault();
    }
  }

  onKeyUp(): void {
    fromEvent(this.airportSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(800),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      if (text.length > 0) {
        this.isSelected = false;
        this.isFetchingAirports = true;
        this.airportService.search(text, this.isDeparture).subscribe((result: any) => {
          this.isOpenAutoComplete = true;
          this.isFetchingAirports = false;
          const array = result.data.airports;
          let hasChild = false;
          for (let index = 0; index < array.length; index++) {
            if (array[index].airports) {
              hasChild = true;
              const element = array[index].airports;
              // tslint:disable-next-line: prefer-for-of
              for (let y = 0; y < element.length; y++) {
                element[y].isChild = true;
                array.splice(index + 1, 0, element[y]);
              }
            }
          }
          this.searchedAirports = array;
        });
      } else {
        this.isOpenAutoComplete = false;
        this.searchedAirports = [];
      }
    });
  }

  onSearchChange(searchValue: string): void {
    if (!searchValue && this.airport) {
      this.airport.iata = null;
      this.airport.name = null;
      this.airport.city = null;
      this.isOpenAutoComplete = false;
      this.selectAirport.emit(this.airport);
    }
  }

  clickAirport(airport: Airport): void {

    if (airport.iata) {
      this.isSelected = true;
      this.isOpenAutoComplete = false;
      if (airport.name) {
        airport.name = airport.isIataCity ? airport.city + ' - ' + this.translateService.instant('search-flight.all') : `${airport.city} - (${airport.iata} - ${airport.name})`;
      } else {
        airport.name = airport.isIataCity ? airport.city + ' - ' + this.translateService.instant('search-flight.all') : `${airport.city} - (${airport.iata} - ${airport.name})`;
      }

      this.airport = airport;

      if (this.isDeparture) {
        this.airport.isDepartureIataCity = airport.isIataCity;
        this.airport.isArrivalIataCity = false;
      } else {
        this.airport.isArrivalIataCity = airport.isIataCity;
        this.airport.isDepartureIataCity = false;
      }

      this.airport.isDeparture = this.isDeparture;
      this.selectAirport.emit(airport);
    } else {
      this.notificationService.messageWarn(this.translateService.instant(Messages.CHOOSE_AIRPORT, { city: `${airport.city}` }));
      this.airport = airport;
    }

  }

  removeAirport(): void {
    this.airport = null;
  }

  ngAfterViewInit(): void {
    this.onKeyUp();
    if (this.isMobile) {
      setTimeout(() => {
        this.airportSearchInput.nativeElement.focus();
      }, 500);
    }
  }
}
