import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-information',
  templateUrl: './modal-information.component.html',
})
export class ModalInformationComponent extends BaseComponent implements OnInit {

  informations: string[];
  title: string;

  constructor(injector: Injector, public bsModalRef: BsModalRef) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null, null);
    this.bsModalRef.hide();
  }
}
