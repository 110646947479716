export default class Messages {

  // Messages ERROR
  public static readonly REQUIRED_FIELDS = 'messages.required.fields';
  public static readonly ERROR_INTERNAL = 'messages.error.internal';
  public static readonly INVALID_FIELD = 'messages.invalid.field';
  public static readonly INVALID_FIELD_FLIGHT = 'messages.invalid.field-flight';
  public static readonly INVALID_CODE = 'messages.invalid.code';
  public static readonly INVALID_CHILD_AGE = 'messages.invalid.child-age';
  public static readonly FULL_NAME_TOO_LONG = 'messages.invalid.full-name';

  // Messages WARNING
  public static readonly NOT_FOUND = 'messages.warning.notfound';
  public static readonly AGENT_NOT_FOUND = 'messages.warning.agentNotfound';
  public static readonly CHOOSE_AIRPORT = 'messages.warning.choose-airport';
  public static readonly SHARED_CART = 'messages.warning.shared-cart';
  public static readonly CHILDREN_AGE = 'messages.warning.children-age';


  // Messages SUCCESS
  public static readonly SUCCESS_SAVE = 'messages.success.save';
  public static readonly SUCCESS_UPDATE = 'messages.success.update';
  public static readonly SUCCESS_HOTEL_BOOKING = 'messages.success.hotel-booking';
  public static readonly CART_COPIED = 'messages.success.cart-copied';

  // Messages Session expiration
  public static readonly FLIGHT_SESSION_EXPIRATION = 'messages.warning.flightExpired';
  public static readonly HOTEL_SESSION_EXPIRATION = 'messages.warning.hotelExpired';

  // Messages Create Booking
  public static readonly CREATE_FLIGHT_BOOKING = 'messages.warning.create-booking';
  public static readonly DEFAULT_CREATE_BOOKING = 'messages.warning.default-create-booking';
  public static readonly GENERATE_HOTEL_BOOKING = 'messages.warning.create-hotel-booking';
  public static readonly UNEXPECTED_ERROR = 'messages.warning.unexpected-error';
  public static readonly PIX_ERROR = 'messages.warning.pix-error';
  public static readonly NOT_FOUND_FILTER = 'messages.warning.not-found-filter';
  public static readonly NOT_FOUND_DOCUMENT = 'messages.warning.not-found-document';
}

