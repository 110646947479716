import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

const WEEK_DAY_MONDAY = 'component.datepicker.weekdays.monday';
const WEEK_DAY_TUESDAY = 'component.datepicker.weekdays.tuesday';
const WEEK_DAY_WEDNESDAY = 'component.datepicker.weekdays.wednesday';
const WEEK_DAY_THURSDAY = 'component.datepicker.weekdays.thursday';
const WEEK_DAY_FRIDAY = 'component.datepicker.weekdays.friday';
const WEEK_DAY_SATURDAY = 'component.datepicker.weekdays.saturday';
const WEEK_DAY_SUNDAY = 'component.datepicker.weekdays.sunday';
const WEEK_DAYS = [
    WEEK_DAY_MONDAY,
    WEEK_DAY_TUESDAY,
    WEEK_DAY_WEDNESDAY,
    WEEK_DAY_THURSDAY,
    WEEK_DAY_FRIDAY,
    WEEK_DAY_SATURDAY,
    WEEK_DAY_SUNDAY
];

const MONTH_JANUARY = 'component.datepicker.months.january';
const MONTH_FEBRUARY = 'component.datepicker.months.february';
const MONTH_MARCH = 'component.datepicker.months.march';
const MONTH_APRIL = 'component.datepicker.months.april';
const MONTH_MAY = 'component.datepicker.months.may';
const MONTH_JUNE = 'component.datepicker.months.june';
const MONTH_JULY = 'component.datepicker.months.july';
const MONTH_AUGUST = 'component.datepicker.months.august';
const MONTH_SEPTEMBER = 'component.datepicker.months.september';
const MONTH_OCTOBER = 'component.datepicker.months.october';
const MONTH_NOVEMBER = 'component.datepicker.months.november';
const MONTH_DECEMBER = 'component.datepicker.months.december';
const MONTHS = [
    MONTH_JANUARY,
    MONTH_FEBRUARY,
    MONTH_MARCH,
    MONTH_APRIL,
    MONTH_MAY,
    MONTH_JUNE,
    MONTH_JULY,
    MONTH_AUGUST,
    MONTH_SEPTEMBER,
    MONTH_OCTOBER,
    MONTH_NOVEMBER,
    MONTH_DECEMBER
];

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private translateService: TranslateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.translateService.instant(WEEK_DAYS[weekday - 1]);
  }

  getMonthShortName(month: number): string {
    return this.translateService.instant(MONTHS[month - 1] + '.s');
  }

  getMonthFullName(month: number): string {
    return this.translateService.instant(MONTHS[month - 1] + '.f');
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}/${date.month}/${date.year}`;
  }
}
