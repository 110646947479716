<div class="modal-header">
  <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{title}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float mb-3">
  <div class="column">
    <div class="col-sm-12">
      <div class="text-center app-f16 text-justify">
        {{ text }}
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="!createBookingError">
  <div class="col-sm-12 d-flex justify-content-between align-items-center p-0">
    <button type="button" class="btn btn-primary app-btn-orange btn-sm app-btn-round w-50 mr-1 ml-auto font-weight-bold" (click)="dismiss()">
      {{cartWasChanged ? ('modal-confirmation.cart-was-changed.buttons.cancel' | translate) : ('modal-confirmation.buttons.no' | translate) }}
    </button>
    <button type="button" class="btn btn-primary btn-sm app-btn w-50 mr-auto ml-1 font-weight-bold" (click)="accept()">
      {{cartWasChanged ? ('modal-confirmation.cart-was-changed.buttons.confirm' | translate) : ('modal-confirmation.buttons.yes' | translate) }}
    </button>
  </div>
</div>

<div class="modal-footer" *ngIf="createBookingError || isExpiredCart">
  <div class="col-sm-12 d-flex justify-content-center align-items-center p-0">
    <button type="button" class="btn btn-primary app-btn-orange btn-sm app-btn-round w-50 mr-1 font-weight-bold" (click)="accept()">
      {{ 'modal-confirmation.cart-was-changed.buttons.confirm' | translate }}
    </button>
  </div>
</div>