<div id="sign-in-dialog" class="zoom-anim-dialog ">
  <div class="small-dialog-header">
    <h3>{{'header.label.signin' | translate}}</h3>
    <button title="Close (Esc)" type="button" class="mfp-close " data-dismiss="modal" aria-label="Close" id="closeModal"
      (click)="dismiss()"></button>
  </div>
  <form [formGroup]="form">
    <div class="sign-in-wrapper mt-sm-4 mt-5">
      <ng-container *ngIf="isLogin; else forgetPassword">

        <div class="form-group">

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'modal-login.input.email' | translate}}</mat-label>
            <input matInput type="email" formControlName="email" email required>
          </mat-form-field>
        </div>
        <div class="form-group" *ngIf="isSentEmail">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'modal-login.input.code' | translate}}</mat-label>
            <input matInput formControlName="code" required minlength="6" inputmode="numeric" pattern="[0-9]*"
              appDigitOnly required>
          </mat-form-field>
        </div>

        <div class="row" *ngIf="!isSentEmail">
          <div class="col-sm-12 d-flex justify-content-center align-items-center">
            <button *ngIf="!buttonLoading" type="button"
              class="btn btn-primary app-btn-sm w-50 mx-auto font-weight-bold app-uppercase" (click)="sentEmail()">
              {{'modal-login.button.send' | translate}}
            </button>
            <button *ngIf="buttonLoading" class="btn btn-primary app-btn-sm w-50 mx-auto font-weight-bold app-uppercase"
              type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{'loader.sending' | translate}}
            </button>
          </div>
        </div>

        <div class="row" *ngIf="isSentEmail">
          <div class="col-sm-12 d-flex justify-content-between align-items-center">
            <button type="button" *ngIf="!buttonLoading"
              class="btn btn-primary btn-sm app-btn-sm app-btn-orange w-50 mx-auto font-weight-bold app-uppercase"
              (click)="sentEmail('resend')">
              {{'modal-login.button.resend' | translate}}
            </button>
            <button *ngIf="buttonLoading"
              class="btn btn-primary btn-sm app-btn-sm app-btn-orange w-50 mx-auto font-weight-bold app-uppercase"
              type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{'loader.loading' | translate}}
            </button>
            <div class="m-1"></div>
            <button type="button" class="btn btn-primary btn-sm app-btn-sm w-50 mx-auto font-weight-bold app-uppercase"
              (click)="validate()">
              {{'modal-login.button.validate' | translate}}
            </button>
          </div>
        </div>

      </ng-container>

      <ng-template #forgetPassword>
        <div id="forgot_pw">
          <div class="form-group">
            <label>Please confirm login email below</label>
            <input type="email" class="form-control" name="email_forgot" id="email_forgot">
            <i class="icon_mail_alt"></i>
          </div>
          <p>You will receive an email containing a link allowing you to reset your password to a new preferred one.</p>
          <div class="text-center">
            <button type="button" class="btn app-btn" (click)="resetPassword()">
              Reset Password
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </form>

  <div class="row d-block d-sm-none d-md-none d-lg-none d-xl-none" *ngIf="!isCheckout">
    <hr>
    <div class="col-sm-12 d-flex justify-content-between align-items-center">

      <div class="col-sm-12">

        <button type="button" class="btn btn-outline-dark app-btn-round ml-1 mr-1 w-100" id="dropdownUserMenu1"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="display:inline-block">
          <img [src]="language?.icon" [alt]="language?.codeIso" width="25px">
        </button>
        <div class="dropdown-menu app-dropdown-round dropdown-lan-cur" aria-labelledby="dropdownUserMenu1">
          <div *ngFor="let language of languages">
            <a class="dropdown-item cursor-pointer d-flex" (click)="changeLanguage(language)">
              <img class="mr-3" [src]="language?.icon" alt="EN" width="25px" style="float: right">
              {{language?.displayName}}
              <div class="divider m-1"></div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <button type="button" class="btn btn-outline-dark app-btn-round mr-1 ml-1 w-100" id="dropdownUserMenu2"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="disableCurrency">
          {{currencie.symbol + ' ' + currencie.code}}
        </button>
        <div class="dropdown-menu app-dropdown-round dropdown-lan-cur" aria-labelledby="dropdownUserMenu2">
          <a class="dropdown-item cursor-pointer" *ngFor="let currencie of currencies;let lastItem = last;"
            (click)="changeCurrencie(currencie)">
            {{currencie.symbol + ' ' + currencie.code}}
            <div class="divider m-1" *ngIf="!lastItem"></div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>