
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import ConfigurationEnum from 'src/app/core/enums/configuration.enum';
import { Institution } from '../../models/institution.model';
import { environment } from './../../../../environments/environment';
import { LoaderModel } from './model/loader.model';
import { LoaderService } from './service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {

  show = false;
  imageLoader: string;
  city: string;
  country: string;
  presentCity = false;
  label = 'loader.loading';
  institution: Institution;
  logoHeader: string;
  agentImgProfile: string;

  private subscriptionLoader: Subscription;
  private subscriptionInstitution: Subscription;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.imageLoader = environment.defaulImageLoading;

    this.subscriptionLoader = this.loaderService.loaderModel
      .subscribe((state: LoaderModel) => {
        if (!!state.institution) {
          this.loadingConfInstitution(state);
        } else {
          this.show = state.show;
        }
      });

    this.subscriptionInstitution = this.loaderService.loaderInstitution
      .subscribe((state: LoaderModel) => {
        if (!!state.institution) {
          this.loadingConfInstitution(state);
        }
      });
  }

  private loadingConfInstitution(state: LoaderModel): void {
    this.institution = state.institution;
    this.show = state.show;
    this.logoHeader = this.institution.configurations.find(element => element.name === ConfigurationEnum.LOGO_HEADER).value;
    this.agentImgProfile = this.institution.agent.profilePictureUrl;
  }

  getTemplate(): string {
    return `
      <div class="loader">
        <img src="${this.imageLoader}" class="loader__mask"/>
        <img src="${this.imageLoader}" class="loader__background"/>
      </div>
    `;
  }

  ngOnDestroy(): void {
    this.subscriptionLoader.unsubscribe();
    this.subscriptionInstitution.unsubscribe();
  }
}
