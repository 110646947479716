<div class="modal-header">
    <div class="d-flex flex-column align-items-start w-100">
        <p class="app-f12 font-weight-bold m-0" *ngIf="!isPackage">{{filterSearch.cityName}}</p>
        <p class="app-f12 font-weight-bold m-0" *ngIf="isPackage">{{packageSearch.cityName}}</p>
        <p class="app-f10 m-0" *ngIf="!isPackage">
            {{ convertJsDateToUTC(filterSearch.startDate) | dateLanguageFormat: currencie.defaultLanguage: 'd'}} -
            {{ convertJsDateToUTC(filterSearch.endDate) | dateLanguageFormat: currencie.defaultLanguage: 'd MMM'}} ,
            {{filterSearch.numberOfAdults}}
            {{'hotel-list.label.adult' | translate}}{{ filterSearch.numberOfAdults > 1 ? 's' : ''}}
            {{'hotel-list.label.and' | translate}} {{filterSearch.numberOfChild}}
            {{'hotel-list.label.children' | translate}}{{ filterSearch.numberOfChild > 1 ? 's' : ''}}
        </p>
        <p class="mb-0 app-f10" *ngIf="isPackage">
            {{ convertJsDateToUTC(hotelSearchRange.startDate) | dateLanguageFormat: currencie.defaultLanguage: 'd'}} -
            {{ convertJsDateToUTC(hotelSearchRange.endDate) | dateLanguageFormat: currencie.defaultLanguage: 'd MMM'}} ,
            {{ orderSummaryPackage.summaryOfStay.numberOfRooms}} {{ orderSummaryPackage.summaryOfStay.numberOfRooms > 1 ? ('hotel-list.label.rooms' | translate) : ('hotel-list.label.room' | translate)}},
            {{ getNumTravelers()}} {{ getNumTravelers() > 1 ? ('hotel-list.label.travelers' | translate) : ('hotel-list.label.traveler' | translate)}}
        </p>
    </div>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body--float p-0" style="max-height: calc(100vh - 65px);">

    <google-map height="92vh" width="100%" [zoom]="13" [center]="center" [mapTypeId]="type">
        <map-marker #mymarker="mapMarker" *ngFor="let marker of markers; let i = index" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfoWindow(mymarker, marker.info)">
        </map-marker>
        <map-info-window>
            <div class="d-flex flex-column">
                <h5 class="card-title app-f14">{{ hotelMapInfo?.name }}</h5>

                <div *ngIf="!isPackage && hotelMapInfo?.totalPerNight">
                    <p class="app-f14 mb-0">
                        <span class="font-weight-bold">
                            {{ hotelMapInfo?.totalPerNight | currencyFormat:currencie.country }}
                        </span>
                    </p>
                    <label class="app-f12 font-weight-normal app-black m-0">
                        {{'hotel-card.label.per-night' | translate}}{{'hotel-list.label.slash-room' | translate}}
                    </label>
                    <div class="app-divider"></div>

                    <p class="app-f12 mb-0">
                        <span class="font-weight-bold">{{ hotelMapInfo?.total | currencyFormat:currencie.country }}</span>
                        <br>
                        {{'hotel-card.label.for' | translate}}
                        <span class="app-blue font-weight-bold">
                            {{ hotelMapInfo?.numberOfNigth }} {{'hotel-card.label.night' | translate}}
                        </span>
                    </p>
                </div>
            </div>
        </map-info-window>
    </google-map>
</div>
