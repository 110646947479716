<div class="modal-header">
  <h5 class="modal-title font-weight-bold" id="exampleModalLabel">{{'modal-filter-guests.title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float">

  <div class="d-flex" *ngIf="hasErros">
    <div class="alert alert-danger w-100" role="alert" style="border-radius: 20px;">
      {{ message }}
    </div>
  </div>

  <ng-container *ngFor="let room of rooms; let i = index; let lastItem = last; let firstItem = first;">
    <div class="row m-0 mb-3 d-flex justify-content-between align-items-center">
      <label class="m-0 text-bold" style="font-size: 1rem;">
        {{'modal-filter-guests.label.room' | translate}} {{i+1}}
      </label>
      <button type="button" class="btn app-btn-outline p-0 app-blue" *ngIf="rooms.length > 1 && !firstItem"
        (click)="removeRoom(i)">
        {{'modal-filter-guests.button.remove-room' | translate}}
      </button>
    </div>
    <div class="form-group w-100-mobile">
      <div class="qtyButtons">
        <label class="f-20">{{'modal-filter-guests.label.adult' | translate}}</label>
        <button (click)="subtractAdult(room)" class="btn btn-sm btn-outline-secondary btn-outline-circle"
          [disabled]="room.numberOfAdults === 1">
          <img class="" src="assets/img/minus.svg" alt="">
        </button>
        <input type="text" name="" [value]="room.numberOfAdults">
        <button (click)="addAdult(room)" class="btn btn-sm btn-outline-secondary btn-outline-circle">
          <img class="" src="assets/img/plus.svg" alt="">
        </button>
      </div>
      <div class="qtyButtons">
        <label class="f-20">{{'modal-filter-guests.label.children' | translate}}&nbsp;<small>(0-11)</small></label>
        <button (click)="subtractChildren(room)" class="btn btn-sm btn-outline-secondary btn-outline-circle"
          [disabled]="room.numberOfChild === 0">
          <img class="" src="assets/img/minus.svg" alt="">
        </button>
        <input type="text" name="" [value]="room.numberOfChild">
        <button (click)="addChildren(room)" class="btn btn-sm btn-outline-secondary btn-outline-circle"
          [disabled]="room.arrNumberChildren?.length === 6">
          <img class="" src="assets/img/plus.svg" alt="">
        </button>
      </div>
      <div class="row qtyButtons">
        <div class="form-group col-6 col-sm-4 has-float-label"
          *ngFor="let item of room.arrNumberChildren; let i = index;">
          <label class="select-child-label" for="selectChild">{{'modal-filter-guests.label.child-age' | translate}} n°
            {{i+1}}</label>
          <select class="form-control-sm" id="selectChild" (change)="addAge($event, i, room)"
            [value]="room.agesOfChild[i]">
            <option value="null" [selected]="!room.agesOfChild[i]">
              Selecione...
            </option>
            <option [value]="0">
              {{'modal-filter-guests.button.under-one' | translate}}
            </option>
            <option [value]="1">1</option>
            <option [value]="2">2</option>
            <option [value]="3">3</option>
            <option [value]="4">4</option>
            <option [value]="5">5</option>
            <option [value]="6">6</option>
            <option [value]="7">7</option>
            <option [value]="8">8</option>
            <option [value]="9">9</option>
            <option [value]="10">10</option>
            <option [value]="11">11</option>
          </select>
        </div>
        <div class="w-100"></div>
      </div>
      <hr *ngIf="!lastItem">
    </div>
  </ng-container>

  <!-- <div class="row">
    <div class="col-sm-12 d-flex justify-content-between align-items-center">
      <button type="button" class="btn btn-primary btn-sm app-btn-sm app-btn-orange  w-50 mx-auto font-weight-bold"
        *ngIf="rooms.length > 1" (click)="removeRoom(i)">
        {{'modal-filter-guests.button.remove-room' | translate}}
      </button>
      <div *ngIf="rooms.length > 1" class="m-1"></div>
      <button type="button" class="btn btn-info btn-sm app-btn-sm w-50 mx-auto font-weight-bold"
        (click)="addAnotherRoom()" [disabled]="guestFilter.numberOfRooms === 4">
        {{'modal-filter-guests.button.add-room' | translate}}
      </button>
    </div>
  </div> -->

</div>
<div class="modal-footer">
  <div class="col-sm-12 d-flex justify-content-between align-items-center">
    <button type="button" class="btn app-btn-outline p-0 app-blue font-weight-bold"
      (click)="addAnotherRoom()"  [disabled]="guestFilter.numberOfRooms === 4">
      {{'modal-filter-guests.button.add-room' | translate}}
    </button>
    <button type="button" class="btn btn-primary btn-sm app-btn w-25 ml-auto font-weight-bold" (click)="search()">
      {{'modal-filter-guests.button.apply' | translate}}
    </button>
  </div>

</div>