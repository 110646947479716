import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { agentConfiguration } from '@shared/agentConfiguration';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import ConfigurationEnum from 'src/app/core/enums/configuration.enum';
import { Institution } from '../../models/institution.model';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() institution: Institution;

  colorFooter: string;
  about: string;
  logoInstitution: string;
  urlNavigateBeAgent: string;
  year: number = new Date().getFullYear();
  agentImgProfile: string;
  agentP2dName: string;
  policy: string;
  terms: string;
  copyRight: string;
  termsArr: any[];

  showTemplateInstitution = false;
  showTemplateAgent = false;

  constructor(injector: Injector, private translateService: TranslateService, private navigationService: NavigationService) {
    super(injector);
  }

  ngOnInit(): void {
    this.institution = JSON.parse(sessionStorage.getItem('institution'));
    if (!!this.institution) {
      this.colorFooter = this.institution.configurations.find(element => element.name === ConfigurationEnum.FOOTER_COLOR).value;
      this.about = this.institution.configurations.find(element => element.name === ConfigurationEnum.ABOUT).value;
      this.logoInstitution = this.institution.configurations.find(element => element.name === ConfigurationEnum.LOGO_HEADER).value;
      this.urlNavigateBeAgent = location.origin + `/${this.institution.agent.agencyPath}/parceirop2d`;
      this.agentImgProfile = this.institution.agent.profilePictureUrl;

      const configs = agentConfiguration(this.institution.agent.configurations);

      this.termsArr = this.institution.agent.termsToAccept.filter((element: any) => {
        return element.name !== 'PartnerContractPJ' && element.name !== 'PartnerContract' && element.name !== 'TerminationTerm';
      });

      this.terms = configs.TermsAndConditions;
      this.policy = configs.PrivacyPolicy;
      this.copyRight = configs.Copyright;
      this.loadingPresentationInstitutionAgent(this.institution);
    }
  }

  getTermNameToTranslate(name: string): string {
    switch (name) {
      case 'TermsAndConditionsPdf':
        return 'modal-terms.link-term';
        break;
      case 'PrivacyPolicy':
        return 'modal-terms.link-policy';
        break;
      case 'PartnerContract':
        return 'modal-terms.link-contract';
        break;
      case 'PartnerContractPJ':
        return 'modal-terms.link-contract';
        break;
      case 'TerminationTerm':
        return 'modal-terms.link-termination';
      default:
        break;
    }
  }

  private loadingPresentationInstitutionAgent(institution): void {
    if (!institution.loadAgentTemplate) {
      this.showTemplateInstitution = true;
      this.showTemplateAgent = false;
    } else if (institution.loadAgentTemplate && !institution.agent.isDefaultAgent) {
      this.showTemplateInstitution = false;
      this.showTemplateAgent = true;
    } else if (institution.loadAgentTemplate && institution.agent.isDefaultAgent) {
      this.showTemplateInstitution = true;
      this.showTemplateAgent = false;
    }
  }

  ngOnChanges(): void {
    if (!!this.institution) {
      this.colorFooter = this.institution.configurations.find(element => element.name === ConfigurationEnum.FOOTER_COLOR).value;
      this.logoInstitution = this.institution.configurations.find(element => element.name === ConfigurationEnum.LOGO_HEADER).value;
      this.urlNavigateBeAgent = location.origin + `/${this.institution.agent.agencyPath}/parceirop2d`;
      this.agentImgProfile = this.institution.agent.profilePictureUrl;
      this.agentP2dName = this.institution.agent.agencyName;
    }
  }
}
