<div class="modal-header">
  <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{'pix-payment.label.modal-pix.title' |
    translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float mb-3">
  <div class="column">

    <div class="col-sm-12 mt-4">
      <div class="text-center app-f20 text-justify font-weight-bold">
        {{'pix-payment.label.modal-pix.text' | translate}}
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="col-sm-12 d-flex justify-content-center align-items-center p-0">
    <button type="button" class="btn btn-primary btn-sm app-btn w-50 font-weight-bold" (click)="accept()">
      {{'pix-payment.label.modal-pix.ok' | translate}}
    </button>
  </div>
</div>