import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DateUtil } from 'src/app/core/utils/date-util';
import { CustomDateParserFormatter } from '../utils/custom-date-parser-formatter';
import { CustomDatepickerI18n } from '../utils/custom-date-picker-i18n';

declare var $: any;
@Component({
  selector: 'app-modal-datepicker-range-inline',
  templateUrl: './modal-datepicker-range-inline.component.html',
  styleUrls: ['./modal-datepicker-range-inline.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class ModalDatepickerRangeInlineComponent extends BaseComponent implements OnInit {

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  minDate;
  maxDate;
  typeSearch: string;
  isRoundTrip: boolean;
  days: number;

  constructor(
    injector: Injector,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
    const current = new Date();
    current.setDate(current.getDate() + 1);
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  ngOnInit(): void {
    const sizeModal = this.isMobile || this.deviceDetectorService.isTablet() ? 'modal-dialog-centered full-screen-modal' : 'modal-dialog-centered modal-lg';
    this.bsModalRef.setClass(sizeModal);
  }

  public onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      if (this.typeSearch === '2') {
        this.days = DateUtil.getDaysBetweenDates(DateUtil.ngbDateToJsDate(this.fromDate),
          DateUtil.ngbDateToJsDate(this.toDate));
        if (this.days > 31) {
          this.notificationService.messageWarn(this.translate.instant('component.datepicker.label.inform-thirty'));
          this.fromDate = date;
          this.toDate = null;
        }
      } else {
        this.toDate = date;
      }
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  public onDateSelectionFlightGo(date: NgbDate): void {
    this.fromDate = date;
  }

  public formatDate(): string {
    return this.formatter.format(this.fromDate) + (this.toDate ? ' - ' + this.formatter.format(this.toDate) : '');
  }

  public isHovered(date: NgbDate): any {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate): any {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate): any {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  public validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public decline(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  public accept(): void {
    if (this.typeSearch === '2') {
      if (!!this.fromDate && !!this.toDate) {
        if (this.days > 31) {
          this.notificationService.messageWarn(this.translate.instant('component.datepicker.label.inform-thirty'));
        } else {
          const arrDateRange = [
            DateUtil.ngbDateToJsDate(this.fromDate),
            DateUtil.ngbDateToJsDate(this.toDate)
          ];
          this.bsModalRef.content.callback(arrDateRange, this.typeSearch, this.isRoundTrip);
          this.bsModalRef.hide();
        }
      } else {
        this.notificationService.messageWarn(this.translate.instant('component.datepicker.label.inform-checkin-checkout'));
      }
    } else {

      if (this.isRoundTrip) {
        if (!!this.fromDate && !!this.toDate) {
          const arrDateRange = [
            DateUtil.ngbDateToJsDate(this.fromDate),
            DateUtil.ngbDateToJsDate(this.toDate)
          ];
          this.bsModalRef.content.callback(arrDateRange, this.typeSearch, this.isRoundTrip);
          this.bsModalRef.hide();
        } else {
          this.notificationService.messageWarn(this.translate.instant('component.datepicker.label.inform-date-go-back'));
        }
      } else {
        if (!!this.fromDate) {
          const arrDateRange = [
            DateUtil.ngbDateToJsDate(this.fromDate)
          ];
          this.bsModalRef.content.callback(arrDateRange, this.typeSearch, this.isRoundTrip);
          this.bsModalRef.hide();
        } else {
          this.notificationService.messageWarn(this.translate.instant('component.datepicker.label.inform-date-go'));
        }
      }

    }
  }

  onIsRoundTrip(isRoundTrip: boolean): void {
    this.isRoundTrip = !isRoundTrip;
    this.fromDate = null;
    this.toDate = null;
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

}
