import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Hotel } from 'src/app/components/hotel-list/models/hotel.model';
import { BaseService } from 'src/app/core/services/base.service';
import { HotelBooking } from './../../components/checkout/models/hotel.booking.model';

@Injectable({
  providedIn: 'root'
})
export class HotelService extends BaseService<Hotel, number> {

  // itemCart = new Subject<string>();

  constructor(protected injector: Injector) {
    super(injector, '/hotel');
  }

  public search(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/search', loader);
  }

  public searchV2(filter: any, loader?: boolean): Observable<any> {
    return this.postRetry(filter, '/v2/search', loader);
  }

  public poolSearch(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/pool-search', loader);
  }

  public detail(filter: any): Observable<any> {
    return this.post(filter, '/detail');
  }

  public booking(hotelBooking: HotelBooking): Observable<any> {
    return this.post(hotelBooking, '/booking');
  }

  public getRoom(id: number): Observable<any> {
    return this.get(`/room/${id}`);
  }

  public getSpecificHotel(hotelId: string, searchKey: string): Observable<any> {
    return this.get(`/${hotelId}/key/${searchKey}`);
  }

  public getExternalDetail(request): Observable<any> {
    return this.post(request, `/external-detail`);
  }

  // addRemoveItemCart(cartLink?: string): any {
  //   if (cartLink) {
  //     sessionStorage.setItem('cartLink', cartLink);
  //     this.itemCart.next(cartLink);
  //   } else {
  //     sessionStorage.removeItem('cartLink');
  //     this.itemCart.next();
  //   }
  // }

  // getItemCart(): any {
  //   return sessionStorage.getItem('cartLink');
  // }

}
