import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HotelSearchKey } from 'src/app/shared/models/hotel/hotel-search-key.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Currencie } from '../../models/currencie.model';
import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { fromEvent } from 'rxjs';
@Component({
  selector: 'app-modal-filter-hotel-mobile',
  templateUrl: './modal-filter-hotel-mobile.component.html',
  styleUrls: ['./modal-filter-hotel-mobile.component.css']
})
export class ModalFilterHotelMobileComponent extends BaseComponent implements OnInit {

  currencie: Currencie;
  numStar: number;
  numStarSelected: number;

  starSelectedOne = false;
  starSelectedTwo = false;
  starSelectedThree = false;
  starSelectedFour = false;
  starSelectedFive = false;

  hotelSearchKey: HotelSearchKey;
  priceRangeStart: number;
  priceRangeStop: number;

  optionsSlider: Options = {
    getPointerColor: () => '#455ED1',
    getSelectionBarColor: () => '#455ED1',
    floor: 0,
    ceil: 1000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this.currencie.symbol + value;
        case LabelType.High:
          return this.currencie.symbol + value;
        default:
          return '';
      }
    }
  };

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.currencie = JSON.parse(sessionStorage.getItem('currencie'));
    this.bsModalRef.setClass(this.modalCss);
    this.optionsSlider = { ...this.optionsSlider, floor: this.priceRangeStart, ceil: this.priceRangeStop };
  }

  setNumStar(numStar: number): void {
    if (!this.hotelSearchKey.filter.stars.includes(numStar)) {
      this.hotelSearchKey.filter.stars.push(numStar);
    } else {
      const index = this.hotelSearchKey.filter.stars.findIndex(element => element === numStar);
      this.hotelSearchKey.filter.stars.splice(index, 1);
    }
  }

  priceOrdenation(ordenation: string): void {
    if (ordenation === 'lowest') {
      this.hotelSearchKey.filter.lowestPriceOrdenation = true;
      this.hotelSearchKey.filter.highestPriceOrdenation = false;
    } else {
      this.hotelSearchKey.filter.lowestPriceOrdenation = false;
      this.hotelSearchKey.filter.highestPriceOrdenation = true;
    }
  }

  onUserChange(changeContext: ChangeContext): void {
    this.hotelSearchKey.filter.priceRangeStart = changeContext.value;
    this.hotelSearchKey.filter.priceRangeStop = changeContext.highValue;
  }

  public accept(): void {
    this.bsModalRef.content.callback(this.hotelSearchKey);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.hide();
  }

  search(): void {
    this.bsModalRef.content.callback(this.hotelSearchKey);
  }

  clear(): void {
    this.hotelSearchKey.filter = {
      hotelName: '',
      stars: [],
      priceRangeStart: null,
      priceRangeStop: null,
      bedBreakfast: false,
      fullBoard: false,
      allInclusive: false,
      halfBoard: false,
      parking: false,
      wifi: false,
      bar: false,
      pool: false,
      lowestPriceOrdenation: false,
      highestPriceOrdenation: false
    }
  }

}
