<div class="row" *ngIf="!!hotel && !!filter">
    <div class="col-sm-12">
        <div class="card mb-4 p-sm-3 ml-1 mr-1" [ngClass]="{'specific-hotel-card': specificHotelSelected}">

            <div *ngIf="specificHotelSelected" class="specific-hotel-label">
                {{'hotel-list.label.interest-hotel' | translate}}
            </div>

            <div class="card-header p-0 m-0">
                <div class="d-flex w-100" style="text-align: start;">
                    <p class="label-hotel-name font-weight-bold">{{ hotel.name }}</p>
                </div>
                <div class="d-flex flex-row justify-content-center align-items-center w-100">
                    <p class="label-hotel-address font-weight-bold m-0 app-grey">
                        <i class="pe-7s-map-marker"></i> {{hotel.address.cityName}} - {{ hotel.address.addressLine }}
                    </p>
                    <div class="cat_star ml-auto"><i class="app-icon-star app-icon-star-w15" *ngFor="let star of numberArray(hotel.stars)"></i>
                    </div>
                </div>
            </div>

            <!-- card body -->
            <div class="card-body p-0">

                <!-- galeria carousel desktop -->
                <div class="container-gallery d-xl-flex d-lg-flex d-md-flex d-sm-none d-xs-none" style="position: relative;">
                    <div id="owl-demo-{{hotel.hotelId}}" class="owl-carousel owl-theme">
                        <div class="item" *ngFor="let image of carouselDesktop | slice:0:10; let i = index">
                            <a class="grid_item">
                                <figure class="figure-destination">
                                    <img [src]="image.url" class="img-fluid" alt="" style="max-width: 100%; height: 260px; object-fit: cover; -webkit-object-fit: cover; border-radius: 8px;">
                                </figure>
                            </a>
                        </div>
                    </div>
                    <div class="customNavigation">
                        <a class="btn prev prev-dest" id="prev-{{hotel.hotelId}}">
                            <span class="carrousel-previous-next d-flex align-items-center justify-content-center">
                                <span class="carousel-control-prev-icon"></span>
                            </span>
                        </a>
                        <a class="btn next next-dest" id="next-{{hotel.hotelId}}">
                            <span class="carrousel-previous-next ml-auto d-flex align-items-center justify-content-center">
                                <span class="carousel-control-next-icon"></span>
                            </span>
                        </a>
                    </div>
                    <!-- <div class="row p-0">
            <div class="col-12">
              <ngb-carousel #carousel [interval]="4000" [pauseOnHover]="false" [pauseOnFocus]="false"
                [showNavigationArrows]="true" [showNavigationIndicators]="true" style="z-index: 9;"
                class="carousel-gallery">
                <ng-container *ngFor="let image of carouselDesktop | slice:0:10; let i = index">
                  <ng-template ngbSlide>
                    <img [src]="image.url" class="img-fluid" alt=""
                      style="max-width: 100%; height: 260px; object-fit: cover; -webkit-object-fit: cover; border-radius: 8px;">
                  </ng-template>
                </ng-container>
              </ngb-carousel>
            </div>
            <div class="col-sm p-0">
              <div class="row p-0">
                <div class="col-7 pt-0 pl-1 pr-1 pb-1">
                  <img [src]="fixedDesktop[1]?.url" class="img-fluid" alt=""
                    style="height: 125px; width: 100%; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
                </div>
                <div class="col-5 pt-0 pl-0 pr-1 pb-0">
                  <img [src]="fixedDesktop[2]?.url" class="img-fluid" alt=""
                    style="height: 127px; width: 100%; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
                </div>
                <div class="w-100"></div>
                <div class="col-5 pt-1 pl-1 pr-1 pb-0">
                  <img [src]="fixedDesktop[3]?.url" class="img-fluid" alt=""
                    style="height: 127px; width: 100%; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
                </div>
                <div class="col-7 pt-1 pl-1 pr-1 pb-0">
                  <img [src]="fixedDesktop[4]?.url" class="img-fluid" alt=""
                    style="height: 125px; width: 100%; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
                </div>
              </div>
            </div>
            <div class="col-sm pr-3">
              <img [src]="fixedDesktop[0]?.url" class="img-fluid" alt=""
                style="max-width: 100%; height: 260px; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
            </div>
          </div> -->
                </div> <!-- fim galeria carousel desktop -->

                <!-- conteudo quarto desktop -->
                <div class="row d-xl-flex d-lg-flex d-md-flex d-sm-none d-xs-none" [ngClass]="{'justify-content-end': filterRooms.length % 3 === 0, 'justify-content-between': filterRooms.length % 3 != 0 }">

                    <div class="col-sm-4 mt-sm-4 mb-3 d-flex flex-column justify-content-center align-items-start" *ngFor="let item of filterRooms; let i = index;" [ngClass]="{'border-left1': i != 0 && i != 3 }">
                        <div class="d-flex flex-row justify-content-between w-100">
                            <div>
                                <p class="app-f22 font-weight-bold text-left">{{ item.roomName | titlecase }}</p>
                            </div>
                        </div>

                        <div class="d-flex flex-row w-100">
                            <p class="app-f12 font-weight-500 app-blue">
                                {{'hotel-card.label.accommodates' | translate}} {{item.occupancy?.MaxAdults}}
                                {{'hotel-card.label.adult' | translate}}(s) {{'hotel-card.label.and' | translate}}
                                {{item.occupancy?.MaxChild}} {{'hotel-card.label.child' | translate}}
                            </p>
                        </div>

                        <div class="d-flex flex-row justify-content-between w-100 mt-3">
                            <div class="col">
                                <div class="row">
                                    <div class="border-box-blue app-btn-round-8 app-f14 font-weight-500 w-50">{{item.numberRooms}}
                                        {{'hotel-card.label.bedroom' | translate}}{{item.numberRooms > 1 ? 's' : ''}}</div>
                                </div>
                                <div class="row mt-1" [ngSwitch]="item.mealPlan">
                                    <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50" *ngSwitchCase="1">
                                        {{'hotel-card.label.allinclusive' | translate}}
                                    </div>
                                    <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50" *ngSwitchCase="2">
                                        {{'hotel-card.label.breakfast' | translate}}
                                    </div>
                                    <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50" *ngSwitchCase="3">
                                        {{'hotel-card.label.fullboard' | translate}}
                                    </div>
                                    <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50" *ngSwitchCase="4">
                                        {{'hotel-card.label.halfboard' | translate}}
                                    </div>
                                </div>
                                <div *ngIf="filterRooms.length > 1">
                                    <div class="row mt-4">
                                        <label class="app-black" *ngIf="!orderSummaryPackage">
                                            <span class="app-f24 font-weight-bold">
                                                {{ item?.price?.totalPerNight | currencyFormat:currencie.country }}
                                            </span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="!orderSummaryPackage">
                                        <label class="app-f14 font-weight-normal app-black m-0">
                                            {{'hotel-card.label.per-night' | translate}}{{'hotel-list.label.slash-room' | translate}}
                                        </label>
                                    </div>
                                </div>
                                <div class="row mt-2" *ngIf="filterRooms.length > 1">
                                    <label class="app-f16 font-weight-normal app-orange text-right text-decoration-underline app-pointer" *ngIf="item?.cancelPenalties?.daysBeforeCheckinFreeCancellation > 0 && item?.cancelPenalties?.freeCacelation" (click)="openModalInformation(item.cancelPenalties.description)">
                                        {{'hotel-card.label.partially-refundable' | translate}} <span class="icon-info-circled-alt"></span>
                                    </label>
                                    <label class="app-f16 font-weight-normal app-orange text-right text-decoration-underline app-pointer" *ngIf="item?.cancelPenalties?.daysBeforeCheckinFreeCancellation === 0 && !item?.cancelPenalties?.freeCacelation" (click)="openModalInformation(item.cancelPenalties.description)">
                                        {{'hotel-card.label.non-refundable' | translate}} <span class="icon-info-circled-alt"></span>
                                    </label>
                                    <label class="app-f16 font-weight-normal app-orange text-right text-decoration-underline app-pointer" *ngIf="item?.cancelPenalties?.daysBeforeCheckinFreeCancellation === 0 && item?.cancelPenalties?.freeCacelation" (click)="openModalInformation(item.cancelPenalties.description)">
                                        {{'hotel-card.label.free-cancellation' | translate}} <span class="icon-info-circled-alt"></span>
                                    </label>
                                </div>
                                <div class="row mt-2" (click)="openModalAmenities(item.amenities, item.roomDescription, item)">
                                    <label class="app-f16 font-weight-500 app-blue mt-1 app-pointer">
                                        {{'hotel-card.label.view-more' | translate}} <span class="icon-angle-down"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Card conteudo centro quando tem apenas 1 quarto -->
                    <div class="col-sm-4 pl-5 mt-sm-4 mb-3 d-flex flex-column justify-content-center align-items-start" *ngIf="filterRooms.length === 1">
                        <div class="row">
                            <p class="app-f24" *ngIf="!orderSummaryPackage">
                                <span class="font-weight-bold">{{ roomRateTotalPerNight | currencyFormat:currencie.country }}</span>
                            </p>
                        </div>
                        <div class="row" *ngIf="!orderSummaryPackage">
                            <label class="app-f14 font-weight-normal app-black m-0">
                                {{'hotel-card.label.per-night' | translate}}{{'hotel-list.label.slash-room' | translate}}
                            </label>
                        </div>
                        <div class="row">
                            <p class="app-f24" *ngIf="orderSummaryPackage">
                                <span class="font-weight-bold">
                                    {{ (roomRateTotalPrice - orderSummaryPackage?.priceHotel) >= 0 ? '+' : '' }}
                                    {{ (roomRateTotalPrice - orderSummaryPackage?.priceHotel) | currencyFormat:currencie.country }}
                                </span>
                            </p>
                        </div>
                        <div class="row mr-3">
                            <p class="app-f12 mt-1 text-left">
                                {{'hotel-card.label.taxes-and-fees' | translate}}
                            </p>
                        </div>
                        <div class="app-divider w-50"></div>
                        <div class="row text-left">
                            <p class="app-f24">
                                <span class="font-weight-bold app-f16" *ngIf="!orderSummaryPackage">
                                    {{ roomRateTotalPrice | currencyFormat:currencie.country }}
                                </span>
                                <br *ngIf="!orderSummaryPackage">
                                <span *ngIf="!orderSummaryPackage">
                                    {{'hotel-card.label.for' | translate}}
                                    <span class="app-blue font-weight-bold">
                                        {{ roomRateNumberOfNigth}}
                                        {{'hotel-detail.label.night' | translate}}{{ roomRateNumberOfNigth > 1 ? 's' : '' }}
                                    </span>
                                </span>
                                <span *ngIf="orderSummaryPackage">
                                    <p class="app-f14 mt-1">
                                        {{'general.label.new-pack-value' | translate}}
                                    </p>
                                </span>
                                <span class="font-weight-bold" *ngIf="orderSummaryPackage">
                                    {{ ((roomRateTotalPrice - orderSummaryPackage?.priceHotel) + orderSummaryPackage?.total) |
                  currencyFormat:currencie.country }}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="col-sm-4 mt-sm-4 mb-3 d-flex flex-column justify-content-end align-items-start">
                        <div class="d-flex flex-column justify-content-center align-items-center w-100" style="margin-bottom: 40px;" *ngIf="filterRooms.length > 1">
                            <p class="app-f20">
                                <span class="font-weight-bold" *ngIf="!orderSummaryPackage">
                                    {{ hotel.price.total | currencyFormat:currencie.country }}
                                </span>
                                <span class="font-weight-bold" *ngIf="orderSummaryPackage">
                                    {{ (hotel.price.total - orderSummaryPackage?.priceHotel) >= 0 ? '+' : '' }}
                                    {{ (hotel.price.total - orderSummaryPackage?.priceHotel) | currencyFormat:currencie.country }}
                                </span>
                                <br *ngIf="!orderSummaryPackage">
                                <span *ngIf="!orderSummaryPackage">
                                    {{'hotel-card.label.for' | translate}}
                                    <span class="app-blue font-weight-bold">
                                        {{ hotel.price.numberOfNigth }} {{'hotel-card.label.night' | translate}}
                                    </span>
                                </span>

                                <span *ngIf="orderSummaryPackage">
                                    <p class="app-f14 mt-3">
                                        {{'general.label.new-pack-value' | translate}}
                                    </p>
                                </span>
                                <span class="font-weight-bold" *ngIf="orderSummaryPackage">
                                    {{ ((hotel.price.total - orderSummaryPackage?.priceHotel) + orderSummaryPackage?.total) |
                  currencyFormat:currencie.country }}
                                </span>
                            </p>
                            <p class="app-f12 mt-1" *ngIf="!orderSummaryPackage">
                                {{'hotel-list.label.taxes-and-fees' | translate}}
                            </p>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center w-100 mb-3" *ngIf="filterRooms.length === 1">
                            <label *ngIf="filterRooms[0]?.cancelPenalties?.daysBeforeCheckinFreeCancellation > 0 && filterRooms[0]?.cancelPenalties?.freeCacelation" class="app-f16 font-weight-normal app-orange text-right text-decoration-underline app-pointer" (click)="openModalInformation(filterRooms[0].cancelPenalties.description)">
                                {{'hotel-card.label.partially-refundable' | translate}} <span class="icon-info-circled-alt"></span>
                            </label>
                            <label *ngIf="filterRooms[0]?.cancelPenalties?.daysBeforeCheckinFreeCancellation === 0 && !filterRooms[0]?.cancelPenalties?.freeCacelation" class="app-f16 font-weight-normal app-orange text-right text-decoration-underline app-pointer" (click)="openModalInformation(filterRooms[0].cancelPenalties.description)">
                                {{'hotel-card.label.non-refundable' | translate}}
                                <span class="icon-info-circled-alt"></span>
                            </label>
                            <label *ngIf="filterRooms[0]?.cancelPenalties?.daysBeforeCheckinFreeCancellation === 0 && filterRooms[0]?.cancelPenalties?.freeCacelation" class="app-f16 font-weight-normal app-green text-right text-decoration-underline app-pointer" (click)="openModalInformation(filterRooms[0].cancelPenalties.description)">
                                {{'hotel-card.label.free-cancellation' | translate}}
                                <span class="icon-info-circled-alt"></span>
                            </label>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center w-100">
                            <button class="app-btn w-100 app-f18 font-weight-bold p-0 app-h40" (click)="redirectHotelDetail(hotel)">
                                {{'hotel-card.button.choose-another' | translate}}
                            </button>
                            <button class="app-btn mt-2 app-btn-orange w-100 app-f18 font-weight-bold p-0 app-h40" (click)="redirectCheckout(hotel)">
                                {{'hotel-card.button.reserve-option' | translate}}
                            </button>
                        </div>
                    </div>

                    <div class="w-100"></div>

                </div> <!-- fim conteudo quartos desktop -->

            </div>
        </div>
    </div>
