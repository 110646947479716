<div class="modal-header">
    <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">
        {{ 'flight-fare-modal.title' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body--float" style="min-height: 400px;">

    <div class="" *ngIf="messageNotFound">
        <div class="not-found d-flex justify-content-center align-items-center">
            <h2 class="text-center">{{ 'flight-fare-modal.not-found' | translate }}</h2>
        </div>
    </div>

    <div *ngIf="loader" class="fare-loader d-flex">
        <span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
        <div class="fare-loader-text">
            {{'loader.loading' | translate}}
        </div>
    </div>

    <ng-template #flightFare let-item let-index="index">
        <div class="custom-column" [ngClass]="{'active': index === fareSelected}">

            <!-- <pre>{{item | json}}</pre> -->
            <div class="" *ngFor="let jour of item.journeys; let j = index">
                <!-- <pre>{{jour | json}}</pre> -->
                <hr *ngIf="jour.journeyNumber === 2" style="margin: 10px 0;">

                <div class="d-flex flex-column">
                    <p class="mb-0 text-center app-uppercase" *ngIf="jour.journeyNumber === 1">{{ 'flight-fare-modal.one-way' | translate }}</p>
                    <p class="mb-0 text-center app-uppercase" *ngIf="jour.journeyNumber === 2">{{ 'flight-fare-modal.round-trip' | translate }}</p>
                    <div class="custom-column-header">{{jour?.fareClass?.airlineFareFamily}}</div>
                    <div class="custom-column-header-sub">{{jour?.fareClass?.cabinClass}}</div>

                    <div class="d-flex flex-column">
                        <ng-container *ngIf="!jour?.baggagesAllowance || jour?.baggagesAllowance.length == 0; else hasBaggagesAllowance">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2">
                                <div class="w-15 text-center">
                                    <app-custom-icon name="baggage" size="30"></app-custom-icon>
                                </div>
                                <div class="w-85 text-left">
                                    <div class="d-flex flex-column">
                                        <label class="bag-text app-f14 font-weight-bold mb-0">
                                            {{ 0 }} {{ getTypeName('default') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #hasBaggagesAllowance>
                            <div *ngFor="let bag of jour?.baggagesAllowance">
                                <div class="d-flex flex-row justify-content-center align-items-center mt-2">
                                    <ng-container *ngIf="bag.unitDescription; else noDispatchedBag">
                                        <div class="w-15 text-center">
                                            <app-custom-icon name="baggage" size="30" [ngClass]="{'app-blue': bag.quantity !== 0 || bag.weight !== 0, 'app-grey': bag.quantity == 0 && bag.weight == 0}"  *ngIf="bag.typeDescription === 'Dispatch'"></app-custom-icon>
                                            <app-custom-icon name="backpack" size="25" [ngClass]="{'app-blue': bag.quantity !== 0 || bag.weight !== 0, 'app-grey': bag.quantity == 0 && bag.weight == 0}" *ngIf="bag.typeDescription === 'Hand'"></app-custom-icon>
                                        </div>
                                        <div class="w-85 text-left">
                                            <div class="d-flex flex-column">
                                                <label class="bag-text app-f13 font-weight-bold mb-0" *ngIf="bag.weight == 0">
                                                    {{ bag.quantity }} {{ getTypeName(bag.unitDescription, bag) }} {{ getTypeDescription(bag.typeDescription, bag) }}
                                                </label>
                                                <label class="bag-text app-f13 font-weight-bold mb-0" *ngIf="bag.weight > 0">
                                                    {{ bag.weight }} {{ getTypeName(bag.unitDescription, bag) }} {{ getTypeDescription(bag.typeDescription, bag) }}
                                                </label>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #noDispatchedBag>
                                        <div class="w-15 text-center">
                                            <img src="assets/img/icon-bag.png" class="" width="24px" alt="">
                                            <app-custom-icon name="backpack" size="30"></app-custom-icon>
                                        </div>
                                        <div class="w-85 text-left">
                                            <div class="d-flex flex-column">
                                                <label class="bag-text app-f13 font-weight-bold mb-0" *ngIf="bag.unitDescription === 'PC'">
                                                    {{ 0 }} {{ getTypeName(bag.unitDescription) }} {{
                                                    getTypeDescription(bag.typeDescription, bag) }}
                                                </label>
                                                <label class="bag-text app-f13 font-weight-bold mb-0" *ngIf="bag.unitDescription !== 'PC'">
                                                    {{ 0 }} {{ getTypeName(bag.unitDescription) }} {{
                                                    getTypeDescription(bag.typeDescription, bag) }}
                                                </label>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>

            </div>

            <div class="app-divider"></div>

            <div class="custom-column-header-price mt-4" *ngIf="!packageSearch">
                + {{ ( (item.price?.totalPrice - flight.price?.totalPrice) / item.price?.passengerCount) | currencyFormat:currencie.country }} /{{ 'general.pax' | translate }}
            </div>
            <div class="custom-column-header-price mt-4" *ngIf="packageSearch">
                {{(item?.price?.totalPrice - orderSummaryPackage?.priceFlight) >= 0 ? '+' : ''}} {{ (item?.price?.totalPrice - orderSummaryPackage?.priceFlight) | currencyFormat:currencie.country }}
            </div>
            <div class="custom-column-header-price-sub" *ngIf="!packageSearch">
                {{ item?.price?.totalPrice | currencyFormat:currencie.country }} {{ 'flight-fare-modal.total' | translate }}
            </div>

            <div class="custom-column-footer" *ngIf="index === fareSelected">
                <button class="btn app-btn app-uppercase">
                    {{ 'flight-fare-modal.buttons.selected' | translate }}
                </button>
            </div>

            <div class="custom-column-footer" *ngIf="index !== fareSelected">
                <button class="btn app-btn-outline app-uppercase" (click)="selectFare(item, index)">
                    {{ 'flight-fare-modal.buttons.select' | translate }}
                </button>
            </div>
        </div>

    </ng-template>

    <div *ngIf="isMobile">
        <div id="owl-demo" class="owl-carousel owl-theme owl-carousel-fare">
            <div class="item" *ngFor="let item of items; let i = index">
                <ng-container *ngTemplateOutlet="flightFare; context: {$implicit: item, index: i}"></ng-container>
            </div>
        </div>
        <div class="mb-3" *ngIf="!loader">
            <div class="col-12">
                <button type="button" class="btn w-100 app-uppercase app-btn font-weight-bold ml-auto" (click)="accept()">
                    {{ 'flight-fare-modal.buttons.proceed' | translate }}
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="!isMobile">
        <div class="row">
            <div class="col-sm-12 col-md-4" *ngFor="let item of items; let i = index">
                <ng-container *ngTemplateOutlet="flightFare; context: {$implicit: item, index: i}"></ng-container>
            </div>
        </div>
        <div class="row" *ngIf="!loader">
            <div class="col-12">
                <button type="button" class="btn w-25 app-uppercase app-btn font-weight-bold ml-auto float-right" (click)="accept()">
                    {{ 'flight-fare-modal.buttons.proceed' | translate }}
                </button>
            </div>
        </div>
    </div>

</div>

<!-- <div class="modal-footer">
  <div class="col-sm-12 d-flex justify-content-end align-items-center p-0">
    <button type="button" class="btn app-uppercase app-btn font-weight-bold ml-auto" (click)="accept()">
      {{ 'flight-fare-modal.buttons.proceed' | translate }}
    </button>
  </div>
</div> -->