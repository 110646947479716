import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-search-departure-mobile',
  templateUrl: './modal-search-departure-mobile.component.html'
})
export class ModalSearchDepartureMobileComponent extends BaseComponent implements OnInit {

  departure: any;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  selectDeparture(departure: any): void {
    this.departure = departure;
    this.bsModalRef.content.callback(departure);
  }

}
