import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirmation-cart-expired',
  templateUrl: './modal-confirmation-cart-expired.component.html',
})
export class ModalConfirmationCartExpiredComponent extends BaseComponent implements OnInit {

  text: string;
  title: string;
  isExpiredCart: boolean;

  constructor(injector: Injector, public bsModalRef: BsModalRef) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }
}
