import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'src/app/core/components/base/base-component';

@Component({
  selector: 'app-modal-send-code-mobile',
  templateUrl: './modal-send-code-mobile.component.html'
})
export class ModalSendCodeMobileComponent extends BaseComponent implements OnInit, AfterViewInit {

  email: string;

  constructor(injector: Injector, public bsModalRef: BsModalRef) {
    super(injector);
   }

  ngOnInit(): void {

  }

  public accept(): void {
    this.bsModalRef.content.callback();
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback();
    this.bsModalRef.hide();
  }

  sendEmail(): void {
    this.bsModalRef.content.callback(this.email);
    // if (!!this.email) {
    //   this.bsModalRef.content.callback(this.email);
    // } else {
    //   this.notificationService.messageWarn('Por favor preencher Email');
    //   document.getElementById('email').focus();
    // }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document.getElementById('email').focus();
    }, 500);
  }

}
