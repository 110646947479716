// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.develop.p2d.travel/api',
  apiAuthUrl: 'https://api.auth.develop.p2d.travel/api',
  hubUrl: 'https://api.develop.p2d.travel',
  apiIpAddressUrl: 'https://jsonip.com',
  defaulImageLoading: 'https://p2d-public-images.s3.amazonaws.com/destinations/flight.jpg',
  urlOfferGenerator: 'https://devoffergenerator.p2d.travel/',
  GA: 'G-YFVDFRR16CC'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
