<form class="example-form">
    <mat-form-field class="example-full-width" appearance="outline" [ngClass]="{'mat-form-field-invalid': isInvalid}">
        <mat-label>{{placeholder | translate}}</mat-label>
        <input matInput [matAutocomplete]="auto" #airportSearchInput id="searchInput" name="searchInput" [value]="airport?.name" (input)="onSearchChange($event.target.value)" placeholder="{{inputplaceholder | translate}}">
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete">
        <div class="autocomplete-airport" *ngIf="isOpenAutoComplete">
            <ng-container *ngIf="searchedAirports.length > 0; else noItems">
                <mat-option class="multiline-options-airport custom-scroll" *ngFor="let airport of searchedAirports;  let i = index" (onSelectionChange)="clickAirport(airport)" [ngStyle]="{'padding-left': airport.isChild ? '20px' : '10px'}">

                    <ng-container *ngIf="airport.airports?.length > 0; else mainAirport">
                        <span class="material-icons-outlined app-f25 app-grey">flight</span>
                        <div class="airport-search">
                            <span>{{airport.city}} - {{ 'search-flight.all' | translate }}</span>
                        </div>
                    </ng-container>
                    <ng-template #mainAirport>
                        <span class="material-icons-outlined app-f25 app-grey">flight</span>
                        <div class="airport-search">
                            <span>{{ (airport.city + ' (' + airport.iata + '-' + airport.name + ')') | truncate}}</span>
                            <small>{{airport.country}}</small>
                        </div>
                    </ng-template>

                </mat-option>
            </ng-container>
            <ng-template #noItems>
                <mat-option disabled>
                    <span>
                        {{'autocomplete-components.no-airport-found' | translate}}
                    </span>
                </mat-option>
            </ng-template>
        </div>
    </mat-autocomplete>
</form>