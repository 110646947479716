import { catchError, tap } from 'rxjs/operators';
import { NavigationService } from './navigation.service';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserSession } from './../../core/models/user-session.model';
import { BaseAuthService } from './../../core/services/base-auth.service';
import { AuthCode } from './../models/auth-code.model';
import jwt_decode from 'jwt-decode';

import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseAuthService<any, number> {

  private subject$ = new Subject<UserSession>();
  user = new BehaviorSubject<UserSession>(null);

  constructor(protected injector: Injector, private navigationService: NavigationService) {
    super(injector, '/authenticate');
  }

  public sendCode(authCode: AuthCode): Observable<any> {
    return this.post(authCode, '/send-code', false);
  }

  public validateCode(authCode: AuthCode): Observable<any> {
    return this.post(authCode, '/code', false);
  }

  sendUserSession(userSession: UserSession): void {
    this.subject$.next(userSession);
  }

  getUserSession(): Observable<any> {
    return this.subject$.asObservable();
  }

  autoLogin(): void {
    const userData: {
      token: string;
    } = JSON.parse(localStorage.getItem('userSessionToken'));
    if (!userData) {
      return;
    }

    if (userData.token) {
      this.user.next(userData);
    }
  }

  getToken(): string {
    const userData: {
      token: string;
    } = JSON.parse(localStorage.getItem('userSessionToken'));
    if (!userData) {
      return;
    }

    if (userData.token) {
      return JSON.parse(localStorage.getItem('userSessionToken')).token;
    }
  }

  decodePayloadJWT(): any {
    try {
      return jwt_decode(JSON.parse(localStorage.getItem('userSessionToken')).token);
    } catch (Error) {
      return null;
    }
  }

  verifyTokenValid(): void {
    const token = this.getToken();
    const isExpired = helper.isTokenExpired(token);
    if (isExpired) {
      this.logout();
    }
  }

  logout(): void {
    localStorage.removeItem('userSessionToken');
    localStorage.removeItem('agencyPath');
    this.user.next(null);
  }
}
