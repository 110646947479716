import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CityHotel } from './../../models/city-hotel.model';

@Component({
  selector: 'app-modal-search-city-hotel-mobile',
  templateUrl: './modal-search-city-hotel-mobile.component.html'
})
export class ModalSearchCityHotelMobileComponent extends BaseComponent implements OnInit {

  cityHotel: CityHotel;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  selectCityHotel(cityHotel: CityHotel): void {
    this.cityHotel = cityHotel;
    this.bsModalRef.content.callback(cityHotel);
  }

}
