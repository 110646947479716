import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Guest } from './models/guest.model';
import { HotelRoomFilter } from '../../models/hotel/hotel-room-filter.model';
import Messages from 'src/app/core/enums/messages.enum';

@Component({
  selector: 'app-modal-filter-guests-mobile',
  templateUrl: './modal-filter-guests-mobile.component.html'
})
export class ModalFilterGuestsMobileComponent extends BaseComponent implements OnInit {

  numberOfAdults: any;
  numberOfInfant: any;
  numberOfChild: any;
  numberOfRooms: any;
  guest: Guest;
  guestFilter: Guest;
  rooms: HotelRoomFilter[] = [];
  hasErros = false;
  message: string;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.rooms = JSON.parse(JSON.stringify(this.rooms.slice()));
    this.guestFilter = JSON.parse(JSON.stringify(this.guest));
    this.rooms = this.rooms.slice();
    this.bsModalRef.setClass(this.modalCss);
  }

  addAnotherRoom(): void {
    this.guestFilter.numberOfRooms = (this.guestFilter.numberOfRooms + 1);
    this.guestFilter.numberOfAdults = (this.guestFilter.numberOfAdults + 1);
    this.rooms.push({ numberOfAdults: 1, numberOfInfant: 0, numberOfChild: 0, agesOfChild: [], roomNum: (this.rooms.length + 1) });
  }

  removeRoom(index: number): void {
    this.guestFilter.numberOfRooms = (this.guestFilter.numberOfRooms - 1);
    this.guestFilter.numberOfAdults = 0;
    this.guestFilter.numberOfChild = 0;
    this.rooms.splice(index, 1);
    this.rooms.forEach(room => {
      this.guestFilter.numberOfAdults += room.numberOfAdults;
      this.guestFilter.numberOfChild += room.numberOfChild;
    });
  }

  addAdult(room: HotelRoomFilter): void {
    this.guestFilter.numberOfAdults = (this.guestFilter.numberOfAdults + 1);
    room.numberOfAdults = (room.numberOfAdults + 1);
  }

  addChildren(room: HotelRoomFilter): void {
    if (room.numberOfChild === 0) {
      room.arrNumberChildren = [];
      room.agesOfChild = [];
    }
    if (room.arrNumberChildren.length < 6) {
      this.guestFilter.numberOfChild = (this.guestFilter.numberOfChild + 1);
      room.numberOfChild = (room.numberOfChild + 1);
      room.arrNumberChildren.push(0);
      room.agesOfChild.push(null);
    }
    this.verifyAgeChild(room);
  }

  addAge(event, i: number, room: HotelRoomFilter): void {
    const result = parseInt(event.target.value);
    const age = Number.isNaN(result) ? null : result;
    room.agesOfChild[i] = age;
    this.verifyAgeChild(room);
  }

  subtractAdult(room: HotelRoomFilter): void {
    if (room.numberOfAdults >= 1) {
      this.guestFilter.numberOfAdults = (this.guestFilter.numberOfAdults - 1);
      room.numberOfAdults = (room.numberOfAdults - 1);
    }
  }

  subtractChildren(room: HotelRoomFilter): void {
    if (room.numberOfChild >= 1) {
      this.guestFilter.numberOfChild = (this.guestFilter.numberOfChild - 1);
      room.numberOfChild = (room.numberOfChild - 1);
      room.agesOfChild.pop();
      room.arrNumberChildren.pop();
    }
    this.verifyAgeChild(room);
  }

  public accept(): void {
    this.bsModalRef.content.callback(null, null);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null, null);
    this.bsModalRef.hide();
  }

  search(): void {
    if (!this.hasErros) {
      this.bsModalRef.content.callback(this.rooms, this.guestFilter);
    }
  }

  verifyAgeChild(room: HotelRoomFilter): void {
    var BreakException = {};
    try {
      room.agesOfChild.forEach((element: any, j: any) => {
        if (element === null) {
          this.hasErros = true;
          this.message = this.translate.instant(Messages.CHILDREN_AGE);
          throw BreakException;
        } else {
          this.hasErros = false;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }

}
