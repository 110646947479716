
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../services/auth.service';
import { NavigationService } from '@shared/services/navigation.service';
import { TermsOfUse } from './interface/terms-of-use';
import { InstitutionService } from '@shared/services/institution.service';
import { Institution } from '@shared/models/institution.model';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { AgentService } from '@shared/services/agent.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-modal-terms-conditions',
  templateUrl: './modal-terms-conditions.component.html',
  styleUrls: ['./modal-terms-conditions.component.css']
})
export class ModalTermsConditionsComponent extends BaseComponent implements OnInit {

  checkedTerms = false;
  formConfiguration: any;
  accptedTermsTime: Dayjs;
  institution: Institution;
  configurationDefault: any;
  terms: any[];
  policy: string;
  contract: string;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private navigationService: NavigationService,
    private institutionService: InstitutionService,
    private agentService: AgentService,
    private location: Location
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
    this.institution = this.institutionService.getInstitution();
    this.institution = JSON.parse(sessionStorage.getItem('institution'));
    this.configurationDefault = this.propertyObject(this.institution.agent.configurations);

    if (this.institution.agent.operatingMode === 1) {
      this.terms = this.institution.agent.termsToAccept.filter((element: any) => {
        return element.name !== 'PartnerContractPJ';
      });
    } else {
      this.terms = this.institution.agent.termsToAccept.filter((element: any) => {
        return element.name !== 'PartnerContract';
      });
    }
  }

  onOpenWhatsApp(): void {
    window.open('https://api.whatsapp.com/send?phone=551130030694', '_blank');
  }

  onNotNow(): void {
    this.authService.logout();
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
    if (this.router.url.includes('/checkout') && (!this.router.url.includes('/cart') && !this.router.url.includes('/cart-package'))) {
      this.location.back();
    }
  }

  onAcceptTerms(): void {
    const userIP = localStorage.getItem('client-ip');
    const terms: TermsOfUse[] = [];
    this.terms.forEach(element => {
      terms.push({
        termId: element.id,
        ip: userIP,
        dateTime: this.accptedTermsTime.toISOString(),
        dateTimeStr: this.accptedTermsTime.format('YYYY-MM-DDTHH:mm:ss [GMT]Z')
      });
    });

    const request = { terms };

    this.agentService.saveTermsConditions(request).subscribe((result: any) => {
      if (result) {
        this.bsModalRef.content.callback(true);
        this.bsModalRef.hide();
      }
    }, err => {
      this.onNotNow();
    });
  }

  getTermNameToTranslate(name: string): string {
    switch (name) {
      case 'TermsAndConditionsPdf':
        return 'modal-terms.link-term';
        break;
      case 'PrivacyPolicy':
        return 'modal-terms.link-policy';
        break;
      case 'PartnerContract':
        return 'modal-terms.link-contract';
        break;
      case 'PartnerContractPJ':
        return 'modal-terms.link-contract';
        break;

      default:
        break;
    }
  }

  setAcceptedTerms(): void {
    this.checkedTerms = !this.checkedTerms;
    const dateTimezone = dayjs(new Date());
    this.accptedTermsTime = dateTimezone;
  }

  propertyObject<T>(arrConfig: any): T {
    return arrConfig.reduce((obj, item) => (
      { ...obj, [item.name]: item.value }), {});
  }

}
