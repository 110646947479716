import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../shared/components/loader/service/loader.service';
export class BaseAuthService<T, ID> {

  protected base: string;
  protected http: HttpClient;
  protected loaderService: LoaderService;

  protected httpOptions: {
    headers: HttpHeaders
  };

  constructor(protected injector: Injector, protected baseUrl: string,
              protected options?: { headers: HttpHeaders }
  ) {
    this.http = injector.get(HttpClient);
    this.loaderService = injector.get(LoaderService);
    this.base = `${environment.apiAuthUrl}`;
    if (!!this.baseUrl) {
      this.base += `${baseUrl}`;
    }

    if (!options) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    } else {
      this.httpOptions = options;
    }
  }

  get(complementUrl?: string, showLoader: boolean = false): Observable<T> {
    if (showLoader && this.loaderService) {
      this.loaderService.show();
    }
    return this.http.get<any>(this.base + this.getComplementUrl(complementUrl), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  post(t: any, complementUrl?: string, showLoader: boolean = true): Observable<T> {
    if (showLoader && this.loaderService) {
      this.loaderService.show();
    }
    return this.http.post<any>(this.base + this.getComplementUrl(complementUrl), t, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  private getComplementUrl(complementUrl: string): string {
    return complementUrl ? complementUrl : '';
  }

  protected handleError(err: any): any {
    const errorMessage = err.error;
    console.error(errorMessage);
    return throwError(err);
  }


}
