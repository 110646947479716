import { Journey } from './../../../components/flight-list/models/journey.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-flight-detail-content',
  templateUrl: './modal-flight-detail-content.component.html'
})
export class ModalFlightDetailContentComponent extends BaseComponent implements OnInit {

  modal = 1;
  @Input() path: string;
  journey: Journey;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  search(): void {
    this.bsModalRef.content.callback(true);
  }

  clear(): void {

  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    // this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

}
