import { Injectable, Injector } from '@angular/core';
import { Agent } from '@shared/models/agent.model';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService extends BaseService<Agent, number> {

  constructor(protected injector: Injector) {
    super(injector, '/agent');
  }

  public saveTermsConditions(terms: any): Observable<any> {
    return this.put(null, terms, '/agent-terms');
  }

}




