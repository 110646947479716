import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService extends BaseService<any, number> {

    CurrentilyLanguageId: number;
    constructor(protected injector: Injector) {
        super(injector, '/language');
    }

    public getLanguagesActive(): Observable<any> {
        return this.get(`/active`);
    }

    public setLanguageId(id: number): void {
        this.CurrentilyLanguageId = id;
    }

}
