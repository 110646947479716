<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Flight details</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float p-0" style="max-height: 91vh;">
  <div class="d-flex justify-content-center align-items-center pt-3">
    <label class="app-black app-f18 font-weight-normal mb-0 app-uppercase">{{path}}</label>
  </div>
  <app-flight-detail-content [modal]="modal" [path]="path" [journey]="journey"></app-flight-detail-content>
</div>