import { ModalHostingPoliciesComponent } from './../modal-hosting-policies/modal-hosting-policies.component';
import { Room } from '../../../components/hotel-list/models/room.model';
import { DateUtil } from 'src/app/core/utils/date-util';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Hotel } from 'src/app/components/hotel-list/models/hotel.model';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalAmenitiesMobileComponent } from '../modal-amenities-mobile/modal-amenities-mobile.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalInformationComponent } from '../modal-information/modal-information.component';

declare var $: any;
@Component({
  selector: 'app-hotel-card-mobile',
  templateUrl: './hotel-card-mobile.component.html',
  styleUrls: ['./hotel-card-mobile.component.css'],
  providers: [NgbCarouselConfig],
})
export class HotelCardMobileComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() hotelData: Hotel;
  @Input() filterData: any;
  @Output() checkout = new EventEmitter();
  @Output() detail = new EventEmitter();
  hotel: Hotel;
  filter: any;
  days: number;
  modalRef: BsModalRef;

  roomRateTotalPrice: number;
  roomRateTotalPerNight: number;
  roomRateNumberOfNigth: number;
  roomRateCancelPenalties: any;

  filterRooms: Room[] = [];
  activeIndex: number = 0;
  skeletonLoader = false;

  orderSummaryPackage: any;
  packageSearch: any;
  packageCartId: string;
  hotelSearchRange: any;
  isPackage = false;
  hotelSearch: any;

  quantityChildPopover: [] = [];

  @Input() specificHotelSelected: boolean;


  constructor(
    injector: Injector,
    config: NgbCarouselConfig,
    private modalService: BsModalService
  ) {
    super(injector);
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.skeletonLoader = true;
    }, 2000);

    this.hotelSearch = JSON.parse(sessionStorage.getItem('hotelSearch'));
    this.orderSummaryPackage = JSON.parse(sessionStorage.getItem('orderSummaryPackage'));
    this.packageSearch = JSON.parse(sessionStorage.getItem('packageSearch'));

    if (!!this.packageSearch) {
      this.packageCartId = JSON.parse(sessionStorage.getItem('packageCartId'));
      this.hotelSearchRange = JSON.parse(sessionStorage.getItem('hotelSearchRange'));
      this.isPackage = true;
    }

    this.filterEqualsRoom(this.hotel.rooms);
  }

  redirectCheckout(hotel: Hotel): void {
    this.checkout.emit(hotel);
  }

  redirectHotelDetail(hotel: Hotel): void {
    this.detail.emit(hotel);
  }

  numberArray(value: number): number[] {
    return new Array(value);
  }

  returnNumOfRooms(): string {
    if (this.filter.numberOfRooms > 1) {
      return `(${this.filter.numberOfRooms})`;
    }
  }

  async filterEqualsRoom(rooms: Room[]): Promise<void> {
    if (!!this.hotelSearch && !this.isPackage) {
      this.hotelSearch?.rooms.forEach((element: any, i: number) => {
        rooms[i].quantityPopover = element?.agesOfChild;
      });
    } else if (!!this.packageSearch) {
      this.packageSearch?.rooms.forEach((element: any, i: number) => {
        rooms[i].quantityPopover = element?.agesOfChild;
      });
    }

    // let agesChildToMerge = [];
    // let equals;
    // rooms.forEach((room: any, index: number) => {
    //   equals = rooms.filter((element: any, idx: number) =>
    //     element.roomId === room.roomId && element.price.totalPerNight === room.price.totalPerNight
    //   );
    // });

    // equals.map((item, i) => {
    //   agesChildToMerge.push(...item.quantityPopover);
    //   equals[i].quantityPopover = agesChildToMerge;
    // });

    // for await (const room of rooms) {
    //   const roomFilters: Room[] = this.filterRoomPorTaxes(rooms, room);
    //   const containsNewRoom = this.filterRoomPorTaxes(this.filterRooms, roomFilters[0]);
    //   if (containsNewRoom.length === 0) {
    //     let newRoom = roomFilters[0];
    //     newRoom.numberRooms = roomFilters.length;
    //     this.filterRooms.push(newRoom);
    //   }
    // };
    this.filterRooms = rooms;
    if (this.filterRooms.length > 0) {
      if (this.filterRooms.length === 1) {
        this.roomRateTotalPerNight = this.filterRooms[0].price.totalPerNight;
        this.roomRateNumberOfNigth = this.filterRooms[0].price.numberOfNigth;
        this.roomRateCancelPenalties = this.filterRooms[0].cancelPenalties;
      }
      this.roomRateTotalPrice = this.hotelData.price.total;
    }
  }

  // filterRoomPorTaxes(rooms: Room[], room: Room): any[] {
  //   return rooms.filter((element) =>
  //     (element.roomId === room.roomId && element.price.totalPerNight === room.price.totalPerNight && Object.entries(element.occupancy).toString() === Object.entries(room.occupancy).toString())
  //   );
  // }

  openModalAmenities(amenities, about, room): void {
    this.modalRef = this.modalService.show(ModalAmenitiesMobileComponent, {
      class: 'modal-sm',
      initialState: {
        amenities,
        about,
        isPolicies: true,
        policiesCheckIn: this.hotel.checkInPolicy,
        policiesCheckOut: this.hotel.checkOutPolicy,
        isPackage: this.isPackage,
        searchKey: this.hotelSearch.searchKey,
        hotel: this.hotel,
        order: room.order,
        callback: () => {
          this.modalRef.hide();
        },
      },
    });
  }

  openModalInformation(information: string): void {
    this.modalRef = this.modalService.show(ModalInformationComponent, {
      class: 'modal-sm',
      initialState: {
        title: 'Informações sobre cancelamento',
        informations: [information],
        callback: () => {
          this.modalRef.hide();
        },
      },
    });
  }

  openModalHostingPolicies(): void {
    this.modalRef = this.modalService.show(ModalHostingPoliciesComponent, {
      class: 'modal-sm',
      initialState: {
        policiesCheckIn: this.hotel.checkInPolicy,
        policiesCheckOut: this.hotel.checkOutPolicy,
        callback: () => {
          this.modalRef.hide();
        },
      },
    });
  }

  ngOnChanges(): void {
    if (this.hotelData && this.filterData) {
      this.hotel = this.hotelData;
      this.filter = this.filterData;
      this.days = DateUtil.getDaysBetweenDates(
        new Date(this.filter.startDate),
        new Date(this.filter.endDate)
      );
    }
  }

  changeTab(index: number): void {
    this.activeIndex = index;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // tslint:disable-next-line: space-before-function-paren
      $(`#owl-carousel-${this.hotel.hotelId}`).each(function (): void {
        $(this).owlCarousel({
          loop: true,
          margin: 10,
          center: true,
          autoplay: false,
          nav: false,
          responsive: {
            0: {
              items: 1,
            },
            767: {
              items: 2,
            },
            1000: {
              items: 4,
              margin: 20,
            },
            1400: {
              items: 5,
              margin: 20,
            },
          },
        });
      });

      $(`#next-${this.hotel.hotelId}`).click(() => {
        $(`#owl-carousel-${this.hotel.hotelId}`).trigger('next.owl.carousel');
      });
      $(`#prev-${this.hotel.hotelId}`).click(() => {
        $(`#owl-carousel-${this.hotel.hotelId}`).trigger('prev.owl.carousel');
      });
    }, 1000);
  }
}
