import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InsuranceService } from '@shared/services/insurance.service';

import { productsMockNational } from './insurance-mocks/national';
import { productsMockInternational } from './insurance-mocks/international';

declare var $: any;
@Component({
  selector: 'app-modal-insurance',
  templateUrl: './modal-insurance.component.html',
  styleUrls: ['./modal-insurance.component.css']
})
export class ModalInsuranceComponent extends BaseComponent implements OnInit {


  productsMock = productsMockNational;
  productsMockInt = productsMockInternational;

  result: any;
  guid: any = '';
  products = [];
  orderSummary: any;
  orderSummaryPackage: any;
  packageSearch: any;
  packageCartId: string;
  isPackage = false;
  cartId: string;

  insuranceSelected: any = 0;
  insuranceSelectedObj: any;
  productsDetail: any = [];
  loader = true;
  messageNotFound = false;
  numSlice = 7;
  exteriorTrip = false;

  modalRef2: BsModalRef;
  sizeModal: any = 'modal-dialog-centered modal-xl';
  qtdPassengers: number;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    public insuranceService: InsuranceService,
    private modalService: BsModalService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.sizeModal = this.isMobile ? 'modal-dialog-centered full-screen-modal' : 'modal-dialog-centered modal-xl';
    this.bsModalRef.setClass(this.sizeModal);

    this.orderSummary = JSON.parse(sessionStorage.getItem('orderSummary'));
    this.orderSummaryPackage = JSON.parse(sessionStorage.getItem('orderSummaryPackage'));
    if (!!this.orderSummaryPackage) {
      this.packageCartId = JSON.parse(sessionStorage.getItem('packageCartId'));
      this.isPackage = true;
    }
    this.cartId = this.orderSummaryPackage ? this.orderSummaryPackage.cartId : this.orderSummary.cartId;

    if (this.isMobile) {
      this.numSlice = 5;
    }

    this.getQuotation();
  }

  openModal2(template: TemplateRef<any>, product: any): void {
    this.insuranceSelectedObj = product;
    this.modalRef2 = this.modalService.show(template, { id: 2, class: this.sizeModal });
  }

  getBeneftiSecondColumn(pro: any, description: any): any {
    const secondProduct = this.products.find((product: any) => product.id !== pro.id);
    const secondBenefit = secondProduct.benefits.find((benefit: any) => benefit.description === description);
    return secondBenefit ? !secondBenefit.hasCoverage ? secondBenefit.coverageValue : 'Sim' : '-';
  }

  getBeneftiSecondColumnName(pro: any): any {
    const secondProduct = this.products.find((product: any) => product.id !== pro.id);
    return secondProduct.name;
  }

  getQuotation(): void {
    this.guid = this.result.data.guid;
    this.exteriorTrip = this.result.data.exteriorTrip;
    this.qtdPassengers = this.result.data.passengers.length;
    if (this.exteriorTrip) {
      this.products = this.result.data.products.filter(element => {
        return this.productsMockInt.some(mock => {
          if (element.id === mock.id) {
            element.benefitsMock = mock.benefits;
            element.travelDays = element.travelDays ?? 10;
            element.totalBooking = element.totalBooking ?? 100;
            return element;
          }
        });
      });
    } else {
      this.products = this.result.data.products.filter(element => {
        return this.productsMock.some(mock => {
          if (element.id === mock.id) {
            element.benefitsMock = mock.benefits;
            element.travelDays = element.travelDays ?? 10;
            element.totalBooking = element.totalBooking ?? 100;
            return element;
          }
        });
      });
    }

    const noInsurance = {
      benefitsMock: [
        {
          id: null,
          order: null,
          description: this.translate.instant('modal-insurance.no-coverage-you'),
          coverageValue: null
        }
      ],
      fare: null,
      fareByDays: null,
      id: null,
      name: this.translate.instant('modal-insurance.no-coverage'),
      installments: null,
      totalBookingWithInsurance: null,
      image: true
    };
    this.products.push(noInsurance);
    
    this.insuranceSelectedObj = this.products[0];

    if (this.isMobile) {
      this.buildOwlCarousel();
    }

    this.loader = false;

  }

  selectInsurance(product: any, index: number): void {
    if (product.id) {
      this.insuranceSelectedObj = product;
      this.insuranceSelected = index;
    } else {
      this.insuranceSelectedObj = null;
      this.insuranceSelected = index;
    }
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  public accept(): void {
    let obj = {};
    if (this.messageNotFound) {
      this.bsModalRef.content.callback(true);
      this.bsModalRef.hide();
    } else {
      if (this.insuranceSelectedObj) {
        obj = {
          quotationGuid: this.guid,
          productId: this.insuranceSelectedObj.id
        };
      } else {
        obj = {
          quotationGuid: this.guid,
          productId: null
        };
      }
      this.insuranceService.booking(this.cartId, obj).subscribe((response) => {
        this.bsModalRef.content.callback(true);
        this.bsModalRef.hide();
      }, error => {
        this.bsModalRef.hide();
      });
    }
  }

  buildOwlCarousel(): void {
    setTimeout(() => {
      // tslint:disable-next-line: space-before-function-paren
      $('.owl-carousel').each(function (): void {
        $(this).owlCarousel({
          loop: false,
          margin: 10,
          center: true,
          autoplay: false,
          nav: false,
          responsive: {
            0: {
              items: 1.2
            }
          }
        });
      });
    }, 1000);
  }
}
