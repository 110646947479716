import { FarePassengerPrice } from './../../../components/flight-list/models/fare-passenger-price.model';
import { FlightPrice } from './../../../components/flight-list/models/flight-price.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-modal-flight-detail-price',
  templateUrl: './modal-flight-detail-price.component.html'
})
export class ModalFlightDetailPriceComponent extends BaseComponent implements OnInit {

  modal = 1;

  price: FlightPrice;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  search(): void {
    this.bsModalRef.content.callback(true);
  }

  labelPassenger(code: string): string {
    let label: string;
    switch (code) {
      case 'ADT':
        label = 'general.adult';
        break;
      case 'CHD':
        label = 'general.child';
        break;
      case 'INF':
        label = 'general.baby';
        break;
      default:
        break;
    }
    return label;
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

}
