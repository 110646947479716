import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService extends BaseService<any, number> {

  constructor(protected injector: Injector) {
    super(injector, '/insurance');
  }

  public getQuotation(cartId: string): Observable<any> {
    return this.get(`/quotation/${cartId}`, true);
  }

  public booking(cartId: any, obj?: any): Observable<any> {
    return this.post(obj, `/booking/${cartId}`, true);
  }

  public deleteInsurance(cartId: any): Observable<any> {
    return this.deleteStringParam(`${cartId}`);
  }

}
