import { DateUtil } from 'src/app/core/utils/date-util';
import { Journey } from './../../../components/flight-list/models/journey.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector, Input } from '@angular/core';

@Component({
  selector: 'app-flight-detail-content',
  templateUrl: './flight-detail-content.component.html',
  styleUrls: ['./flight-detail-content.component.css']
})
export class FlightDetailContentComponent extends BaseComponent implements OnInit {

  @Input() path: string;
  @Input() journey: Journey;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void { }

  convertDate(year: number, month: number, day: number): any {
    return DateUtil.convertYearMonthDayToDate(year, month, day);
  }

  diffDays(yearOne: number, monthOne: number, dayOne: number, yearTwo: number, monthTwo: number, dayTwo: number): any {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.convertDate(yearOne, monthOne, dayOne);
    const secondDate = this.convertDate(yearTwo, monthTwo, dayTwo);

    return Math.round(Math.abs((firstDate - secondDate) / oneDay));
  }

}
