<div class="card-selected-flight" *ngIf="journey">

    <div class="container-grid">
        <div class="flight-info">
            <div class="flight-description">
                <span class="icon-flight-1" [ngClass]="{'reverse': reverseIcon}"> </span>
                <span>{{ flightDirection | translate }}</span>
            </div>
            <span>{{convertDate(journey.departure.date) | dateLanguageFormat: currencie.defaultLanguage: 'EEE d MMM'}}</span>
        </div>

        <div class="company-logo">
            <span *ngIf="!journey.marketingAirline.pathLogo">{{ journey?.marketingAirline?.name }}</span>
            <img [src]="journey?.marketingAirline.pathLogo">
        </div>

        <div class="departure-station">
            <span class="flight-time">{{journey.departure.time.hour}}:{{journey.departure.time.minute | number:'2.0'}}</span>
            <span class="airport-iata">{{journey.departure.iata}}</span>
        </div>

        <div class="flight-column" [ngbPopover]="popConnection" triggers="mouseenter:mouseleave" placement="bottom">
            <div>
                <label *ngIf="journey.numberOfStops >= 1">
                    {{ journey.numberOfStops }}
                    {{ journey.numberOfStops == 1 ? ('flight-list.label.connection' | translate) :  ('flight-list.label.connections' | translate) }}
                </label>
                <label *ngIf="journey.numberOfStops == 0">
                    {{ 'flight-list.label.direct-flight' | translate }}
                </label>
            </div>
            <span class="line-connection"></span>
            <span>{{journey.flyingTime.hour}}:{{journey.flyingTime.minute | number:'2.0'}}</span>
        </div>

        <div class="arrival-station">
            <span class="flight-time">{{journey.destination.time.hour}}:{{journey.destination.time.minute | number:'2.0'}}</span>

            <span class="airport-iata">{{journey.destination.iata}}</span>
        </div>

        <div class="flight-fare">
            <span>{{journey.fareClass.cabinClass}}</span>
        </div>

        <div class="baggages-column">
            <div *ngFor="let bag of baggage" class="baggage">
                <span class="short-baggage-description">{{bag.weight > 0 ? bag.weight : bag.quantity}} {{ bag.weight > 0 ? 'Kg' : 'x'}}</span>
                <app-custom-icon name="baggage" size="25"></app-custom-icon>
                <span class="full-baggage-description">{{bag.weight > 0 ? bag.weight : bag.quantity}} {{ bag.weight > 0 ? 'Kg' : ('flight-list.label.bag' | translate)}}</span>
            </div>
            <div *ngIf="baggage.length == 0" class="not-allowed">
                <app-custom-icon name="baggage" size="25"></app-custom-icon>
            </div>
            <div *ngFor="let bag of handBag" class="backpack">
                <span class="short-baggage-description">{{bag.weight > 0 ? bag.weight : bag.quantity}} {{ bag.weight > 0 ? 'Kg' : 'x'}}</span>
                <app-custom-icon name="backpack" size="25"></app-custom-icon>
                <span class="full-baggage-description">{{bag.weight > 0 ? bag.weight : bag.quantity}} {{ bag.weight > 0 ? 'Kg' : ('flight-list.label.bag' | translate)}}</span>
            </div>
            <div *ngIf="handBag.length == 0" class="not-allowed">
                <app-custom-icon name="backpack" size="25" ></app-custom-icon>
            </div>
        </div>
    </div>
</div>

<!-- <div class="card-selected-flight" *ngIf="!journey">
  <div class="flight-info">
    <div class="flight-description">
      <span class="icon-flight-1" [ngClass]="{'reverse': reverseIcon}"></span>
      <span>{{ flightDirection | translate }}</span>
    </div>
  </div>
  <div class="departure-station no-flight-selected">
    <span>{{ 'flight-list.label.select-your-flight' | translate }}</span>
  </div>
</div> -->

<ng-template #baggageAllowed>
    <div class="popover-container">
        <div *ngFor="let bag of bagList" [ngClass]="bag.iconName">
            <app-custom-icon [name]="bag.iconName" size="25" [ngClass]="{'active': bag.quantity > 0 || bag.weight > 0}"></app-custom-icon>
            <div>
                <span>{{bag.quantity}} {{ bag.description }}</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #popConnection>
    <div class="d-flex flex-column">
        <ng-container *ngFor="let segment of journey.segments;let i = index;let first = first;">
            <div class="d-flex justify-content-center align-items-center mt-3 mb-3" *ngIf="segment.connectionTime">
                <label class="mb-0 app-blue app-f22 font-weight-normal">
                    <span class="icon-info-circled-alt"></span>
                </label>
                <label class="mb-0 app-black app-f10-12">
                    {{ 'flight-list.label-pop-connection.connection-in' | translate }}
                    <b> {{ segment.departure.name }} </b>
                    {{ 'flight-list.label-pop-connection.waiting-for' | translate }}
                    <b> {{ segment.connectionTime.hour }}h {{ segment.connectionTime.minute | number:'2.0'}}m </b>
                </label>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-3 mb-3" *ngIf="!segment.connectionTime && !first">
                <label class="app-blue app-f10-12 text-center text-decoration-underline ml-1 mr-1">
                    {{ 'flight-list.label-pop-connection.warning' | translate }}
                </label>
            </div>
            <div class="d-flex">
                <label class="app-black app-f10-12 mb-0">
                    {{ 'flight-list.label-pop-connection.flight' | translate }}
                    {{ segment.operatingAirline.iata }}{{ segment.flightNumber }} -
                    {{ segment.operatingAirline.icao }}
                </label>
            </div>
            <div class="d-flex justify-content-between align-items-center mt-1">
                <label class="app-black app-f10-12 font-weight-bold mb-0">
                    {{ segment.departure.iata }} > {{ segment.destination.iata }}
                </label>
            </div>
        </ng-container>
    </div>
</ng-template>