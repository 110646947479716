import { InstitutionService } from './institution.service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Institution } from '@shared/models/institution.model';
import { CurrencieService } from './currencie.service';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';
import { LanguageModel } from '@shared/models/language.model';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class InitializeService {

  clientIp: string;
  language: string;
  languages: LanguageModel[];

  constructor(
    private institutionService: InstitutionService,
    private http: HttpClient,
    private currencieService: CurrencieService,
    private translateService: TranslateService,
    private localeService: BsLocaleService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  async init(): Promise<void> {
    this.languageService.getLanguagesActive().subscribe(result => {
      this.languages = result.data;
      sessionStorage.setItem('languages', JSON.stringify(result.data));
    });

    await this.http.get<any>(`${environment.apiIpAddressUrl}`).toPromise().then(async (data: any) => {
      this.clientIp = data.ip;
      localStorage.setItem('client-ip', data.ip);
      await this.institutionService.getConfigs(location.href, this.clientIp).toPromise().then(async (result: any) => {
        if (result.success) {
          this.currencieService.setCurrencieAllowInstitution(result.data.institutionCurrency);
          sessionStorage.setItem('institution', JSON.stringify(result.data));
          sessionStorage.setItem('aplicationName', JSON.stringify(result.data.applicationName));
          this.defaultLanguageBrowser(result.data);
        }
      });
    }, async (err) => {
      await this.institutionService.getConfigs(location.href).toPromise().then(async (result: any) => {
        if (result.success) {
          this.currencieService.setCurrencieAllowInstitution(result.data.institutionCurrency);
          sessionStorage.setItem('institution', JSON.stringify(result.data));
          sessionStorage.setItem('aplicationName', JSON.stringify(result.data.applicationName));
          this.defaultLanguageBrowser(result.data);
        }
      });
    });
  }

  async getIp(): Promise<any> {
    return this.http.get<any>(`${environment.apiIpAddressUrl}`).toPromise();
  }

  defaultLanguageBrowser(institution?: Institution): void {
    const preferenceLanguage = localStorage.getItem('lang');
    const defaultBrowser = institution.agent.configurations.filter(obj => {
      return obj.name === 'DefautLanguage';
    });

    const defaultCurrency = institution.agent.configurations.filter(obj => {
      return obj.name === 'DefautCurrency';
    });

    if (!!defaultBrowser && defaultBrowser.length > 0) {
      const defaultLangauge = preferenceLanguage ?? defaultBrowser[0].value;
      this.currencieService.setCurrencieByDefaultLanguage(defaultCurrency[0].value, defaultLangauge);
      this.translateService.setDefaultLang(defaultLangauge);
      this.language = defaultLangauge;
      this.document.documentElement.lang = defaultLangauge;
      this.configureLanguageRequest();
    } else {
      this.language = 'en-us';
      const currencie = 'USD';
      this.document.documentElement.lang = this.language;
      this.currencieService.setCurrencieByDefaultLanguage(currencie, this.language);
      this.translateService.setDefaultLang(this.language);
    }

    this.localeService.use(this.language);
  }

  private configureLanguageRequest(): any {
    const languageSelectedId = this.languages.find(language => language.codeIso === this.language)?.id;
    this.languageService.setLanguageId(languageSelectedId);
  }
}
