import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class FlightService extends BaseService<any, number> {

  // itemCart = new Subject<string>();

  constructor(protected injector: Injector, private httpclient: HttpClient) {
    super(injector, '/flight');
  }

  public search(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/search', loader);
  }

  public searchNotify(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/search-notification', false);
  }

  public searchSplitTripNotify(filter: any, loader?: boolean): Observable<any> {
    return this.postRetry(filter, '/search-split-trip-notification', false);
  }

  public poolSearch(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/pool-search', loader);
  }

  public searchFare(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/search-fares', false);
  }

  public searchSpliTripFares(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/search-split-trip-fares', true);
  }

  public searchInboundSplitTrip(filter: any, loader?: boolean): Observable<any>  {
    return this.post(filter, '/search-inbounds-split-trip', false);
  }

  public poolSearchOutboundTrip(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/pool-search-outbound-trip', false);
  }

  public poolSearchInboundTrip(filter: any, loader?: boolean): Observable<any> {
    return this.post(filter, '/pool-search-inbound-trip', false);
  }

  public searchSplitTripByFilter(filter: any): Observable<any> {
    return this.post(filter, '/search-split-trip-by-filter');
  }

  // addRemoveItemCart(cartLink?: string): any {
  //   if (cartLink) {
  //     sessionStorage.setItem('cartLink', cartLink);
  //     this.itemCart.next(cartLink);
  //   } else {
  //     sessionStorage.removeItem('cartLink');
  //     this.itemCart.next();
  //   }
  // }

  // getItemCart(): any {
  //   return sessionStorage.getItem('cartLink');
  // }

}
