import { Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Page } from 'src/app/shared/models/page.mode';
import { Currencie } from './../../../shared/models/currencie.model';
import { NotificationService } from './../../services/notification.service';

export abstract class BaseComponent {

  public isMobile = false;
  public currencie: Currencie;
  public page: Page;
  public modalCss: string;
  protected route: ActivatedRoute;
  protected router: Router;
  protected formBuilder: FormBuilder;
  protected translate: TranslateService;
  protected deviceDetectorService: DeviceDetectorService;
  protected notificationService: NotificationService;

  constructor(protected injector: Injector) {
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.formBuilder = this.injector.get(FormBuilder);
    this.translate = this.injector.get(TranslateService);
    this.deviceDetectorService = this.injector.get(DeviceDetectorService);
    this.notificationService = this.injector.get(NotificationService);
    this.isMobile = this.deviceDetectorService.isMobile();
    this.currencie = this.setCurrency();
    this.modalCss = this.getModalCss();
  }

  getModalCss(): string {
    const sizeModal = this.isMobile ? 'full-screen-modal' : 'modal-md';
    return `modal-dialog-centered ${sizeModal}`;
  }

  setCurrency(): any {
    if (JSON.parse(sessionStorage.getItem('currencie'))) {
      return JSON.parse(sessionStorage.getItem('currencie'));
    } else {
      const obj = {
        id: 3,
        currencie: '€',
        country: 'EUR',
        defaultLanguage: 'en-us',
        defaultCurrency: 'EUR',
        custom: true
      };
      sessionStorage.setItem('currencie', JSON.stringify(obj));
      return obj;
    }
  }
}
