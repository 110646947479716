<div [class.hidden]="!show">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="ball-spin-fade" [fullScreen]="true">

    <div class="card-p2d-logo mt-4" *ngIf="institution && institution?.agent.isDefaultAgent">
      <div class="profile d-flex justify-content-center align-items-center">
        <img [src]="logoHeader" class="picture-header-institution" alt="">
      </div>
    </div>
    <div class="card card-profile mt-4" style="background-color: #455ED2;" *ngIf="institution && !institution?.agent.isDefaultAgent">
      <div class="card-body">
        <div class="profile d-flex justify-content-center align-items-center">
          <!-- <div>
            <img [src]="agentImgProfile" class="picture-header" alt="">
          </div> -->
          <div class="d-flex justify-content-center align-items-center">
            <p class="ml-2 mb-0 mr-2">{{institution?.agent.agencyName | slice:0:20}}</p>
          </div>
        </div>
      </div>
    </div>
  </ngx-spinner>
</div>