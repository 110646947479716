<header class="header menu_fixed d-flex" [style.background-color]="colorHeader" id="header">


  <div class="col-sm-12 pl-0">
    <div class="d-flex flex-row justify-content-between align-items-center row-itens">

      <div class="card-p2d-logo" *ngIf="showTemplateInstitution">
        <div class="profile d-flex justify-content-center align-items-center app-pointer" (click)="redirectHome()">
          <img [src]="logoHeader" class="picture-header-institution" alt="">
        </div>
      </div>
      <div class="card card-profile backgroundOrange" [style.background-color]="backgroundOrange"
        *ngIf="showTemplateAgent">
        <div class="profile d-flex justify-content-center align-items-center" (click)="redirectHome()"
          style="cursor: pointer;height: 55px;">
          <div>
            <img [src]="agentImgProfile" class="picture-header" alt="" *ngIf="skeletonLoader">
            <ngx-skeleton-loader count="1" *ngIf="!skeletonLoader" appearance="circle"
              [theme]="{ width: '50px', height: '50px', bottom: '0px', top: '3px', right: '3px' }">
            </ngx-skeleton-loader>
          </div>
          <div>
            <p class="ml-2 mb-0 mr-2" *ngIf="skeletonLoader">{{institution?.agent.agencyName | slice:0:30}}</p>
            <div class="ml-2 mb-0 mr-2">
              <ngx-skeleton-loader *ngIf="!skeletonLoader"
                [theme]="{ width: '120px', 'border-radius': '8px', height: '25px', bottom: '-8px' }">
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>


      <div class="menu-actions d-flex align-items-center">
        <div class="dropdown" *ngIf="isLoggedIn && isMobile">
          <a class="dropdown-item dropdown-footer hover-white" style="display: flex;" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div>
              <img [src]="language?.icon" style="width: 17px;" alt="">
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <div *ngFor="let language of languages">
              <a class="dropdown-item" (click)="changeLanguage(language)">
                <img [src]="language.icon" style="width: 17px;" alt="">
                &nbsp;{{language.displayName}}
              </a>
            </div>
          </div>
        </div>

        <div class="profile d-flex justify-content-center align-items-center">
          <div class="home-label mr-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
            <span class="cursor-pointer" (click)="redirectHome()" *ngIf="skeletonLoader">
              {{'header.menu.home' | translate}}
            </span>
            <ngx-skeleton-loader *ngIf="!skeletonLoader && !isMobile"
              [theme]="{ width: '50px', 'border-radius': '8px', height: '25px', bottom: '-8px', right: '80px' }">
            </ngx-skeleton-loader>
          </div>

          <div class="dropdown">

            <button class="country-label btn btn-link mr-4 d-none d-sm-block d-md-block d-lg-block d-xl-block"
              type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              [disabled]="disableCurrency" *ngIf="skeletonLoader">
              <span>{{currencie?.code}}</span>
            </button>
            <ngx-skeleton-loader *ngIf="!skeletonLoader && !isMobile"
              [theme]="{ width: '50px', 'border-radius': '8px', height: '25px', bottom: '-8px', right: '60px' }">
            </ngx-skeleton-loader>
            <div class="dropdown-menu app-dropdown-round dropdown-cur-header" aria-labelledby="dropdownMenuButton1">
              <a class="dropdown-item cursor-pointer" *ngFor="let cur of currencies;let lastItem = last;"
                (click)="changeCurrencie(cur)">
                {{cur.symbol + ' - ' + cur.description}}
                <div class="divider m-1" *ngIf="!lastItem"></div>
              </a>
            </div>
          </div>

          <div class="dropdown">
            <button *ngIf="skeletonLoader"
              class="country-label flags-country btn btn-link mr-sm-4 mr-2 d-none d-sm-block d-md-block d-lg-block d-xl-block"
              type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img [src]="language?.icon" [alt]="language?.codeIso">
            </button>
            <ngx-skeleton-loader *ngIf="!skeletonLoader && !isMobile"
              [theme]="{ width: '50px', 'border-radius': '20px', height: '30px', bottom: '-8px', right: '30px' }">
            </ngx-skeleton-loader>
            <div class="dropdown-menu app-dropdown-round dropdown-lan-header" aria-labelledby="dropdownMenuButton2">
              <div *ngFor="let language of languages">
                <a class="dropdown-item cursor-pointer d-flex" (click)="changeLanguage(language)">
                  <img class="mr-3" [src]="language?.icon" alt="EN" width="25px" style="float: right">
                  {{language?.displayName}}
                  <div class="divider m-1"></div>
                </a>
              </div>
            </div>
          </div>

          <div class="button-login-profile" *ngIf="!isLoggedIn">
            <button class="btn_n rounded d-none d-sm-block d-md-block d-lg-block d-xl-block" (click)="openModalLogin()"
              [style.background-color]="backgroundOrange" *ngIf="skeletonLoader">
              {{'header.label.signin' | translate}}
            </button>
            <ngx-skeleton-loader *ngIf="!skeletonLoader && !isMobile"
              [theme]="{ width: '50px', 'border-radius': '20px', height: '30px', bottom: '-8px' }">
            </ngx-skeleton-loader>
          </div>

          <div class="dropdown" *ngIf="isLoggedIn">
            <a class="user-icon cursor-pointer picture-header" href="#" *ngIf="userJwt.ImageProfile"
              style="background-image: url({{userJwt.ImageProfile}})" role="button" id="dropdownUserMenuLoggedIn"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </a>
            <a class="user-icon cursor-pointer picture-header" href="#" *ngIf="!userJwt.ImageProfile"
              style="background-image: url('assets/img/avatar.jpg')" role="button" id="dropdownUserMenuLoggedIn"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </a>
            <div class="dropdown-menu app-dropdown-round dropdown-menu-right" aria-labelledby="dropdownUserMenuLoggedIn"
              appDropdown>
              <a class="dropdown-item cursor-pointer" [href]="urlRestrictArea" target="_self" *ngIf="isOwner">
                <span>{{'header.label.restrict' | translate}}</span>
              </a>
              <div class="divider m-1" *ngIf="isOwner"></div>
              <a class="dropdown-item cursor-pointer" (click)="redirectUserArea()">
                <span>{{'header.label.my-account' | translate}}</span>
              </a>
              <div class="divider m-1"></div>
              <a class="dropdown-item cursor-pointer" (click)="onLogout()">
                <span>{{'header.label.logout' | translate}}</span>
              </a>
            </div>
          </div>

          <a (click)="openModalLogin()" class="btn_mobile" *ngIf="!isLoggedIn">
            <div class="hamburger hamburger--spin" id="hamburger">
              <div class="hamburger-box">
                <div class="hamburger-inner"></div>
              </div>
            </div>
          </a>

        </div>
      </div>
    </div>
  </div>
</header>
