<div class="modal-header">
  <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{title}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float mb-3">
  <div class="column">
    <div class="col-sm-12">
      <div class="text-center app-f16 text-justify">
        {{'checkout-cart.label.expired-title' | translate}}
      </div>
      <div class="text-center app-f14 text-justify mt-3">
        {{'checkout-cart.label.expired-sub-title' | translate}}
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="col-sm-12 d-flex justify-content-center align-items-center p-0">
    <button type="button" class="btn btn-primary app-btn-orange btn-sm app-btn-round w-50 mr-1 font-weight-bold" (click)="accept()">
      {{'checkout-cart.label.expired-keep-buying' | translate}}
    </button>
  </div>
</div>