import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirm-update-version',
  templateUrl: './modal-confirm-update-version.component.html',
})
export class ModalConfirmUpdateVersionComponent extends BaseComponent implements OnInit {

  constructor(injector: Injector, public bsModalRefUpdate: BsModalRef) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRefUpdate.setClass(this.modalCss);
  }

  public accept(): void {
    this.bsModalRefUpdate.content.callback(true);
    this.bsModalRefUpdate.hide();
  }

  public dismiss(): void {
    this.bsModalRefUpdate.content.callback(false);
    this.bsModalRefUpdate.hide();
  }
}
