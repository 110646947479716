<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{'modal-search-city-hotel.title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="min-height: 400px;">
  <div class="form-group">
    <app-autocomplete-city-hotel
      [placeholder]="'modal-search-city-hotel.input.placeholder.choose'"
      (selectCityHotel)="selectCityHotel($event)">
    </app-autocomplete-city-hotel>
  </div>
</div>
