import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Destination } from 'src/app/shared/models/destination.model';

@Component({
  selector: 'app-modal-search-destination-mobile',
  templateUrl: './modal-search-destination-mobile.component.html'
})
export class ModalSearchDestinationMobileComponent extends BaseComponent implements OnInit {

  destination: Destination;
  searchedDestinations: Destination[] = [];

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  selectDestination(destination: any): void {
    this.destination = destination;
    this.bsModalRef.content.callback(destination);
  }

}
