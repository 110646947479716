import { InitializeService } from '@shared/services/initialize.service';
import { environment } from './../environments/environment';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { RequestInterceptor } from './core/interceptors/request-interceptor';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { SharedModule } from '@shared/shared.module';
import { WebpackTranslateLoader } from './webpacktranslate';
import { CreditCardDirectivesModule } from 'angular-cc-library';

import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';
import { ServiceWorkerModule } from '@angular/service-worker';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = true;
  accountUrl = 'p2d.zendesk.com';
  callback(): void { }
}

registerLocaleData(localePt);
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeEn);
@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader:
      {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.GA),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    Title,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [InitializeService],
      useFactory: (configService: InitializeService) => {
        return () => {
          return configService.init();
        };
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
