import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe(
    { name: 'dateLanguageFormat' }
)
export class DateLanguageFormatPipe implements PipeTransform {

    language: string;
    format: string;

    constructor(
        private datePipe: DatePipe
    ) { }

    transform(date: any | string, language: string, format: string): any {
        if (language === 'pt-br' || language === 'pt') {
            const datePipe = new DatePipe('pt-BR');
            return datePipe.transform(date, format);
        } else if (language === 'es-mx') {
            const datePipe = new DatePipe('es-MX');
            return datePipe.transform(date, format);
        } else {
            const datePipe = new DatePipe('en-EU');
            return datePipe.transform(date, format);
        }
    }
}
