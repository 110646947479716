import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';
import { Airport } from './../models/airport.model';

@Injectable({
  providedIn: 'root'
})
export class AirportService extends BaseService<Airport, number> {

  constructor(protected injector: Injector) {
      super(injector, '/airport');
  }

  public search(name: string, isDeparture: boolean): Observable<any> {
    return this.get(`/search?name=${name}&isDeparture=${isDeparture}`, false);
  }

  public nearestByLocation(latitude: any, longitude: any): Observable<any>  {
    return this.get(`/nearest-by-geolocation?latitude=${latitude}&longitude=${longitude}&isDeparture=true`);
  }

}
