import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Messages from 'src/app/core/enums/messages.enum';

@Component({
  selector: 'app-modal-confirmation-share-cart',
  templateUrl: './modal-confirmation-share-cart.component.html',
  styles: [`.card-round {border-radius: 20px;}`]
})
export class ModalConfirmationShareCartComponent extends BaseComponent implements OnInit {

  text: string;
  title: string;
  link: string;
  hasCopied = false;

  constructor(injector: Injector, public bsModalRef: BsModalRef) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
  }

  shareAgency(): void {
    navigator.clipboard.writeText(this.link)
      .then(() => {
        this.notificationService.messageSuccess(this.translate.instant(Messages.CART_COPIED));
        this.hasCopied = true;
        this.bsModalRef.content.callback(false);
        this.bsModalRef.hide();
      })
      .catch(e => console.error(e));
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }
}
