<div class="title d-flex justify-content-start">
  <div class="app-f16 app-black">{{'flight-filter.order.title' | translate}}</div>
</div>

<div class="departure radios mt-3 d-flex flex-column" [ngClass]="{'custom-disabled': blockedFilters?.others}">
  <div class="custom-control custom-radio d-flex justify-content-start">
    <input type="radio" id="customRadio11{{modal}}" name="customRadioVoo" class="custom-control-input"
      [checked]="flightSearchKey.ordination === 0" (click)="changeOrdenation('0')">
    <label class="custom-control-label" for="customRadio11{{modal}}">
      {{'flight-filter.order.lower' | translate}}
    </label>
  </div>
  <div class="custom-control custom-radio d-flex justify-content-start">
    <input type="radio" id="customRadio12{{modal}}" name="customRadioVoo" class="custom-control-input"
      [checked]="flightSearchKey.ordination === 1" (click)="changeOrdenation('1')">
    <label class="custom-control-label" for="customRadio12{{modal}}">
      {{'flight-filter.order.highest' | translate}}
    </label>
  </div>
  <div class="custom-control custom-radio d-flex justify-content-start">
    <input type="radio" id="customRadio13{{modal}}" name="customRadioVoo" class="custom-control-input"
      [checked]="flightSearchKey.ordination === 2" (click)="changeOrdenation('2')">
    <label class="custom-control-label" for="customRadio13{{modal}}">
      {{'flight-filter.order.duration' | translate}}
    </label>
  </div>
</div>

<div *ngIf="showOutbound">
  <hr>
  <div class="title d-flex justify-content-start">
    <div class="app-f16 app-black">{{'flight-filter.luggage.title' | translate}}</div>
  </div>
  <div class="departure radios mt-3 d-flex flex-column">
    <div class="form-check pl-0 pb-1 d-flex justify-content-start">
      <input class="form-check-input" type="checkbox" value="" id="customRadioBaggage{{modal}}"
        [checked]="flightSearchKey.filter.allowDispatchBaggage" (click)="changeBaggage()">
      <label class="form-check-label" for="customRadioBaggage{{modal}}">
        {{'flight-filter.luggage.checked-luggage' | translate}}
      </label>
    </div>
  </div>
</div>

<div *ngIf="showOutbound">
  <hr>
  <div class="title d-flex justify-content-start">
    <div class="app-f16 app-black">{{'flight-filter.class.title' | translate}}</div>
  </div>

  <div class="stops radios mt-3 d-flex flex-column" [ngClass]="{'custom-disabled': blockedFilters?.cabin}">
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="allclasses30{{modal}}" name="customRadioClasses" class="custom-control-input"
        [checked]="!flightSearchKey.filter.cabinClasses" (click)="changeClass(null)">
      <label class="custom-control-label" for="allclasses30{{modal}}">
        {{'flight-filter.class.all' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="allclasses31{{modal}}" name="customRadioClasses" class="custom-control-input"
        [checked]="flightSearchKey.filter.cabinClasses == 0" (click)="changeClass('0')">
      <label class="custom-control-label" for="allclasses31{{modal}}">
        {{'flight-filter.class.economy' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="allclasses32{{modal}}" name="customRadioClasses" class="custom-control-input"
        [checked]="flightSearchKey.filter.cabinClasses == 1" (click)="changeClass('1')">
      <label class="custom-control-label" for="allclasses32{{modal}}">
        {{'flight-filter.class.economy' | translate}} premium
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="allclasses33{{modal}}" name="customRadioClasses" class="custom-control-input"
        [checked]="flightSearchKey.filter.cabinClasses == 2" (click)="changeClass('2')">
      <label class="custom-control-label" for="allclasses33{{modal}}">
        {{'flight-filter.class.business' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="allclasses34{{modal}}" name="customRadioClasses" class="custom-control-input"
        [checked]="flightSearchKey.filter.cabinClasses == 3" (click)="changeClass('3')">
      <label class="custom-control-label" for="allclasses34{{modal}}">
        {{'flight-filter.class.first' | translate}}
      </label>
    </div>
  </div>
</div>

<hr>

<div class="title d-flex justify-content-start">
  <div class="app-f16 app-black">{{'flight-filter.airline.title' | translate}}</div>
</div>
<div class="airline boxes mt-3" [ngClass]="{'custom-disabled': blockedFilters?.others}">
  <div class="form-check pl-0 pb-1 d-flex justify-content-start">
    <input class="form-check-input" type="checkbox" value="" id="allairlines{{modal}}" [checked]="flightSearchKey.filter.marketingAirlinesIata.length === marketingAirlinesAvailable.length 
      || flightSearchKey.filter.marketingAirlinesIata.length === 0" (click)="changeAirline(null)">
    <label class="form-check-label" for="allairlines{{modal}}">
      {{'flight-filter.airline.all' | translate}}
    </label>
  </div>
  <div class="form-check pl-0 pb-1 d-flex justify-content-start"
    *ngFor="let item of marketingAirlinesAvailable; let i = index;">
    <input class="form-check-input" type="checkbox" value="" id="{{item.iata+modal}}"
      [checked]="flightSearchKey.filter.marketingAirlinesIata.includes(item.iata)" (click)="changeAirline(item.iata)">
    <label class="form-check-label" for="{{item.iata+modal}}">
      {{ item.businessName }}
    </label>
  </div>
</div>

<hr>

<div class="title d-flex justify-content-start">
  <div class="app-f16 app-black">{{'flight-filter.airport.title-departure' | translate}}</div>
</div>
<div class="airline boxes mt-3" [ngClass]="{'custom-disabled': blockedFilters?.others}">
  <div class="form-check pl-0 pb-1 d-flex justify-content-start">
    <input class="form-check-input" type="checkbox" value="" id="allairportsdeparture{{modal}}" [checked]="flightSearchKey.filter.departureAirportsIata.length === departureAirportsAvailable.length 
      || flightSearchKey.filter.departureAirportsIata.length === 0" (click)="changeAirportDeparture(null)">
    <label class="form-check-label" for="allairportsdeparture{{modal}}">
      {{'flight-filter.airport.all' | translate}}
    </label>
  </div>
  <div class="form-check pl-0 pb-1 d-flex justify-content-start"
    *ngFor="let item of departureAirportsAvailable; let i = index;">
    <input class="form-check-input" type="checkbox" value="" id="{{item?.iata+modal}}"
      [checked]="flightSearchKey.filter.departureAirportsIata.includes(item?.iata)"
      (click)="changeAirportDeparture(item?.iata)">
    <label title="{{item?.name}}" class="form-check-label" for="{{item?.iata+modal}}">
      {{item?.iata}} - {{ item?.name?.split(' ')[0] }} {{ item?.name?.split(' ')[1] }}
    </label>
  </div>
</div>

<hr>

<div class="title d-flex justify-content-start">
  <div class="app-f16 app-black">{{'flight-filter.airport.title-return' | translate}}</div>
</div>
<div class="airline boxes mt-3" [ngClass]="{'custom-disabled': blockedFilters?.others}">
  <div class="form-check pl-0 pb-1 d-flex justify-content-start">
    <input class="form-check-input" type="checkbox" value="" id="allairportsreturn{{modal}}" [checked]="flightSearchKey.filter.arrivalAirportsIata.length === arrivalAirportsAvailable.length 
      || flightSearchKey.filter.arrivalAirportsIata.length === 0" (click)="changeAirportReturn(null)">
    <label class="form-check-label" for="allairportsreturn{{modal}}">
      {{'flight-filter.airport.all' | translate}}
    </label>
  </div>
  <div class="form-check pl-0 pb-1 d-flex justify-content-start"
    *ngFor="let item of arrivalAirportsAvailable; let i = index;">
    <input class="form-check-input" type="checkbox" value="" id="{{item?.iata+modal}}"
      [checked]="flightSearchKey.filter.arrivalAirportsIata.includes(item?.iata)"
      (click)="changeAirportReturn(item?.iata)">
    <label title="{{item?.name}}" class="form-check-label" for="{{item?.iata+modal}}">
      {{item?.iata}} - {{ item?.name?.split(' ')[0] }} {{ item?.name?.split(' ')[1] }}
    </label>
  </div>
</div>

<hr>

<div class="title d-flex justify-content-start">
  <div class="app-f16 app-black">{{'flight-filter.stops.title' | translate}}</div>
</div>

<div class="stops radios mt-3 d-flex flex-column" [ngClass]="{'custom-disabled': blockedFilters?.others}">
  <div class="custom-control custom-radio d-flex justify-content-start">
    <input type="radio" id="customRadio14{{modal}}" name="customRadioStops" class="custom-control-input"
      [checked]="flightSearchKey.filter.maxStops === 0" (click)="changeStops('0')">
    <label class="custom-control-label" for="customRadio14{{modal}}">
      {{'flight-filter.stops.indifferent' | translate}}
    </label>
  </div>
  <div class="custom-control custom-radio d-flex justify-content-start">
    <input type="radio" id="customRadio15{{modal}}" name="customRadioStops" class="custom-control-input"
      [checked]="flightSearchKey.filter.maxStops === 1" (click)="changeStops('1')">
    <label class="custom-control-label" for="customRadio15{{modal}}">
      {{'flight-filter.stops.direct' | translate}}
    </label>
  </div>
  <div class="custom-control custom-radio d-flex justify-content-start">
    <input type="radio" id="customRadio16{{modal}}" name="customRadioStops" class="custom-control-input"
      [checked]="flightSearchKey.filter.maxStops === 2" (click)="changeStops('2')">
    <label class="custom-control-label" for="customRadio16{{modal}}">
      {{'flight-filter.stops.one-stop' | translate}}
    </label>
  </div>
  <div class="custom-control custom-radio d-flex justify-content-start">
    <input type="radio" id="customRadio17{{modal}}" name="customRadioStops" class="custom-control-input"
      [checked]="flightSearchKey.filter.maxStops === 3" (click)="changeStops('3')">
    <label class="custom-control-label" for="customRadio17{{modal}}">
      {{'flight-filter.stops.two-more' | translate}}
    </label>
  </div>
</div>

<div *ngIf="showOutbound">
  <hr>

  <div class="title d-flex justify-content-start">
    <div class="app-f16 app-black">{{'flight-filter.departure.title' | translate}}</div>
  </div>

  <div class="departure radios mt-3 d-flex flex-column" [ngClass]="{'custom-disabled': blockedFilters?.others}">
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio25{{modal}}" name="customRadio" class="custom-control-input"
        [checked]="!flightSearchKey.filter.startDepartureTime" (click)="changeDepartureTime(0, 0, 0, 0, false)">
      <label class="custom-control-label" for="customRadio25{{modal}}">
        {{'flight-filter.departure.all' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio2{{modal}}" name="customRadio" class="custom-control-input"
        [checked]="flightSearchKey.filter.endDepartureTime && flightSearchKey.filter.endDepartureTime.hour == 4"
        (click)="changeDepartureTime(0, 0, 4, 59, true)">
      <label class="custom-control-label" for="customRadio2{{modal}}">
        {{'flight-filter.departure.dawn' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio3{{modal}}" name="customRadio" class="custom-control-input"
        [checked]="flightSearchKey.filter.endDepartureTime && flightSearchKey.filter.endDepartureTime.hour == 11"
        (click)="changeDepartureTime(5, 0, 11, 59, true)">
      <label class="custom-control-label" for="customRadio3{{modal}}">
        {{'flight-filter.departure.morning' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio4{{modal}}" name="customRadio" class="custom-control-input"
        [checked]="flightSearchKey.filter.endDepartureTime && flightSearchKey.filter.endDepartureTime.hour == 17"
        (click)="changeDepartureTime(12, 0, 17, 59, true)">
      <label class="custom-control-label" for="customRadio4{{modal}}">
        {{'flight-filter.departure.afternoon' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio5{{modal}}" name="customRadio" class="custom-control-input"
        [checked]="flightSearchKey.filter.endDepartureTime && flightSearchKey.filter.endDepartureTime.hour == 23"
        (click)="changeDepartureTime(18, 0, 23, 59, true)">
      <label class="custom-control-label" for="customRadio5{{modal}}">
        {{'flight-filter.departure.night' | translate}}
      </label>
    </div>
  </div>
</div>


<div *ngIf="!showOutbound">
  <hr>

  <div class="title d-flex justify-content-start">
    <div class="app-f16 app-black">{{'flight-filter.return.title' | translate}}</div>
  </div>

  <div class="return radios mt-3 d-flex flex-column" [ngClass]="{'custom-disabled': blockedFilters?.others}">
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio6{{modal}}" name="customRadioReturn" class="custom-control-input"
        [checked]="!flightSearchKey.filter.startReturnTime" (click)="changeReturnTime(0, 0, 0, 0, false)">
      <label class="custom-control-label" for="customRadio6{{modal}}">
        {{'flight-filter.return.all' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio7{{modal}}" name="customRadioReturn" class="custom-control-input"
        [checked]="flightSearchKey.filter.endReturnTime && flightSearchKey.filter.endReturnTime.hour == 4"
        (click)="changeReturnTime(0, 0, 4, 59, true)">
      <label class="custom-control-label" for="customRadio7{{modal}}">
        {{'flight-filter.return.dawn' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio8{{modal}}" name="customRadioReturn" class="custom-control-input"
        [checked]="flightSearchKey.filter.endReturnTime && flightSearchKey.filter.endReturnTime.hour == 11"
        (click)="changeReturnTime(5, 0, 11, 59, true)">
      <label class="custom-control-label" for="customRadio8{{modal}}">
        {{'flight-filter.return.morning' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio9{{modal}}" name="customRadioReturn" class="custom-control-input"
        [checked]="flightSearchKey.filter.endReturnTime && flightSearchKey.filter.endReturnTime.hour == 17"
        (click)="changeReturnTime(12, 0, 17, 59, true)">
      <label class="custom-control-label" for="customRadio9{{modal}}">
        {{'flight-filter.return.afternoon' | translate}}
      </label>
    </div>
    <div class="custom-control custom-radio d-flex justify-content-start">
      <input type="radio" id="customRadio10{{modal}}" name="customRadioReturn" class="custom-control-input"
        [checked]="flightSearchKey.filter.endReturnTime && flightSearchKey.filter.endReturnTime.hour == 23"
        (click)="changeReturnTime(18, 0, 23, 59, true)">
      <label class="custom-control-label" for="customRadio10{{modal}}">
        {{'flight-filter.return.night' | translate}}
      </label>
    </div>
  </div>
</div>