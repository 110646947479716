<div class="form-group">
    
    <!-- <label *ngIf="labelValue" for="inputRangeDeskCheckin{{id}}" style="color: #0054a6;">{{'home.label.when' | translate}}</label> -->
    
    <input *ngIf="isRoundTrip"
        id="inputRangeDeskCheckin{{id}}" 
        #dpFromDate
        placeholder="{{'home.label.when-go' | translate}}"
        name="datepicker" 
        class="form-control font-weight-bold app-input app-pointer" 
        ngbDatepicker 
        #datepicker="ngbDatepicker" 
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event, datepicker)" 
        [displayMonths]="2" 
        [dayTemplate]="t" 
        outsideDays="hidden"
        [startDate]="fromDate!" 
        (click)="datepicker.toggle()"
        [value]="formatDate()" 
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        [minDate]="minDate"
        autocomplete="off"
        navigation="arrows"
        [readonly]="true"
        [ngClass]="{'is-invalid': isInvalid}"
    >

    <input *ngIf="!isRoundTrip"
        id="inputDeskCheckinGo{{id}}" 
        #dpFromDate
        placeholder="{{'home.label.when-back' | translate}}"
        name="datepicker" 
        class="form-control font-weight-bold app-input app-pointer" 
        ngbDatepicker 
        #datepicker="ngbDatepicker" 
        [autoClose]="'outside'"
        (dateSelect)="onDateSelectionFlightGo($event, datepicker)" 
        [displayMonths]="1" 
        outsideDays="hidden"
        (click)="datepicker.toggle()"
        [value]="formatDate()" 
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        [minDate]="minDate"
        autocomplete="off"
        navigation="arrows"
        [readonly]="true"
        [ngClass]="{'is-invalid': isInvalid}"
    >


      
    <ng-template #t let-date let-focused="focused">
        <div 
            class="custom-day" 
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" 
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
    </div>
    </ng-template>

</div>