import { Flight } from 'src/app/components/flight-list/models/flight.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlightService } from '@shared/services/flight.service';
import { TranslateService } from '@ngx-translate/core';
import iconJSON from '../../../../assets/css/icon.json';
import { Journey } from 'src/app/components/flight-list/models/journey.model';

declare var $: any;
@Component({
  selector: 'app-modal-flight-fare',
  templateUrl: './modal-flight-fare.component.html',
  styleUrls: ['./modal-flight-fare.component.css']
})
export class ModalFlightFareComponent extends BaseComponent implements OnInit, AfterViewInit {

  items = [];
  currentResult: any;
  flight: Flight;
  searchKey: string;
  upSellKey: string;
  index: number;
  fareSelected: any;
  fareSelectedObj: any;
  messageNotFound = false;
  fareSearchKey: string;
  loader = true;
  notFirstItem = false;
  lastIndex: number;
  orderSummaryPackage: any;
  packageSearch: any;
  packageCartId: string;
  isPackage = false;
  svgPath = iconJSON;
  fareId: string;
  outboundItineraryId: string;
  inboundItineraryId: string;
  indexSelectedMobile: number;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    private flightService: FlightService,
    private translateService: TranslateService,
  ) {
    super(injector);
    this.svgPath = iconJSON;
  }

  ngOnInit(): void {
    this.orderSummaryPackage = JSON.parse(sessionStorage.getItem('orderSummaryPackage'));
    this.packageSearch = JSON.parse(sessionStorage.getItem('packageSearch'));
    if (!!this.packageSearch) {
      this.packageCartId = JSON.parse(sessionStorage.getItem('packageCartId'));
      this.isPackage = true;
    }

    const sizeModal = this.isMobile ? 'modal-dialog-centered full-screen-modal' : 'modal-dialog-centered modal-xl';
    this.bsModalRef.setClass(sizeModal);
    if (this.isMobile) {
      this.currentResult.flights = this.currentResult.flights.reverse();
      this.indexSelectedMobile = 0;
    }
    this.searchFare();
  }

  searchFare(): void {
    const objSearch = {
      searchKey: this.searchKey,
      upSellKey: this.upSellKey,
      fareSearchKey: null,
      fareId: this.fareId,
      outboundItineraryId: this.outboundItineraryId,
      inboundItineraryId: this.inboundItineraryId
    };

    const journeyNumber = this.inboundItineraryId ? 2 : 1;
    const flightsByJourneyNumber = this.currentResult.flights.filter((flight: Flight) => flight.journeys.filter((journey: Journey) => journey.journeyNumber === journeyNumber));
    flightsByJourneyNumber.forEach((flight: Flight) => {

      const price = flight.price;
      this.items.push({
        fareId: flight.fareId,
        price,
        journeys: flight.journeys
      });
    });
    this.setFareSelected();
  }

  setFareSelected(): void {
    this.fareSelected = this.indexSelectedMobile ?? this.items.length - 1;
    this.lastIndex = this.indexSelectedMobile ?? this.items.length - 1;
    this.selectFare(this.items[this.fareSelected], this.fareSelected);

    if (this.isMobile) {
      this.buildOwlCarousel();
    }
    setTimeout(() => {
      this.loader = false;
    }, 500);
  }

  selectFare(fare: any, index: number): void {
    if (index === this.lastIndex) {
      this.notFirstItem = false;
      this.fareSearchKey = null;
      this.fareSelectedObj = null;
      this.fareSelected = index;
    } else {
      this.notFirstItem = true;
      this.fareSearchKey = this.currentResult.fareSearchKey;
      this.fareSelectedObj = {
        ...fare,
        fareId: fare.fareId
      };
      this.fareSelected = index;
    }
  }

  public dismiss(): void {
    this.bsModalRef.hide();
  }

  public accept(): void {
    this.bsModalRef.content.callback(false, this.notFirstItem, this.fareSearchKey, this.fareSelectedObj);
    this.bsModalRef.hide();
  }

  getTypeName(type: string, bag: any): string {

    if (bag.weight > 0) {
      return this.translateService.instant('flight-fare-modal.type.kg', { kg: bag.weight });
    }

    let label = '';
    switch (type) {
      case 'PC':
        label = this.translateService.instant('flight-fare-modal.type.pc');
        break;
      case 'KG':
        label = this.translateService.instant('flight-fare-modal.type.kg', { kg: bag.weight });
        break;
      case 'LB':
        label = this.translateService.instant('flight-fare-modal.type.lb');
        break;

      default:
        label = this.translateService.instant('flight-fare-modal.type.default');
        break;
    }
    return label;
  }

  getTypeDescription(type: string, bag: any): string {
    let label = '';
    switch (type) {
      case 'Hand':
        label = this.translateService.instant('flight-fare-modal.type.hand');
        break;
      case 'Dispatch':
        label = this.translateService.instant('flight-fare-modal.type.dispatch');
        break;

      default:
        label = this.translateService.instant('flight-fare-modal.type.default');
        break;
    }
    return label;
  }

  buildOwlCarousel(): void {
    setTimeout(() => {
      // tslint:disable-next-line: space-before-function-paren
      $('.owl-carousel').each(function (): void {
        $(this).owlCarousel({
          loop: false,
          margin: 10,
          center: true,
          autoplay: false,
          nav: false,
          stagePadding: 50,
          responsive: {
            0: {
              items: 1
            }
          }
        });
      });
    }, 100);
  }

  ngAfterViewInit(): void { }
}
