import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'src/app/core/components/base/base-component';

@Component({
  selector: 'app-modal-validate-code-mobile',
  templateUrl: './modal-validate-code-mobile.component.html'
})
export class ModalValidateCodeMobileComponent extends BaseComponent implements OnInit, AfterViewInit {

  code: string;

  constructor(injector: Injector, public bsModalRef: BsModalRef) {
    super(injector);
  }

  ngOnInit(): void {
  }

  public dismiss(): void {
    this.bsModalRef.hide();
  }

  resendCode(): void {
    this.bsModalRef.content.callback('RESEND');
  }

  validate(): void {
    this.bsModalRef.content.callback(this.code);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document.getElementById('code').focus();
    }, 500);
  }
}
