import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';
import { Client } from '../../checkout/models/client.model';
@Injectable({
  providedIn: 'root'
})
export class HomeService extends BaseService<Client, number> {

  constructor(protected injector: Injector) {
      super(injector, '/client');
  }

  isRoundTripSubject = new Subject<boolean>();

  sendIsRoundTripPropagate(isRoundTrip: boolean): any {
    this.isRoundTripSubject.next(isRoundTrip);
  }

  getIsRoundTripPropagate(): Observable<any> {
    return this.isRoundTripSubject.asObservable();
  }


  public save(client: any): Observable<any> {
    return this.post(client, '/create-to-offer');
  }
}
