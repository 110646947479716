import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';
  language: string;
  constructor(private translateService: TranslateService) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      if (this.language === 'pt-br') {
        return {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10)
        };
      } else {
        return {
          month: parseInt(date[0], 10),
          day: parseInt(date[1], 10),
          year: parseInt(date[2], 10)
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    this.language = this.translateService.getDefaultLang();
    if (this.language === 'pt-br') {
      return date ? date.day + this.DELIMITER + ('0' + date.month).slice(-2) + this.DELIMITER + date.year : '';
    } else {
      return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
    }
  }
}
