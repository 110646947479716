import { ModalFlightFareComponent } from './components/modal-flight-fare/modal-flight-fare.component';
import { ModalFlightDetailPriceComponent } from './components/modal-flight-detail-price/modal-flight-detail-price.component';
import { ModalFlightDetailContentComponent } from './components/modal-flight-detail-content/modal-flight-detail-content.component';
import { FlightDetailContentComponent } from './components/flight-detail-content/flight-detail-content.component';
import { ModalFlightFilterComponent } from './components/modal-flight-filter/modal-flight-filter.component';
import { FlightFilterComponent } from './components/flight-filter/flight-filter.component';
import { ModalTravelerComponent } from './components/modal-traveler/modal-traveler.component';
import { HotelCardMobileComponent } from './components/hotel-card-mobile/hotel-card-mobile.component';
import { ModalInformationComponent } from './components/modal-information/modal-information.component';
import { HotelCardComponent } from './components/hotel-card/hotel-card.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ptBrLocale, plLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AutoAirportCompleteComponent } from './components/autocomplete-airport/autocomplete-airport.component';
import { AutoCompleteCityHotelComponent } from './components/autocomplete-city-hotel/autocomplete-city-hotel.component';
import { DatePickerRangeComponent } from './components/datepicker-range/datepicker-range.component';
import { ImageCropperModalModule } from './components/image-cropper/image-cropper-modal.module';
import { ModalAmenitiesMobileComponent } from './components/modal-amenities-mobile/modal-amenities-mobile.component';
import { ModalDatepickerRangeInlineComponent } from './components/modal-datepicker-range-inline/modal-datepicker-range-inline.component';
import { ModalFilterGuestsMobileComponent } from './components/modal-filter-guests-mobile/modal-filter-guests-mobile.component';
import { ModalFilterHotelMobileComponent } from './components/modal-filter-hotel-mobile/modal-filter-hotel-mobile.component';
import { ModalLanguageCurrencyComponent } from './components/modal-language-currency/modal-language-currency.component';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { ModalMapMobileComponent } from './components/modal-map-mobile/modal-map-mobile.component';
import { ModalOrderHotelMobileComponent } from './components/modal-order-hotel-mobile/modal-order-hotel-mobile.component';
import { ModalSearchCityHotelMobileComponent } from './components/modal-search-city-hotel-mobile/modal-search-city-hotel-mobile.component';
import { ModalSearchDepartureMobileComponent } from './components/modal-search-departure-mobile/modal-search-departure-mobile.component';
import { ModalSearchDestinationMobileComponent } from './components/modal-search-destination-mobile/modal-search-destination-mobile.component';
import { ModalSendCodeMobileComponent } from './components/modal-send-code-mobile/modal-send-code-mobile.component';
import { ModalValidateCodeMobileComponent } from './components/modal-validate-code-mobile/modal-validate-code-mobile.component';
import { DropdownDirective } from './directives/dropdown.directive';
import { MaskInputDirective } from './directives/mask-input.directive';
import { PipeModule } from './pipes/pipe.module';
import { FooterComponent } from './template/footer/footer.component';
import { HeaderComponent } from './template/header/header.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ModalGalleryComponent } from './components/modal-gallery/modal-gallery.component';
import { ModalHostingPoliciesComponent } from './components/modal-hosting-policies/modal-hosting-policies.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FlightCardDepartureComponent } from './components/flight-card-departure/flight-card-departure.component';
import { ModalFareRuleComponent } from './components/modal-fare-rule/modal-fare-rule.component';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { ModalFilterGuestsPackageMobileComponent } from './components/modal-filter-guests-package-mobile/modal-filter-guests-package-mobile.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { ModalConfirmationShareCartComponent } from './components/modal-confirmation-share-cart/modal-confirmation-share-cart.component';
import { ModalInsuranceComponent } from './components/modal-insurance/modal-insurance.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { ModalConfirmUpdateVersionComponent } from './components/modal-confirm-update-version/modal-confirm-update-version.component';
import { ModalConfirmQrCodeComponent } from './components/modal-confirm-qrcode/modal-confirm-qrcode.component';
import { ModalChooseAgentComponent } from './components/modal-choose-agent/modal-choose-agent.component';
import { ModalConfirmationCartExpiredComponent } from './components/modal-confirmation-cart-expired/modal-confirmation-cart-expired.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorCircleModule } from 'ngx-color/circle';
import { FlightCardComponent } from './components/flight-card/flight-card.component';
import { CustomIconComponent } from '../components/custom-icon/custom-icon.component';
import { FlightCardOutboundComponent } from './components/flight-card-outbound/flight-card-outbound.component';
import { ModalTermsConditionsComponent } from './components/modal-terms-conditions/modal-terms-conditions.component';
import { MaterialModule } from './material/material.module';
import { SpecificHotelNotFoundComponent } from './components/specific-hotel-not-found/specific-hotel-not-found.component';
import { ModalCancellationInfoComponent } from './components/modal-cancellation-info/modal-cancellation-info/modal-cancellation-info.component';
import { Modal3dsComponent } from './components/modal-3ds/modal-3ds.component';
import { WhenScrolledDirective } from './directives/when-scrolled.directive';

defineLocale('pt-br', ptBrLocale);
defineLocale('pt', plLocale);
@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDatepickerModule,
    TranslateModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing'
    }),
    TypeaheadModule,
    NgbModule,
    ModalModule,
    GoogleMapsModule,
    NgxSpinnerModule,
    NgxSliderModule,
    PipeModule,
    CollapseModule.forRoot(),
    InfiniteScrollModule,
    NgxMaskModule.forRoot(),
    ImageCropperModalModule,
    CarouselModule.forRoot(),
    NgxGalleryModule,
    PopoverModule.forRoot(),
    NgxSkeletonLoaderModule,
    CreditCardDirectivesModule,
    CreditCardDirectivesModule,
    NgxCurrencyModule,
    ColorSketchModule,
    ColorCircleModule,
    MaterialModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    AutoAirportCompleteComponent,
    AutoCompleteCityHotelComponent,
    ModalLoginComponent,
    ModalSearchDepartureMobileComponent,
    ModalSearchDestinationMobileComponent,
    ModalSearchCityHotelMobileComponent,
    ModalFilterGuestsMobileComponent,
    ModalFilterHotelMobileComponent,
    ModalOrderHotelMobileComponent,
    ModalMapMobileComponent,
    DatePickerRangeComponent,
    ModalDatepickerRangeInlineComponent,
    ModalSendCodeMobileComponent,
    ModalValidateCodeMobileComponent,
    ModalLanguageCurrencyComponent,
    DropdownDirective,
    ModalAmenitiesMobileComponent,
    MaskInputDirective,
    WhenScrolledDirective,
    HotelCardComponent,
    HotelCardMobileComponent,
    ModalInformationComponent,
    ModalGalleryComponent,
    ModalTravelerComponent,
    ModalHostingPoliciesComponent,
    FlightFilterComponent,
    ModalFlightFilterComponent,
    FlightDetailContentComponent,
    ModalFlightDetailContentComponent,
    ModalFlightDetailPriceComponent,
    FlightCardDepartureComponent,
    ModalFareRuleComponent,
    DigitOnlyDirective,
    ModalConfirmationComponent,
    ModalFlightFareComponent,
    ModalFilterGuestsPackageMobileComponent,
    ModalConfirmationShareCartComponent,
    ModalInsuranceComponent,
    ModalConfirmUpdateVersionComponent,
    ModalConfirmQrCodeComponent,
    ModalChooseAgentComponent,
    ModalConfirmationCartExpiredComponent,
    FlightCardComponent,
    CustomIconComponent,
    FlightCardOutboundComponent,
    ModalTermsConditionsComponent,
    SpecificHotelNotFoundComponent,
    ModalCancellationInfoComponent,
    Modal3dsComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    AutoAirportCompleteComponent,
    AutoCompleteCityHotelComponent,
    ModalLoginComponent,
    ModalSearchDepartureMobileComponent,
    ModalSearchDestinationMobileComponent,
    ModalSearchCityHotelMobileComponent,
    ModalFilterGuestsMobileComponent,
    ModalFilterHotelMobileComponent,
    ModalOrderHotelMobileComponent,
    ModalMapMobileComponent,
    DatePickerRangeComponent,
    ModalDatepickerRangeInlineComponent,
    ModalSendCodeMobileComponent,
    ModalValidateCodeMobileComponent,
    ModalLanguageCurrencyComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule,
    BsDatepickerModule,
    TypeaheadModule,
    NgbModule,
    ModalModule,
    GoogleMapsModule,
    NgxSpinnerModule,
    NgxSliderModule,
    PipeModule,
    CollapseModule,
    InfiniteScrollModule,
    NgxMaskModule,
    ImageCropperModalModule,
    DropdownDirective,
    ModalAmenitiesMobileComponent,
    MaskInputDirective,
    HotelCardComponent,
    HotelCardMobileComponent,
    CarouselModule,
    ModalInformationComponent,
    ModalHostingPoliciesComponent,
    NgxGalleryModule,
    ModalGalleryComponent,
    ModalTravelerComponent,
    FlightFilterComponent,
    ModalFlightFilterComponent,
    FlightDetailContentComponent,
    ModalFlightDetailContentComponent,
    PopoverModule,
    ModalFlightDetailPriceComponent,
    FlightCardDepartureComponent,
    ModalFareRuleComponent,
    DigitOnlyDirective,
    NgxSkeletonLoaderModule,
    ModalConfirmationComponent,
    ModalFlightFareComponent,
    ModalFilterGuestsPackageMobileComponent,
    CreditCardDirectivesModule,
    ModalConfirmationShareCartComponent,
    ModalInsuranceComponent,
    NgxCurrencyModule,
    ModalConfirmUpdateVersionComponent,
    ModalConfirmQrCodeComponent,
    ModalChooseAgentComponent,
    ModalConfirmationCartExpiredComponent,
    ColorSketchModule,
    ColorCircleModule,
    FlightCardComponent,
    CustomIconComponent,
    FlightCardOutboundComponent,
    ModalTermsConditionsComponent,
    MaterialModule,
    SpecificHotelNotFoundComponent,
    Modal3dsComponent
  ],
  providers: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
}
