<div class="modal-header">
    <div class="d-flex flex-column align-items-start w-100">
        <h5 class="modal-title font-weight-bold app-f18" id="exampleModalLabel">
            {{'modal-insurance.title' | translate}}
        </h5>
        <div class="modal-sub-title app-f12">
            {{'modal-insurance.sub-title' | translate}}
        </div>
    </div>
</div>
<div class="modal-body modal-body--float mb-3">

    <div class="" *ngIf="messageNotFound">
        <div class="not-found d-flex justify-content-center align-items-center">
            <h2 class="text-center">
                {{'modal-insurance.not-found' | translate}}
            </h2>
        </div>
    </div>

    <div *ngIf="loader" class="fare-loader d-flex">
        <span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
        <div class="fare-loader-text">
            {{'loader.loading' | translate}}
        </div>
    </div>

    <ng-template #insurances let-item let-index="index">
        <div class="custom-column" [ngClass]="{'active': index === insuranceSelected}">

            <div class="list">
                <div class="d-flex flex-column">
                    <div class="custom-column-header app-uppercase">
                        {{ item.name }}
                    </div>
                    <div class="custom-column-header-price mt-4" *ngIf="item.id">
                        +{{ item.fareByDays | currencyFormat:currencie.country }}
                    </div>
                    <span class="custom-column-header-perday" *ngIf="item.id">{{'modal-insurance.per-day' | translate}}</span>

                    <div class="custom-column-header-price mt-4" *ngIf="!item.id">
                        +{{ 0 | currencyFormat:currencie.country }}
                    </div>
                    <span class="custom-column-header-perday" *ngIf="!item.id">{{'modal-insurance.per-day' | translate}}</span>

                    <div class="app-divider"></div>

                    <div class="row">
                        <div class="col-12">
                            <ul class="list-group">
                                <li class="list-group-item border-0" *ngFor="let benefit of item.benefitsMock | slice:0:numSlice; let i=index">
                                    <div class="d-flex justify-content-center align-items-center mt-4 mb-4" *ngIf="item.image">
                                        <img src="assets/img/no-insurance.png" width="175px">
                                    </div>
                                    <div class="row">
                                        <div class="col-2 d-flex justify-content-center align-items-center">
                                            <i class="icon-check-1 app-f26 app-blue" *ngIf="benefit.id"></i>
                                            <i class="icon-cancel-1 app-f26" *ngIf="!benefit.id"></i>
                                        </div>
                                        <div class="col-10">
                                            {{ benefit.description }}
                                            <span class="font-weight-bold">
                                                {{ benefit.value }}
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item border-0" *ngIf="item.id">
                                    <div class="row app-pointer" (click)="openModal2(templateNested, item)">
                                        <div class="col-2 d-flex justify-content-center align-items-center">
                                            <i class="icon-check-1 app-f26 app-blue"></i>
                                        </div>
                                        <div class="col-10 d-flex justify-content-start align-items-center font-weight-bold  app-blue">
                                            {{'modal-insurance.plus-you' | translate}}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions mt-3">
                <hr class="m-0">
                <div class="mt-2" *ngIf="item.installments" style="padding: .4rem 1.5rem;">
                    <span class="custom-column-header-price-sub">
                        <b>{{'modal-insurance.total-insurance' | translate}}</b>
                    </span>
                    <br><br>
                    <span class="custom-column-header-price-sub mt-3" style="font-weight: normal;">
                        {{ item.installments[item.installments.length - 1].number }}x {{'checkout-cart.label.of' | translate}}
                    </span>
                    <span class="custom-column-header-price">
                        {{ item.installments[item.installments.length - 1].value | currencyFormat:currencie.country }}
                    </span>
                    <br>
                    <span class="custom-column-header-price-sub">{{'modal-insurance.or' | translate}} {{item.totalBookingWithInsurance | currencyFormat:currencie.country}}</span>
                    <span class="icon-info-circled-alt app-blue" #p="ngbPopover" (click)="p.open()" [ngbPopover]="popContent" triggers="mouseenter:mouseleave"></span>
                </div>
                <div class="mt-2" *ngIf="orderSummary && !item.installments" style="padding: .4rem 1.5rem;">
                    <span class="custom-column-header-price-sub">
                        <b> {{'modal-insurance.total-without-insurance' | translate}}</b>
                    </span>
                    <br><br>
                    <span class="custom-column-header-price-sub mt-3">
                        {{ orderSummary?.maxInstallment }}x {{'checkout-cart.label.of' | translate}}
                    </span>
                    <span class="custom-column-header-price">
                        {{ orderSummary?.priceInstallment | currencyFormat:currencie.country }}
                    </span>
                    <span class="custom-column-header-price-sub">{{'modal-insurance.no-coverage' | translate}}</span>
                    <br>
                    <span class="custom-column-header-price-sub">{{'modal-insurance.or' | translate}} {{orderSummary?.total | currencyFormat:currencie.country}}</span>
                </div>
                <div class="mt-2" *ngIf="orderSummaryPackage && !item.installments" style="padding: .4rem 1.5rem;">
                    <span class="custom-column-header-price-sub">
                        <b> {{'modal-insurance.total-without-insurance' | translate}}</b>
                    </span>
                    <br><br>
                    <span class="custom-column-header-price-sub mt-3">
                        {{ orderSummaryPackage?.maxInstallment }}x {{'checkout-cart.label.of' | translate}}
                    </span>
                    <span class="custom-column-header-price">
                        {{ orderSummaryPackage?.priceInstallment | currencyFormat:currencie.country }}
                    </span>
                    <span class="custom-column-header-price-sub">{{'modal-insurance.no-coverage' | translate}}</span>
                    <br>
                    <span class="custom-column-header-price-sub">{{'modal-insurance.or' | translate}} {{orderSummaryPackage?.total | currencyFormat:currencie.country}}</span>
                </div>

                <div class="custom-column-footer" *ngIf="index === insuranceSelected">
                    <button class="btn app-btn app-uppercase">
                        {{ 'flight-fare-modal.buttons.selected' | translate }}
                    </button>
                </div>

                <div class="custom-column-footer" *ngIf="index !== insuranceSelected">
                    <button class="btn app-btn-outline app-uppercase" (click)="selectInsurance(item, index)">
                        {{ 'flight-fare-modal.buttons.select' | translate }}
                    </button>
                </div>
            </div>
            <ng-template #popContent>
                <div class="d-flex flex-column m-1">
                    <span class="app-f16 font-weight-bold">{{'modal-insurance.popover.title' | translate}}</span>
                    <hr class="m-3">
                    <div class="d-flex flex-row justify-content-between">
                        <span class="app-f12 mr-5">
                            {{'modal-insurance.popover.value' | translate}}
                        </span>
                        <span class="app-f12 font-weight-bold">{{ item?.fareByDays | currencyFormat:currencie.country }}</span>
                    </div>
                    <div class="d-flex flex-row justify-content-between mt-2">
                        <span class="app-f12 mr-5">
                            {{'modal-insurance.popover.value-total-people' | translate: { days: item?.travelDays, people: qtdPassengers } }}
                            {{ qtdPassengers > 1 ? ('modal-insurance.popover.people' | translate) : ('modal-insurance.popover.person' | translate) }}
                        </span>
                        <span class="app-f12 font-weight-bold">{{ (item?.fare) | currencyFormat:currencie.country }}</span>
                    </div>
                    <div class="d-flex flex-row justify-content-between mt-2">
                        <span class="app-f12 mr-5">
                            {{'modal-insurance.popover.trip-value' | translate}}
                        </span>
                        <span class="app-f12 font-weight-bold">{{ item?.totalBooking | currencyFormat:currencie.country }}</span>
                    </div>
                    <hr class="m-3">
                    <div class="d-flex flex-row justify-content-between">
                        <span class="app-f12 font-weight-bold">
                            {{'modal-insurance.popover.final-value' | translate}}
                        </span>
                        <span class="app-f12 text-right">
                            <b>{{ item?.totalBookingWithInsurance | currencyFormat:currencie.country }}</b>
                            <br>
                            {{ item.installments[item.installments.length - 1].number }}x {{'checkout-cart.label.of' | translate}}
                            <b>{{ item.installments[item.installments.length - 1].value | currencyFormat:currencie.country }}</b>
                        </span>
                    </div>
                </div>
            </ng-template>

        </div>
    </ng-template>

    <div *ngIf="isMobile">
        <div id="owl-demo" class="owl-carousel owl-theme owl-carousel-fare">
            <div class="item" *ngFor="let item of products; let i=index">
                <ng-container *ngTemplateOutlet="insurances; context: {$implicit: item, index: i}"></ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="!isMobile">
        <div class="row">
            <div class="col-sm-12 col-md-4 strech-item" *ngFor="let item of products; let i=index">
                <ng-container *ngTemplateOutlet="insurances; context: {$implicit: item, index: i}"></ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="isMobile && products.length > 0" class="details mr-sm-auto d-flex flex-column justify-content-center justify-content-sm-start align-items-center align-items-sm-start p-3 p-sm-0">
        <p class="m-0 p-1 app-f14">
            {{'modal-insurance.know-and' | translate}}
            <span class="font-weight-bold app-blue app-pointer">
                <a href="https://www.travelace.com.br/CGE-Viagem-Individual.pdf" target="_blank" class="font-weight-bold app-blue app-pointer">
                    {{'modal-insurance.accept-details' | translate}}
                </a>
            </span>
            {{'modal-insurance.conditions' | translate}}
        </p>
    </div>

</div>

<div class="modal-footer" *ngIf="!loader">
    <div class="col-sm-12 d-flex flex-sm-row flex-column p-0">

        <div *ngIf="!isMobile && products.length > 0" class="details mr-sm-auto d-flex flex-column justify-content-center justify-content-sm-start align-items-center align-items-sm-start">
            <p class="m-0 p-1 app-f14">
                {{'modal-insurance.know-and' | translate}}
                <span class="font-weight-bold app-blue app-pointer">
                    <a href="https://www.travelace.com.br/CGE-Viagem-Individual.pdf" target="_blank" class="font-weight-bold app-blue app-pointer">
                        {{'modal-insurance.accept-details' | translate}}
                    </a>
                </span>
                {{'modal-insurance.conditions' | translate}}
            </p>
        </div>

        <button type="button" class="btn app-uppercase app-btn font-weight-bold ml-auto" (click)="accept()">
            {{'modal-insurance.proceed' | translate}}
        </button>
    </div>
</div>

<ng-template #templateNested>
    <div class="modal-header">
        <h4 id="dialog-nested-name1" class="modal-title pull-left">
            {{'modal-insurance.second-modal.title' | translate}}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body second modal-body--float mb-3 m-1">
        <div class="d-flex flex-row justify-content-sm-start justify-content-center">
            <h4 class="app-f18" *ngIf="!exteriorTrip">
                {{'modal-insurance.second-modal.naticional' | translate}}
            </h4>
            <h4 class="app-f18" *ngIf="exteriorTrip">
                {{'modal-insurance.second-modal.international' | translate}}
            </h4>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">
                            {{'modal-insurance.second-modal.coverage' | translate}}
                        </th>
                        <th scope="col" style="text-align: center;">{{ insuranceSelectedObj?.name }}</th>
                        <th scope="col" style="text-align: center;">{{ getBeneftiSecondColumnName(insuranceSelectedObj) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let benefit of insuranceSelectedObj?.benefits">
                        <td style="font-size: 14px;">
                            {{ benefit.description }}
                        </td>
                        <td style="font-size: 12px; text-align: center;" *ngIf="!benefit.hasCoverage">
                            {{ benefit.coverageValue }}
                        </td>
                        <td style="font-size: 12px; text-align: center;" *ngIf="benefit.hasCoverage">
                            {{'modal-insurance.second-modal.yes' | translate}}
                        </td>

                        <td style="font-size: 12px; text-align: center;" *ngIf="!benefit.hasCoverage">
                            {{ getBeneftiSecondColumn(insuranceSelectedObj, benefit.description) !== '-' ?
              (getBeneftiSecondColumn(insuranceSelectedObj, benefit.description)) :
              (getBeneftiSecondColumn(insuranceSelectedObj, benefit.description)) }}
                        </td>
                        <td style="font-size: 12px; text-align: center;" *ngIf="benefit.hasCoverage">
                            {{ getBeneftiSecondColumn(insuranceSelectedObj, benefit.description) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>