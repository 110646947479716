<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{'modal-search-departure.title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="min-height: 400px;">
  <div class="form-group">
    <app-autocomplete-airport
      [placeholder]="'modal-search-departure.input.placeholder.choose'"
      [isDeparture]="false"
      [isPresentLocation]="true"
      (selectAirport)="selectDeparture($event)">
    </app-autocomplete-airport>
  </div>
</div>
