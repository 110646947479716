import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Guest } from './models/guest.model';
import { PackageRoomFilter } from '@shared/models/package/package-room-filter.model';
import { TranslateService } from '@ngx-translate/core';
import Messages from 'src/app/core/enums/messages.enum';

@Component({
  selector: 'app-modal-filter-guests-package-mobile',
  templateUrl: './modal-filter-guests-package-mobile.component.html'
})
export class ModalFilterGuestsPackageMobileComponent extends BaseComponent implements OnInit {

  numberOfAdults: any;
  numberOfInfant: any;
  numberOfChild: any;
  numberOfRooms: any;
  guest: Guest;
  guestFilter: Guest;
  rooms: PackageRoomFilter[] = [];

  hasErros = false;
  message = '';

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.rooms = JSON.parse(JSON.stringify(this.rooms.slice()));
    this.guestFilter = JSON.parse(JSON.stringify(this.guest));
    this.rooms = this.rooms.slice();
    this.bsModalRef.setClass(this.modalCss);
  }

  addAnotherRoom(): void {
    this.guestFilter.numberOfRooms = (this.guestFilter.numberOfRooms + 1);
    this.guestFilter.numberOfAdults = (this.guestFilter.numberOfAdults + 1);
    this.rooms.push({ numberOfAdults: 1, numberOfInfant: 0, numberOfChild: 0, agesOfChild: [], roomNum: (this.rooms.length + 1) });
  }

  removeRoom(index: number): void {
    this.guestFilter.numberOfRooms = (this.guestFilter.numberOfRooms - 1);
    this.guestFilter.numberOfAdults = 0;
    this.guestFilter.numberOfChild = 0;
    this.guestFilter.numberOfInfant = 0;
    this.rooms.splice(index, 1);
    this.rooms.forEach(room => {
      this.guestFilter.numberOfAdults += room.numberOfAdults;
      this.guestFilter.numberOfChild += room.numberOfChild;
      this.guestFilter.numberOfInfant += room.numberOfInfant;
    });
  }

  addAdult(room: PackageRoomFilter): void {
    this.guestFilter.numberOfAdults = (this.guestFilter.numberOfAdults + 1);
    room.numberOfAdults = (room.numberOfAdults + 1);
  }

  subtractAdult(room: PackageRoomFilter): void {
    if (room.numberOfAdults >= 1) {
      this.guestFilter.numberOfAdults = (this.guestFilter.numberOfAdults - 1);
      room.numberOfAdults = (room.numberOfAdults - 1);
    }
  }

  addInfant(room: PackageRoomFilter): void {
    this.guestFilter.numberOfInfant = (this.guestFilter.numberOfInfant + 1);
    room.numberOfInfant = (room.numberOfInfant + 1);
  }

  subtractInfant(room: PackageRoomFilter): void {
    if (room.numberOfInfant >= 1) {
      this.guestFilter.numberOfInfant = (this.guestFilter.numberOfInfant - 1);
      room.numberOfInfant = (room.numberOfInfant - 1);
    }
  }

  addChildren(room: PackageRoomFilter): void {
    if (room.numberOfChild === 0) {
      room.arrNumberChildren = [];
      room.agesOfChild = [];
    }
    if (room.arrNumberChildren.length < 6) {
      this.guestFilter.numberOfChild = (this.guestFilter.numberOfChild + 1);
      room.numberOfChild = (room.numberOfChild + 1);
      room.arrNumberChildren.push(0);
      room.agesOfChild.push(null);
    }
    this.verifyAgeChild(room);
  }

  subtractChildren(room: PackageRoomFilter): void {
    if (room.numberOfChild >= 1) {
      this.guestFilter.numberOfChild = (this.guestFilter.numberOfChild - 1);
      room.numberOfChild = (room.numberOfChild - 1);
      room.agesOfChild.pop();
      room.arrNumberChildren.pop();
    }
    this.verifyAgeChild(room);
  }

  addAge(event, i: number, room: PackageRoomFilter): void {
    const age = +event.target.value;
    room.agesOfChild[i] = age;
    this.verifyAgeChild(room);
  }

  addInfants(): void {
    // this.flightSearch.infantCount = this.flightSearch.infantCount += 1;
    // this.verification();
  }

  subtractInfants(): void {
    // this.flightSearch.infantCount = this.flightSearch.infantCount -= 1;
    // this.verification();
  }

  verification(): void {
    // if ((this.flightSearch.adultsCount + this.flightSearch.childCount) < this.flightSearch.infantCount) {
    //   this.message = 'Informe pelo menos um adulto ou uma criança para cada bebê.';
    //   this.hasErros = true;
    //   return;
    // }
    // if (this.getNumTravelersFlight() > 9) {
    //   this.message = 'O número máximo de viajantes é 9, informe novos viajantes.';
    //   this.hasErros = true;
    //   return;
    // }
    // if (this.getNumTravelersFlight() === 0) {
    //   this.message = 'O número mínimo de viajantes é 1, informe novos viajantes.';
    //   this.hasErros = true;
    //   return;
    // }
    // this.hasErros = false;
  }

  public accept(): void {
    this.bsModalRef.content.callback(null, null);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null, null);
    this.bsModalRef.hide();
  }

  search(): void {
    this.bsModalRef.content.callback(this.rooms, this.guestFilter);
  }

  verifyAgeChild(room: PackageRoomFilter): void {
    var BreakException = {};
    try {
      room.agesOfChild.forEach((element: any, j: any) => {
        if (!element) {
          this.hasErros = true;
          this.message = this.translate.instant(Messages.CHILDREN_AGE);
          throw BreakException;
        } else {
          this.hasErros = false;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }

}
