<div class="modal-header">
  <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{ 'modal-update.title' | translate }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="accept()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float mb-3">
  <div class="column">
    <div class="col-sm-12">
      <div class="text-center app-f16 text-justify">
        {{ 'modal-update.text' | translate }}
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="col-sm-12 d-flex justify-content-center align-items-center p-0">
    <button type="button" class="btn btn-primary app-btn-orange btn-sm app-btn-round w-50 mr-1 font-weight-bold" (click)="accept()">
      {{ 'modal-update.button' | translate }}
    </button>
  </div>
</div>