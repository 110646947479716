import { AuthService } from '@shared/services/auth.service';
import { Component, HostListener, OnDestroy, OnInit, ApplicationRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@shared/components/loader/service/loader.service';
import { Currencie } from '@shared/models/currencie.model';
import { Institution } from '@shared/models/institution.model';
import { Title } from '@angular/platform-browser';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalConfirmUpdateVersionComponent } from '@shared/components/modal-confirm-update-version/modal-confirm-update-version.component';
import { ModalChooseAgentComponent } from '@shared/components/modal-choose-agent/modal-choose-agent.component';
import { environment } from '@environment/environment';

// tslint:disable-next-line: ban-types
declare let gtag: Function;
declare var $: any;

export class ZenConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad: boolean;
  timeOut: number;
  injectionTag: 'head' | 'body' | 'script' | string;
  accountUrl: string;
  callback(zE: any): any { }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'P2D Travel';
  langs = ['en-us', 'pt-br', 'pt'];

  institution: Institution;
  currencie: Currencie;
  clientIp: string;
  timeOutToShow = false;
  modalRefUpdate: BsModalRef;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private titleService: Title,
    private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService,
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private modalService: BsModalService
  ) {
    this.swUpdateClient();
    this.checkUpdateClient();
    this.clientIp = localStorage.getItem('client-ip');
  }

  ngOnInit(): void {
    this.authService.verifyTokenValid();

    const institution: Institution = JSON.parse(sessionStorage.getItem('institution'));
    const agencyPath = localStorage.getItem('agencyPath');
    if (!!institution) {

      if (!agencyPath || (agencyPath !== institution.agent.agencyPath)) {
        this.authService.logout();
      }

      if (institution.openModalSelectAgent) {
        setTimeout(() => {
          this.openModalChooseAgentComponent();
        }, 2000);
      }
      this.configChat(institution);
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.institution = JSON.parse(sessionStorage.getItem('institution'));
        this.loaderService.setInstitution(this.institution);
        this.loaderService.hide();
        if (!!event && event.url !== '/') {
          this.timeOutToShow = true;
        }
      }
    });
  }

  openModalChooseAgentComponent(): void {
    this.modalRefUpdate = this.modalService.show(ModalChooseAgentComponent, {
      class: 'modal-sm',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        callback: () => {
          this.modalRefUpdate.hide();
        },
      },
    });
  }

  swUpdateClient(): any {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.available.subscribe(() => {
      alert(this.translateService.instant('modal-update.text'));
      this.swUpdate.activateUpdate().then(() => document.location.reload());
    });
  }

  checkUpdateClient(): any {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(1 * 60 * 60 * 500);

        timeInterval.subscribe(() => {
          this.swUpdate.checkForUpdate();
        });
      }
    });
  }


  openModalConfirmUpdateVersion(): void {
    this.modalRefUpdate = this.modalService.show(ModalConfirmUpdateVersionComponent, {
      class: 'modal-sm',
      initialState: {
        callback: () => {
          this.swUpdate.activateUpdate().then(() => document.location.reload());
          this.modalRefUpdate.hide();
        },
      },
    });
  }

  async configChat(institution: Institution): Promise<void> {
    await this.ngxZendeskWebwidgetService.initZendesk(new ZenConfig());
    this.ngxZendeskWebwidgetService.zE('webWidget', 'chat:addTags', [`Agencia:${institution?.agent?.agencyName}`, `Institution:${institution?.applicationName}`]);
  }

  setDocTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  ngOnDestroy(): void {
    sessionStorage.clear();
  }

  public loadScript(url: string): void {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(e: KeyboardEvent): boolean {
  //   if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     return false;
  //   }
  //   return true;
  // }
}
