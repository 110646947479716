<div class="modal-header">
    <h4 class="modal-title">Photo</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="row">
    <div class="col-12 d-flex">
        <button (click)="zoomOut()" class="btn">Zoom -</button>
        <button (click)="zoomIn()" class="btn ml-auto">Zoom +</button>
    </div>
</div>

<image-cropper
  *ngIf="imageBase64"
  [imageBase64]="imageBase64"
  [maintainAspectRatio]="true"
  [aspectRatio]="4 / 3"
  [transform]="transform"
  [resizeToWidth]="400"
  [cropperMinWidth]="400"
  format="png"
  (imageCropped)="imageCropped($event)"
  (loadImageFailed)="loadImageFailed()"
></image-cropper>

<div class="modal-footer">
    <button type="button" class="btn btn-admin btn-secondary" (click)="decline()">Cancel</button>
    <button type="button" class="btn btn-primary btn-admin btn-primary-admin" (click)="accept()">OK</button>
</div>
