import { BlockedFilters } from './../../models/blocked-filters';
import { IFlightSearchKey } from './../../models/flight/flight-search-key.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-flight-filter',
  templateUrl: './flight-filter.component.html',
  styleUrls: ['./flight-filter.component.css']
})
export class FlightFilterComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() modal: number;
  @Input() flightSearchKey: IFlightSearchKey;
  @Input() marketingAirlinesAvailable: any;
  @Input() departureAirportsAvailable: any;
  @Input() arrivalAirportsAvailable: any;
  @Output() emitterFlightSearchKey = new EventEmitter<any>();
  @Output() emitterFlightSearchKeyCabinClass = new EventEmitter<any>();
  @Output() emitterFlightSearchKeyBaggage = new EventEmitter<any>();
  allClassesIsTrue: boolean;
  @Input() outboundFlight: any;
  showOutbound = true;
  @Input() blockedFilters: BlockedFilters;
  @Input() showFiltersOutbound: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.showOutbound = this.showFiltersOutbound;

    this.arrivalAirportsAvailable.filter(el => {
      return el.name = el?.name?.replace(/[_-]/g, '').replace(/[_–]/g, '');
    });

    this.departureAirportsAvailable.filter(el => {
      return el.name = el?.name?.replace(/[_-]/g, '').replace(/[_–]/g, '');
    });
  }

  changeOrdenation(ordenation: any): void {
    this.flightSearchKey.ordination = +ordenation;
    this.filterResult();
  }

  changeStops(stops: any): void {
    this.flightSearchKey.filter.maxStops = +stops;
    this.filterResult();
  }

  changeAirline(iata: string): void {

    if (iata) {
      const alreadyHasIata = this.flightSearchKey.filter.marketingAirlinesIata.includes(iata);

      if (!alreadyHasIata) {
        this.flightSearchKey.filter.marketingAirlinesIata.push(iata);
      } else {
        const index = this.flightSearchKey.filter.marketingAirlinesIata.indexOf(iata);
        if (index !== -1) {
          this.flightSearchKey.filter.marketingAirlinesIata.splice(index, 1);
        }
      }
    } else {
      this.flightSearchKey.filter.marketingAirlinesIata = [];
    }

    this.filterResult();
  }


  changeAirportDeparture(iata: string): void {

    if (iata) {
      const alreadyHasIata = this.flightSearchKey.filter.departureAirportsIata.includes(iata);

      if (!alreadyHasIata) {
        this.flightSearchKey.filter.departureAirportsIata.push(iata);
      } else {
        const index = this.flightSearchKey.filter.departureAirportsIata.indexOf(iata);
        if (index !== -1) {
          this.flightSearchKey.filter.departureAirportsIata.splice(index, 1);
        }
      }
    } else {
      this.flightSearchKey.filter.departureAirportsIata = [];
    }

    this.filterResult();
  }


  changeAirportReturn(iata: string): void {

    if (iata) {
      const alreadyHasIata = this.flightSearchKey.filter.arrivalAirportsIata.includes(iata);

      if (!alreadyHasIata) {
        this.flightSearchKey.filter.arrivalAirportsIata.push(iata);
      } else {
        const index = this.flightSearchKey.filter.arrivalAirportsIata.indexOf(iata);
        if (index !== -1) {
          this.flightSearchKey.filter.arrivalAirportsIata.splice(index, 1);
        }
      }
    } else {
      this.flightSearchKey.filter.arrivalAirportsIata = [];
    }

    this.filterResult();
  }

  changeClass(classe: any): void {
    this.flightSearchKey.filter.cabinClasses = classe ? +classe : null;
    this.filterResultCabinClass();
  }

  changeBaggage(): void {
    this.flightSearchKey.filter.allowDispatchBaggage = !this.flightSearchKey.filter.allowDispatchBaggage;
    this.filterResultBaggage();
  }

  changeDepartureTime(sdh: number, sdm: number, edh: number, edm: number, notAll: boolean): void {
    if (notAll) {
      this.flightSearchKey.filter.startDepartureTime = { hour: sdh, minute: sdm };
      this.flightSearchKey.filter.endDepartureTime = { hour: edh, minute: edm };
    } else {
      this.flightSearchKey.filter.startDepartureTime = null;
      this.flightSearchKey.filter.endDepartureTime = null;
    }
    this.filterResult();
  }

  changeReturnTime(srh: number, srm: number, erh: number, erm: number, notAll: boolean): void {
    if (notAll) {
      this.flightSearchKey.filter.startDepartureTime = { hour: srh, minute: srm };
      this.flightSearchKey.filter.endDepartureTime = { hour: erh, minute: erm };
    } else {
      this.flightSearchKey.filter.startDepartureTime = null;
      this.flightSearchKey.filter.endDepartureTime = null;
    }
    this.filterResult();
  }

  filterBaggageAllowance(): void {
    this.filterResult();
  }

  ngOnChanges(): void {
    this.showOutbound = this.showFiltersOutbound;
    this.arrivalAirportsAvailable.filter(el => {
      return el.name = el?.name?.replace(/[_-]/g, '').replace(/[_–]/g, '');
    });

    this.departureAirportsAvailable.filter(el => {
      return el.name = el?.name?.replace(/[_-]/g, '').replace(/[_–]/g, '');
    });
    this.marketingAirlinesAvailable = this.marketingAirlinesAvailable;
    this.blockedFilters = this.blockedFilters;
  }


  filterResult(): void {
    this.emitterFlightSearchKey.emit({ value: this.flightSearchKey, eventByFilter: true });
  }

  filterResultCabinClass(): void {
    this.emitterFlightSearchKeyCabinClass.emit({ value: this.flightSearchKey, eventByFilter: true, type: 'cabin' });
  }

  filterResultBaggage(): void {
    this.emitterFlightSearchKeyBaggage.emit({ value: this.flightSearchKey, eventByFilter: true, type: 'baggage' });
  }

  classesIsTrue(arr, arr2): any {
    this.allClassesIsTrue = arr.every(i => arr2.includes(i));
  }

}
