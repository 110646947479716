<div class="modal-header">
  <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{title}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float mb-3" style="min-height: 400px;">
  <div class="column">
    <div class="col-sm-12" *ngFor="let info of informations">
      <div class="app-f16 text-justify">
        {{ info }}
      </div>
    </div>
  </div>
</div>
