import { HomeService } from './../../../components/home/services/home.service';
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DateUtil } from 'src/app/core/utils/date-util';
import { CustomDateParserFormatter } from '../utils/custom-date-parser-formatter';
import { CustomDatepickerI18n } from '../utils/custom-date-picker-i18n';

declare var $: any;
@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class DatePickerRangeComponent implements OnInit, OnDestroy {

  @Output() selectDate = new EventEmitter();
  @Output() search = new EventEmitter();
  @Input() id: string;
  @Input() typeSearch: string;
  @Input() isRoundTrip: boolean;
  @Input() isInvalid: boolean;
  subscription: Subscription;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  minDate;
  days: number;
  labelValue = false;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private notificationService: NotificationService,
    private homeService: HomeService
  ) {
    const current = new Date();
    current.setDate(current.getDate() + 1);
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  ngOnInit(): void {
    this.subscription = this.homeService.getIsRoundTripPropagate().subscribe(isRoundTrip => {
      this.hoveredDate = null;
      this.fromDate = null;
      this.toDate = null;
      this.days = null;
      this.labelValue = false;
      const current = new Date();
      current.setDate(current.getDate() + 1);
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
    });
  }

  public onDateSelection(date: NgbDate, datepicker: any): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.labelValue = true;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      if (this.typeSearch === '2') {
        this.days = DateUtil.getDaysBetweenDates(DateUtil.ngbDateToJsDate(this.fromDate),
          DateUtil.ngbDateToJsDate(this.toDate));
        if (this.days > 31) {
          this.notificationService.messageWarn('Informar check-in e check-out com menos de 31 dias');
          this.fromDate = date;
          this.toDate = null;
          this.propagateValues();
        } else {
          datepicker.close();
          this.propagateValues();
        }
      } else {
        this.toDate = date;
        this.propagateValues();
        datepicker.close();
      }
    } else {
      this.fromDate = date;
      this.toDate = null;
    }
  }

  public onDateSelectionFlightGo(date: NgbDate, datepicker: any): void {
    this.fromDate = date;
    this.toDate = null;
    this.propagateValues();
    datepicker.close();
  }

  onIsRoundTrip(isRoundTrip: boolean): void {
    this.isRoundTrip = !isRoundTrip;
    this.fromDate = null;
    this.toDate = null;
  }

  private propagateValues(): void {
    const emitter = [
      DateUtil.ngbDateToJsDate(this.fromDate)
    ];
    if (this.toDate) {
      emitter.push(
        DateUtil.ngbDateToJsDate(this.toDate)
      );
    }
    this.selectDate.emit(emitter);
  }

  public formatDate(): string {
    return this.formatter.format(this.fromDate) + (this.toDate ? ' - ' + this.formatter.format(this.toDate) : '');
  }

  public isHovered(date: NgbDate): any {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate): any {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate): any {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  public validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public clickSearch(): void {
    this.search.emit({});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
