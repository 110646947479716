export function agentConfiguration(arrConfig: any): any {

  const configs = {
    Copyright: '',
    DateTimeFormat: '',
    DefautCurrency: '',
    DefautLanguage: '',
    DocumentFormat: '',
    DocumentType: '',
    PrivacyPolicy: '',
    SocialSecurity: '',
    TermsAndConditions: '',
    YoutubePresentation: '',
    AllowChangeCurrency: '',
    CompanyDocumentType: '',
    CompanyDocumentFormat: '',
    ZipCodeFormat: '',
    MobilePhoneFormat: '',
    StateOrProviceFormat: '',
    PaymentMethods: ''
  };

  for (const iterator of arrConfig) {
    const key = iterator.name;
    const value = iterator.value;
    arrConfig[key] = value;
  }
  return arrConfig;

}
