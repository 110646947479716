import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Currencie } from './../models/currencie.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencieService {

  currencies: Currencie[] = [];
  subscCurrencie = new Subject<any>();

  constructor() {
  }

  public loadCurrencies(): Currencie[] {
    return this.currencies;
  }

  public propagateCurrencieToSearch(currencie): void {
    if (currencie) {
      this.subscCurrencie.next(currencie);
    }
  }

  public propagateCurrencieToCart(currencie): void {
    if (currencie) {
      this.subscCurrencie.next(currencie);
    }
  }

  setCurrencieById(currencieId: number): void {
    this.currencies = this.loadCurrencies();
    const deafultCurrencie = this.currencies.find(element => element.currencyTypeId === currencieId);
    sessionStorage.setItem('currencie', JSON.stringify(deafultCurrencie));
    this.propagateCurrencieToCart(deafultCurrencie);
  }

  setCurrencieByDefaultLanguage(defaultCurrency: string, defaultLanguage: string): void {
    this.currencies = this.loadCurrencies();
    const deafultCurrencie = this.currencies.find(element => element.code === defaultCurrency);

    if (deafultCurrencie) {
      deafultCurrencie.defaultLanguage = defaultLanguage;
      sessionStorage.setItem('currencie', JSON.stringify(deafultCurrencie));
    } else {
      this.currencies[0].defaultLanguage = defaultLanguage;
      sessionStorage.setItem('currencie', JSON.stringify(this.currencies[0]));
    }

    const currencie = JSON.parse(sessionStorage.getItem('currencie'));
    if (!currencie) {
      sessionStorage.removeItem('currencie');
      if (deafultCurrencie) {
        sessionStorage.setItem('currencie', JSON.stringify(deafultCurrencie));
      } else {
        sessionStorage.setItem('currencie', JSON.stringify(this.currencies[0]));
      }
    }
  }

  public setCurrencieAllowInstitution(currencies: Currencie[]) {
    this.currencies = currencies.filter(item => item.isActive == true);
  }
}
