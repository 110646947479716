<!-- <body class="datepicker_mobile_full"> -->
<!-- Remove this class to disable datepicker full on mobile -->
<div class="content-page">

  <div class="header">
    <app-header [institution]="institution" [currencie]="currencie"></app-header>
    <div id="page">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="footer">
    <ng-container *ngIf="timeOutToShow">
      <app-footer [institution]="institution" [timeOutToShow]="timeOutToShow"></app-footer>
    </ng-container>
  </div>

</div>

<app-loader></app-loader>
<!-- </body> -->
