import { Component, Input, OnInit } from '@angular/core';
import { Currencie } from '@shared/models/currencie.model';
import { BaggageAllowance } from 'src/app/components/flight-list/models/baggage-allowance.model';
import { Journey } from 'src/app/components/flight-list/models/journey.model';

@Component({
  selector: 'app-flight-card-outbound',
  templateUrl: './flight-card-outbound.component.html',
  styleUrls: ['./flight-card-outbound.component.scss']
})
export class FlightCardOutboundComponent implements OnInit {

  @Input() journey: Journey;
  currencie: Currencie;
  flightDirection: string;
  reverseIcon: boolean;
  handBag: BaggageAllowance[]
  baggage: BaggageAllowance[];

  private _inboudFlight: Journey;
  get inboudFlight(): Journey {
    return this._inboudFlight;
  }

  @Input() set inboudFlight(value: Journey) { 
    this._inboudFlight = value;
    this.flightDirection = 'flight-list.label.back';
    this.reverseIcon = true;
    this.journey = this._inboudFlight;
    this.checkBaggageAllowance(this.journey?.baggagesAllowance);
  }

  private _outboundFlight: Journey;
  get outboundFlight(): Journey {
    return this._outboundFlight;
  }

  @Input() set outboundFlight(value: Journey) {
    this._outboundFlight = value;
    this.flightDirection = 'flight-list.label.go';
    this.journey = this._outboundFlight;
    this.checkBaggageAllowance(this.journey?.baggagesAllowance);
  }

  constructor() { }

  ngOnInit(): void {
    this.currencie = JSON.parse(sessionStorage.getItem('currencie'));
  }

  convertDate({ year, month, day }) {
    return new Date(year, month - 1, day);
  }

  checkBaggageAllowance(bag: BaggageAllowance[]) {
    this.handBag = bag?.filter(b => b.typeDescription === 'Hand' && (b.quantity > 0 || b.weight > 0));
    this.baggage = bag?.filter(b => b.typeDescription === 'Dispatch' && (b.quantity > 0 || b.weight > 0));
  }
}
