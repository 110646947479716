import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './../../core/services/base.service';
import { CityHotel } from './../models/city-hotel.model';

@Injectable({
    providedIn: 'root',
})
export class PointOfInterestService extends BaseService<CityHotel, number> {
    constructor(protected injector: Injector) {
        super(injector, '/pointOfInterest');
    }

    public searchPointOfInterest(searchParam: string): Observable<any> {
        return this.get(`/${searchParam}`, false);
    }
}