<div class="connection">

  <div *ngFor="let segment of journey.segments; let first = first">

    <div class="card card-info m-3">
      <div class="card-body app-f12-16 p-2 font-weight-500 text-center" *ngIf="segment.connectionTime">
        {{ 'flight-list.label-detail-content.connection-in' | translate }} {{segment.departure.name}}
        {{'flight-list.label-detail-content.waiting-for' | translate }} {{segment.connectionTime.hour}}h
        {{segment.connectionTime.minute | number:'2.0'}}m
      </div>
    </div>

    <div class="card card-info m-3">
      <div class="card-body app-f12-16 p-2 font-weight-500 text-center" *ngIf="!segment.connectionTime && !first">
        {{ 'flight-list.label-pop-connection.warning' | translate }}
      </div>
    </div>

    <div class="card card-detail-flight mb-1">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <span class="text-left">
            <span *ngIf="!journey.marketingAirline.pathLogo">{{ journey?.marketingAirline?.name }}</span>
            <img [src]="journey.marketingAirline.pathLogo" width="60px" alt="">
          </span>

          <label class="app-black app-f12-14 mb-0">
            {{ 'flight-list.label-detail-content.flight' | translate }} {{ segment.flightNumber }}
          </label>
        </div>
        <div class="d-flex justify-content-between mt-4">
          <div class="wrapper-detail-content">
            <div class="grid-1">
              <div class="d-flex flex-column justify-content-between">
                <div class="d-flex flex-column justify-content-center align-items-center date-time-departure">
                  <label class="app-black app-f14-16 mb-0 font-weight-normal">
                    {{ 'flight-list.label-detail-content.departure' | translate }}
                  </label>
                  <label class="app-black-light app-f12-16 mb-0 font-weight-500">
                    {{convertDate(segment.departure.date.year, segment.departure.date.month, segment.departure.date.day)
                    | dateLanguageFormat: currencie.defaultLanguage: 'EEE'}}
                    {{ convertDate(segment.departure.date.year, segment.departure.date.month,
                    segment.departure.date.day) | dateLanguageFormat: currencie.defaultLanguage: 'd MMM'}}
                  </label>
                  <label class="app-black app-f18-22 mb-0 font-weight-bold">
                    {{segment.departure.time.hour | number:'2.0'}}:{{segment.departure.time.minute | number:'2.0'}}
                  </label>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center date-time-departure mt-4">
                  <label class="app-black app-f12-14 mb-0 text-center">{{segment.departure.city}}</label>
                  <label
                    class="app-black app-f12-18 font-weight-bold text-decoration-underline mb-0 mt-1">{{segment.departure.iata}}</label>
                  <label class="app-black app-f10-12 mb-0 mt-2 text-center">{{segment.departure.name}}</label>
                </div>
              </div>
            </div>
            <div class="grid-2">
              <div class="d-flex flex-column justify-content-center w-flight-detail-80 mf-flight-detail-10">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <small class="app-f10 app-grey text-center">
                    {{ journey.marketingAirline.name }}
                  </small>
                  <ng-container *ngIf="journey.marketingAirline.icao !== journey.operatingAirline.icao">
                    <label class="app-black-light app-f10 mb-0 font-weight-500">
                      {{ 'flight-list.label-detail-content.operated-by' | translate }}
                    </label>
                    <small class="app-f10 app-grey text-center">
                      {{ journey.operatingAirline.name }}
                    </small>
                  </ng-container>

                  <span class="d-flex justify-content-center align-items-center w-100">
                    <img src="./assets/img/icone-aviao.png" class="icon-airplane" alt="">
                    <span class="line-conection"></span>
                    <img src="./assets/img/icon-pin.png" class="icon-airplane" alt="">
                  </span>

                  <ng-container *ngIf="journey.fareClassControlModal; else originalClass">
                    <label
                      class="app-black-light app-f12-14 mb-0 font-weight-500">{{segment.fareClassControlModalCabinClass}}</label>
                    <label class="app-black-light app-f2-14 mb-0 font-weight-500 text-center"
                      *ngIf="!!segment.fareClassControlModalAirlineFareFamily">
                      {{ 'flight-list.label-detail-content.tariff' | translate }}:
                      {{ segment.fareClassControlModalAirlineFareFamily }}
                    </label>
                  </ng-container>
                  <ng-template #originalClass>
                    <label class="app-black-light app-f12-14 mb-0 font-weight-500">
                      {{segment.cabinClass}}
                    </label>
                    <label class="app-black-light app-f2-14 mb-0 font-weight-500 text-center"
                      *ngIf="!!segment.airlineFareFamily">
                      {{ 'flight-list.label-detail-content.tariff' | translate }}: {{ segment.airlineFareFamily }}
                    </label>
                  </ng-template>

                </div>
              </div>
            </div>
            <div class="grid-3">
              <div class="d-flex flex-column justify-content-between ">
                <div class="d-flex flex-column justify-content-center align-items-center date-time-departure">
                  <label class="app-black app-f14-16 mb-0 font-weight-normal">
                    {{ 'flight-list.label-detail-content.arrival' | translate }}
                  </label>
                  <label class="app-black-light app-f12-16 mb-0 font-weight-500">
                    {{ convertDate(segment.destination.date.year, segment.destination.date.month,
                    segment.destination.date.day) | dateLanguageFormat: currencie.defaultLanguage: 'EEE'}}
                    {{ convertDate(segment.destination.date.year, segment.destination.date.month,
                    segment.destination.date.day) | dateLanguageFormat: currencie.defaultLanguage: 'd MMM'}}
                  </label>
                  <label class="app-black app-f18-22 mb-0 font-weight-bold">
                    {{segment.destination.time.hour | number:'2.0'}}:{{segment.destination.time.minute | number:'2.0'}}
                  </label>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center date-time-departure mt-4">
                  <label class="app-black app-f12-14 mb-0 text-center">
                    {{segment.destination.city }}
                  </label>
                  <label class="app-black app-f12-18 font-weight-bold text-decoration-underline mb-0 mt-1">
                    {{segment.destination.iata}}
                  </label>
                  <label class="app-black app-f10-12 mb-0 mt-2 text-center">
                    {{segment.destination.name}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="card card-info m-3">
  <div class="card-body app-f12-16 p-2 font-weight-500 text-center">
    {{ 'flight-list.label-detail-content.total-flight' | translate }} {{ journey.flyingTime.hour }}h
    {{journey.flyingTime.minute | number:'2.0'}}m
  </div>
  <div class="card-body app-f12-16 p-2 font-weight-500 text-center">
    {{ 'flight-list.label-detail-content.local-time-for-each-city' | translate }}
  </div>
</div>

<!-- <div class="card card-detail-bag m-3">
  <div class="card-body">

    <div class="d-flex flex-column">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <div class="w-15" *ngIf="journey.allowedBaggage">
          <img src="assets/img/luggage-blue.png" class="" width="24px" alt="">
        </div>
        <div class="w-15" *ngIf="!journey.allowedBaggage">
          <img src="assets/img/luggage-grey.png" class="" width="24px" alt="">
        </div>
        <div class="w-85 text-left" *ngIf="journey.allowedBaggage">
          <div class="d-flex flex-column">
            <label class="app-blue app-f14-12 font-weight-bold mb-0">{{ 'flight-list.label-pop-luggage.carry-on' |
              translate }}</label>
            <label class="app-black app-f14-12 font-weight-normal mb-0">
              {{ 'flight-list.label-pop-luggage.carry-on-text' | translate }}
            </label>
          </div>
        </div>
        <div class="w-85 text-left" *ngIf="!journey.allowedBaggage">
          <div class="d-flex flex-column">
            <label class="app-black app-f14-12 font-weight-bold mb-0">{{ 'flight-list.label-pop-luggage.no-carry-on' |
              translate }}</label>
          </div>
        </div>
      </div>
      <hr>
      <div class="d-flex flex-row justify-content-start align-items-center">
        <div class="w-15"><img src="assets/img/luggage-grey.png" class="" width="34px" alt=""></div>
        <div class="w-85 text-left">
          <div class="d-flex flex-column">
            <label class="app-black app-f14-12 font-weight-bold mb-0">
              {{ 'flight-list.label-pop-luggage.no-checked-bag' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->