<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{'modal-filter-hotel.label.filter' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float ml-3 mr-3">
  <app-flight-filter [modal]="modal" [showFiltersOutbound]="showFiltersOutbound" [flightSearchKey]="flightSearchKey" [blockedFilters]="blockedFilters"
    [marketingAirlinesAvailable]="marketingAirlinesAvailable" [departureAirportsAvailable]="departureAirportsAvailable"
    [arrivalAirportsAvailable]="arrivalAirportsAvailable" [outboundFlight]="outboundFlight"></app-flight-filter>
</div>
<div class="modal-footer">
  <div class="col-sm-12 d-flex justify-content-between align-items-center p-0">
    <button type="button" class="btn btn-primary app-btn-orange btn-sm app-btn-round w-50 mr-1 ml-auto font-weight-bold"
      (click)="clear()">
      {{'modal-filter-hotel.button.clear' | translate}}
    </button>
    <button type="button" class="btn btn-primary btn-sm app-btn w-50 mr-auto ml-1 font-weight-bold" (click)="search()">
      {{'modal-filter-hotel.button.search' | translate}}
    </button>
  </div>
</div>