import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'currencyFormat' })
export class CurrencyFormatPipe implements PipeTransform {

  transform(value: string, currencyCode: string = 'BRL'): string {
    let valueFormated = '';

    switch (currencyCode) {
      case 'BRL':
        const currencyPtBr: CurrencyPipe = new CurrencyPipe('pt-BR', 'BRL');
        valueFormated = currencyPtBr.transform(value, currencyCode, 'R$');
        break;
      case 'USD':
        const currencyEn: CurrencyPipe = new CurrencyPipe('en-US', 'USD');
        valueFormated = currencyEn.transform(value, currencyCode, '$');
        break;
      case 'EUR':
        const currencyEu: CurrencyPipe = new CurrencyPipe('pt-PT', 'EUR');
        valueFormated = currencyEu.transform(value, currencyCode, '€');
        break;
      case 'MXN':
        const currencyMx: CurrencyPipe = new CurrencyPipe('es-MX', 'MXN');
        valueFormated = currencyMx.transform(value, 'es-MX', 'MXN$ ', null, 'en-US');
        break;
      default:
        valueFormated = value;
        break;
    }
    return valueFormated;
  }
}
