export let productsMockInternational = [
  {
    benefits: [
      {
        coverageValue: 150000,
        description: 'Despesas médicas e/ou hospitalares até',
        value: 'US$150mil',
        hasCoverage: false,
        id: true,
        order: 1,
      },
      {
        coverageValue: 1500,
        description: 'Despesas odontológicas até',
        value: 'US$1,5mil',
        hasCoverage: false,
        id: true,
        order: 2,
      },
      {
        coverageValue: 30000,
        description: 'Despesa médica por Covid-19 até',
        value: 'US$30mil',
        hasCoverage: false,
        id: true,
        order: 3,
      },
      {
        coverageValue: 60000,
        description: 'Traslado médico até',
        value: 'US$60mil',
        hasCoverage: false,
        id: true,
        order: 4,
      },
      {
        coverageValue: 1500,
        description: 'Despesas farmacêuticas até',
        value: 'US$1,5mil',
        hasCoverage: false,
        id: true,
        order: 5,
      },
      {
        coverageValue: 2500,
        description: 'Retorno atencipado do segurado até',
        value: 'US$2,5mil',
        hasCoverage: false,
        id: true,
        order: 6,
      },
      {
        coverageValue: 1500,
        description: 'Perda de bagagem em viagem até',
        value: 'US$1,5mil',
        hasCoverage: false,
        id: true,
        order: 7,
      },
    ],
    fare: true,
    fareByDays: true,
    id: '5376716',
    name: 'Com seguro Excellence',
    totalBookingWithInsurance: true,
    installments: []
  },
  {
    benefits: [
      {
        coverageValue: 800000,
        description: 'Despesas médicas e/ou hospitalares até',
        value: 'US$80mil',
        hasCoverage: false,
        id: true,
        order: 1,
      },
      {
        coverageValue: 800,
        description: 'Despesas odontológicas até',
        value: 'US$800',
        hasCoverage: false,
        id: true,
        order: 2,
      },
      {
        coverageValue: 30000,
        description: 'Despesa médica por Covid-19 até',
        value: 'US$30mil',
        hasCoverage: false,
        id: true,
        order: 3,
      },
      {
        coverageValue: 40000,
        description: 'Traslado médico até',
        value: 'US$40mil',
        hasCoverage: false,
        id: true,
        order: 4,
      },
      {
        coverageValue: 1000,
        description: 'Despesas farmacêuticas até',
        value: 'US$1mil',
        hasCoverage: false,
        id: true,
        order: 5,
      },
      {
        coverageValue: 2000,
        description: 'Retorno atencipado do segurado até',
        value: 'US$2mil',
        hasCoverage: false,
        id: true,
        order: 6,
      },
      {
        coverageValue: 1500,
        description: 'Perda de bagagem em viagem',
        value: 'US$1,5mil',
        hasCoverage: false,
        id: true,
        order: 7,
      },
    ],
    fare: true,
    fareByDays: true,
    id: '5376717',
    name: 'Com seguro Value',
    totalBookingWithInsurance: true,
    installments: []
  }
];
