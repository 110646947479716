import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector, Inject, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InstitutionService } from '@shared/services/institution.service';
import { DOCUMENT } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-choose-agent',
  templateUrl: './modal-choose-agent.component.html',
  styleUrls: ['./modal-choose-agent.component.css']
})
export class ModalChooseAgentComponent extends BaseComponent implements OnInit {
  @ViewChild('searchTextElement', { static: false }) searchTextInput: ElementRef;

  title = 'angular-text-search-highlight';
  searchText = '';
  characters = [];
  urlOrigin = location.origin;
  hashOrder = uuidv4();
  agentPage = 0;

  constructor(
    injector: Injector,
    public bsModalRefUpdate: BsModalRef,
    private institutionService: InstitutionService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(injector);
  }

  ngOnInit(): void {
    const sizeModal = this.isMobile ? 'modal-dialog-centered full-screen-modal' : 'modal-dialog-centered modal-lg';
    this.bsModalRefUpdate.setClass(sizeModal);
    this.getAgents();
  }

  public accept(): void {
    this.bsModalRefUpdate.content.callback(true);
    this.bsModalRefUpdate.hide();
  }

  getAgents(clear: boolean = false): void {
    if (clear) {
      this.agentPage = 0;
    }
    this.institutionService.getAgentsPaginated(this.searchText, this.hashOrder, this.agentPage).subscribe((result: any) => {
      if (clear) {
        this.characters = result.data;
      } else {
        this.characters.push(...result.data);
      }
    });
  }

  goAgency(agency: any): void {
    this.document.location.href = `${location.origin}/${agency.agencyPath}`;
  }

  loadMore() {
    this.agentPage++;
    this.getAgents();
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchTextInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.getAgents(true);
    });
  }
}
