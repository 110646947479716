<div id="sign-in-dialog" class="zoom-anim-dialog ">
  <div class="small-dialog-header">
    <h3>Configuração Regional</h3>
    <button title="Close (Esc)" type="button" class="mfp-close " data-dismiss="modal" aria-label="Close" id="closeModal"
      (click)="dismiss()"></button>
  </div>
  <form>
    <div class="sign-in-wrapper">
      <div class="form-group">
        <label>Idioma</label>
        <select class="form-control" id="select-area" (change)="changeLanguage($event)">
          <option value="en" [selected]="languageId === 'en-us'">
            <img src="./assets/img/united-states.png" style="width: 17px;" alt="">
            &nbsp;Inglês<i class="fa fa-check" aria-hidden="true"></i>
          </option>
          <option value="pt-br" [selected]="languageId === 'pt-br'">
            <img src="./assets/img/portugal.png" style="width: 17px;" alt="">
            &nbsp;Português<i class="fa fa-check" aria-hidden="true"></i>
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Moeda</label>
        <select class="form-control" id="select-area" (change)="changeCurrencie($event)">
          <option value="{{currencie.id}}" [selected]="currencieId === currencie.id"
            *ngFor="let currencie of currencies">{{currencie.symbol + ' ' + currencie.code}}</option>
        </select>
      </div>
    </div>
    <div class="">
      <button type="button" class="btn btn-primary ml-1" (click)="save()">Salvar</button>
    </div>

  </form>
  <!--form -->
</div>
