import { InstitutionService } from 'src/app/shared/services/institution.service';
import { Subject, Subscription } from 'rxjs';
import { CurrencieService } from './../../services/currencie.service';
import { Currencie } from './../../models/currencie.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit, OnDestroy, TemplateRef, ViewChild, Inject } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Messages from 'src/app/core/enums/messages.enum';
import { UserSession } from 'src/app/core/models/user-session.model';
import { AuthCode } from '../../models/auth-code.model';
import { AuthService } from '../../services/auth.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Institution } from '../../models/institution.model';
import { agentConfiguration } from '@shared/agentConfiguration';

import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { NavigationService } from '@shared/services/navigation.service';
import { ModalTermsConditionsComponent } from '../modal-terms-conditions/modal-terms-conditions.component';
import dayjs from 'dayjs';
import { LanguageModel } from '@shared/models/language.model';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from '@shared/services/language.service';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styles: ['.example-full-width {width: 100%;}']
})
export class ModalLoginComponent extends BaseComponent implements OnInit, OnDestroy {

  isLogin = true;
  isSentEmail = false;
  formSubmitted = false;
  currencies: Currencie[] = [];
  currencie: Currencie;
  language: LanguageModel = { codeIso: '' };
  languages: LanguageModel[] = [];
  languageId: string;
  currencieId: number;
  buttonLoading = false;
  institution: Institution;
  isCheckout: boolean;
  disableCurrency = false;
  instiSubscription: Subscription;
  disableCurrencySubscription: Subscription;
  configs: any;

  verifyTokenExpireFromGuard: boolean;
  public onClose: Subject<boolean>;

  modalRef2?: BsModalRef;
  sizeModal: any = 'modal-dialog-centered modal-md';
  authCodeToTerms: any;

  form!: FormGroup;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private translateService: TranslateService,
    private currencieService: CurrencieService,
    private bsLocaleService: BsLocaleService,
    private instiService: InstitutionService,
    private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService,
    private modalService: BsModalService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.sizeModal = this.isMobile ? 'modal-dialog-centered full-screen-modal' : 'modal-dialog-centered modal-md';
    this.onClose = new Subject();

    this.currencies = this.currencieService.loadCurrencies();
    this.currencie = JSON.parse(sessionStorage.getItem('currencie'));
    this.institution = JSON.parse(sessionStorage.getItem('institution'));
    this.language.codeIso = this.translateService.getDefaultLang();
    this.bsModalRef.setClass(this.modalCss);
    this.configs = agentConfiguration(this.institution.agent.configurations);
    if (this.configs.AllowChangeCurrency === 'false') {
      this.disableCurrency = true;
    } else {
      this.disableCurrency = false;
    }

    if (this.configs.AllowChangeCurrency !== 'false') {
      if (this.router.url.includes('/home') || this.router.url.includes('/flight-list') || this.router.url.includes('/hotel-list')) {
        this.disableCurrency = false;
      } else {
        this.disableCurrency = true;
      }
    }

    this.instiSubscription = this.instiService.getInstitutionPropagate().subscribe(institution => {
      this.configs = agentConfiguration(institution.agent.configurations);
      if (this.configs.AllowChangeCurrency === 'false') {
        this.disableCurrency = true;
      } else {
        this.disableCurrency = false;
      }
    });

    this.disableCurrencySubscription = this.instiService.getDisableCurrencyPropagate().subscribe(disable => {
      if (disable) {
        this.disableCurrency = true;
      } else {
        this.disableCurrency = false;
      }
    });

    this.form = this.formBuilder.group({
      email: ['', [Validators.required]],
      code: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  chatConfig(): void {
    const user = this.authService.decodePayloadJWT();
    this.ngxZendeskWebwidgetService.zE('webWidget', 'identify', {
      name: `${user.FullName}`,
      email: `${user.email}`,
      organization: `${this.institution.applicationName}`
    });
    const flag = user.IsAgencyOwner ? 'AGENT' : '';
    this.ngxZendeskWebwidgetService.zE('webWidget', 'chat:addTags', [flag]);
  }

  ngOnDestroy(): void {
    this.instiSubscription.unsubscribe();
    this.disableCurrencySubscription.unsubscribe();
  }

  changeLanguage(language: LanguageModel): void {
    this.language = language;
    this.currencie.defaultLanguage = language.codeIso;
    this.translateService.use(language.codeIso);
    this.translateService.setDefaultLang(language.codeIso);
    this.bsLocaleService.use(language.codeIso);
    sessionStorage.setItem('currencie', JSON.stringify(this.currencie));
    localStorage.setItem('lang', this.language.codeIso);
    this.languageService.setLanguageId(language?.id);
    this.document.documentElement.lang = language?.codeIso;
    this.reloadPage();
    this.bsModalRef.content.callback(false, this.language)
    this.bsModalRef.hide();
  }

  changeCurrencie(currencie: any): void {
    this.currencie = currencie;
    this.language = this.language;
    this.currencie.defaultLanguage = this.language.codeIso;
    this.translateService.use(this.language.codeIso);
    this.translateService.setDefaultLang(this.language.codeIso);
    this.bsLocaleService.use(this.language.codeIso);
    sessionStorage.setItem('currencie', JSON.stringify(currencie));
    this.currencieService.propagateCurrencieToSearch(this.currencie);
    localStorage.setItem('lang', this.language.codeIso);
    this.bsModalRef.hide();
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    if (this.verifyTokenExpireFromGuard) {
      this.onClose.next(false);
    } else {
      this.bsModalRef.content.callback(false);
    }
    this.bsModalRef.hide();
  }

  login(): void {
    this.bsModalRef.content.callback(true);
  }

  reset(): void {
    this.isLogin = false;
  }

  reloadPage(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([location.pathname]);
  }

  sentEmail(resend?: string): void {
    this.formSubmitted = true;

    if (resend) {
      if (!this.form.controls.email.valid) {
        this.form.controls.email.markAsTouched();
        this.notificationService.messageError(this.translateService.instant('messages.required.fields'));
        return;
      }
    } else {
      if (!this.form.controls.email.valid) {
        this.form.controls.email.markAsTouched();
        this.notificationService.messageError(this.translateService.instant('messages.required.fields'));
        return;
      }
    }

    this.buttonLoading = true;

    const email = this.form.value.email;

    if (!!email) {
      const authCode: AuthCode = {
        email
      };

      this.authService.sendCode(authCode).subscribe((result: any) => {
        this.isSentEmail = true;
        this.formSubmitted = false;
        this.buttonLoading = false;
      });
    }
  }

  resetPassword(): void {
    this.bsModalRef.content.callback(true);
  }

  public validate(): void {
    this.formSubmitted = true;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.notificationService.messageError(this.translateService.instant('messages.required.fields'));
      return;
    }

    const email = this.form.value.email;
    const code = this.form.value.code;

    if (!!code) {
      const dateTimezone = dayjs(new Date());
      const authCode: AuthCode = {
        email,
        authenticationCode: code,
        agentId: this.institution.agent.id,
        dateTimeClient: dateTimezone.format()
      };

      this.authCodeToTerms = authCode;

      this.authService.validateCode(authCode).subscribe((result: any) => {
        if (!!result && result.success) {
          const userSession: UserSession = { token: result.data.token };
          localStorage.setItem('userSessionToken', JSON.stringify(userSession));
          localStorage.setItem('agencyPath', this.institution.agent.agencyPath);
          this.authService.autoLogin();
          if (this.verifyTokenExpireFromGuard) {
            this.onClose.next(true);
          } else {
            this.bsModalRef.content.callback(true);
          }
          this.chatConfig();
          this.checkTermsAndConditions();
        } else {
          this.notificationService.messageError(this.translateService.instant(Messages.INVALID_CODE));
        }
      }, (error: any) => {
        this.notificationService.messageError(this.translateService.instant(Messages.INVALID_CODE));
      });
    }
  }

  checkTermsAndConditions(): void {
    const user = this.authService.decodePayloadJWT();
    if (user.IsAgencyOwner === 'True' && user.AcceptedTermsAndContract === 'False') {
      this.openModalTermsConditions();
    }
  }

  openModalTermsConditions(): void {
    this.modalRef2 = this.modalService.show(ModalTermsConditionsComponent,
      {
        backdrop: true,
        animated: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          callback: (result: boolean) => {
            if (result) {
              this.validateAfterTermsConditions(this.authCodeToTerms);
            }
          }
        }
      });
  }

  validateAfterTermsConditions(authCodeToTerms: any): void {
    this.authService.validateCode(authCodeToTerms).subscribe((result: any) => {
      if (!!result && result.success) {
        const userSession: UserSession = { token: result.data.token };
        localStorage.setItem('userSessionToken', JSON.stringify(userSession));
      } else {
        this.notificationService.messageError(this.translateService.instant(Messages.INVALID_CODE));
      }
    }, (error: any) => {
      this.notificationService.messageError(this.translateService.instant(Messages.INVALID_CODE));
    });
  }

}
