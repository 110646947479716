import { Institution } from './../../../models/institution.model';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { LoaderModel } from './../model/loader.model';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<LoaderModel>();
  public loaderModel = this.loaderSubject.asObservable();

  private loaderSetInstitutionSubject = new Subject<LoaderModel>();
  public loaderInstitution = this.loaderSetInstitutionSubject.asObservable();

  constructor(private spinner: NgxSpinnerService) { }

  show(): void {
    this.loaderSubject.next({ show: true } as LoaderModel);
    this.spinner.show();
  }

  hide(): void {
    this.loaderSubject.next({ show: false } as LoaderModel);
    this.spinner.hide();
  }

  setInstitution(institution: Institution): void {
    this.loaderSetInstitutionSubject.next({
      show: true,
      institution
    } as LoaderModel);
  }
}
