import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Journey } from 'src/app/components/flight-list/models/journey.model';
import { Currencie } from '@shared/models/currencie.model';
import { BaggageAllowance } from 'src/app/components/flight-list/models/baggage-allowance.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { DateUtil } from 'src/app/core/utils/date-util';

@Component({
  selector: 'app-flight-card-departure',
  templateUrl: './flight-card-departure.component.html',
  styleUrls: ['./flight-card-departure.component.scss'],
})
export class FlightCardDepartureComponent extends BaseComponent implements OnInit {
 
  @Input() path: string;
  allowBagType: string;
  currencie: Currencie;
  handBag: BaggageAllowance;
  baggage: BaggageAllowance;
  flightDetailExpanded = false;
  bagTypeDescription: string;
  bagUnitType: string;
  dateConverted: Date;
  bagList = [];
  daysDiff: number;
  daysDiffDate: Date;
  showDaysDiffPop = false;
  @Output() functionBind = new EventEmitter<any>();

  @Input() flight: any;
  @Input() fareId: string;

  private _journey: Journey;
  get journey(): Journey {
    return this._journey;
  }

  @Input() set journey(value: Journey) {
    this._journey = value;
  }

  constructor(
    injector: Injector,
    private translateService: TranslateService
  ) {
    super(injector);
    this.currencie = JSON.parse(sessionStorage.getItem('currencie'));
    this.bagTypeDescription = '';
    this.bagUnitType = '';
  }

  ngOnInit(): void {
    this.convertDate(this.journey?.departure.date);
    this.checkBaggageAllowance(this.journey?.baggagesAllowance);
    const dateDeparture = this.journey.departure.date;
    const dateDestination = this.journey.destination.date;

    this.daysDiffDate = DateUtil.convertYearMonthDayToDate(dateDestination.year, dateDestination.month, dateDestination.day)
    this.diffDays(dateDeparture.year, dateDeparture.month, dateDeparture.day, dateDestination.year, dateDestination.month, dateDestination.day);
  }

  checkBaggageAllowance(bag: BaggageAllowance[]): any {
    let bagDescription = '';
    let handBagUnit = '';

    if (bag.length === 0) {
      this.bagList.push(
        {
          iconName: 'backpack',
          quantity: 0,
          description: this.translateService.instant('flight-fare-modal.type.default')
        },
        {
          iconName: 'baggage',
          quantity: 0,
          description: this.translateService.instant('flight-fare-modal.type.default')
        });
      return;
    }

    this.handBag = bag.find(b => b.typeDescription === 'Hand' && (b.quantity > 0 || b.weight > 0));
    this.baggage = bag.find(b => b.typeDescription === 'Dispatch' && (b.quantity > 0 || b.weight > 0));

    bag.forEach(b => {
      b.unitDescription = b.unitDescription === 'PC' && b.weight > 0 ? 'KG' : b.unitDescription;
      bagDescription = this.getDescriptionBag(b);
      handBagUnit = this.getBaggageType(b);

      this.bagList.push({
        iconName: b.typeDescription === 'Hand' ? 'backpack' : 'baggage',
        quantity: b.weight > 0 ? b.weight : b.quantity,
        description: `${bagDescription} ${handBagUnit}`
      });
    });
  }


  convertDate({ year, month, day }): any {
    this.dateConverted = new Date(year, month - 1, day);
  }

  openModalFlightFare(): any {
    this.functionBind.emit({ flight: this.flight, fareId: this.fareId });
  }

  expandModalFlightDetailContent(): void {
    this.flightDetailExpanded = !this.flightDetailExpanded;
  }

  getDescriptionBag(bag): string {

    let description = '';
    switch (bag.unitDescription) {
      case 'PC':
        description = this.translateService.instant('flight-fare-modal.type.pc');
        break;
      case 'KG':
        description = this.translateService.instant('flight-fare-modal.type.kg', {
          kg: bag.weight,
        });
        break;
      case 'LB':
        description = this.translateService.instant('flight-fare-modal.type.lb');
        break;
      default:
        description = this.translateService.instant('flight-fare-modal.type.default');
        break;
    }
    return description;
  }

  getBaggageType(bag: BaggageAllowance): string {
    let unitType = '';

    switch (bag.typeDescription) {
      case 'Hand':
        unitType = this.translateService.instant('flight-fare-modal.type.hand');
        break;
      case 'Dispatch':
        unitType = this.translateService.instant('flight-fare-modal.type.dispatch');
        break;
      default:
        unitType = this.translateService.instant('flight-fare-modal.type.default');
        break;
    }
    return unitType;
  }

  diffDays(yearOne: number, monthOne: number, dayOne: number, yearTwo: number, monthTwo: number, dayTwo: number): any {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = DateUtil.convertYearMonthDayToDate(yearOne, monthOne, dayOne);
    const secondDate = DateUtil.convertYearMonthDayToDate(yearTwo, monthTwo, dayTwo);
    this.daysDiff = Math.round(Math.abs((+firstDate - +secondDate) / oneDay));
    this.showDaysDiffPop = this.daysDiff <= 0 ?? false;
  }
}
