import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Currencie } from './../../models/currencie.model';
import { CurrencieService } from './../../services/currencie.service';

@Component({
  selector: 'app-modal-language-currency',
  templateUrl: './modal-language-currency.component.html'
})
export class ModalLanguageCurrencyComponent extends BaseComponent implements OnInit {

  language = '';
  currencie: Currencie;
  currencies: Currencie[] = [];

  languageId = '';
  currencieId: number;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    private translateService: TranslateService,
    private localeService: BsLocaleService,
    private currencieService: CurrencieService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.languageId = this.language;
    this.currencieId = this.currencie.id;
    this.loadCurrencie();
    this.bsModalRef.setClass(this.modalCss);
  }



  loadCurrencie(): void {
    this.currencies = this.currencieService.loadCurrencies();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  useLanguage(value: string): void {
    this.language = value;
    this.translateService.setDefaultLang(value);
    this.localeService.use(value);
  }

  changeLanguage(eventLanguage: any): void {
    this.language = eventLanguage.target.value;
  }

  changeCurrencie(eventCurrencie: any): void {
    const currencie: Currencie = this.currencies.find(element => (element.id === Number.parseInt(eventCurrencie.target.value)));
    this.currencie = currencie;
  }

  save(): void {
    this.bsModalRef.content.callback(this.currencie, this.language);
    this.bsModalRef.hide();
  }

}
