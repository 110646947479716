<div class="modal-header">
    <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{title}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body--float mb-3" style="min-height: 200px;">
    <div class="column">
      <div class="col-sm-12" *ngFor="let info of informations">
        <div style="float:left;width:100%;margin-top: 3%;" *ngIf="info.linkToAirlineFareRules != null && info.type=='Flight'">
          <img *ngIf="info.linkToAirlineFareRules != null && info.type=='Flight'" width="15%" height="30%" alt="" src="{{info.pathLogo}}">
          <span *ngIf="info.linkToAirlineFareRules != null && info.type=='Flight'" style="margin:0 0 0 2%;font-weight: bold;">{{'cancellation-methods.going' | translate}} {{ info.airlineName }} 
              <a *ngIf="info.linkToAirlineFareRules != null && info.type=='Flight'" href="{{info.linkToAirlineFareRules}}" target="blank" style=" cursor:pointer; color:blue; text-decoration:underline;">{{'cancellation-methods.link' | translate}}</a>
          </span>
        </div>
        <!-- <div class="app-f16 text-justify">
          {{ info }}
        </div> -->
      </div>
    </div>
  </div>
  
