import { HotelService } from './../../services/hotel.service';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ExternalDetail } from './external-detail.model';
import { Hotel } from 'src/app/components/hotel-list/models/hotel.model';


@Component({
    selector: 'app-modal-amenities-mobile',
    templateUrl: './modal-amenities-mobile.component.html'
})
export class ModalAmenitiesMobileComponent extends BaseComponent implements OnInit {

    amenities: string[] = [];
    about: string;
    isPolicies = false;
    policiesCheckIn: string;
    policiesCheckOut: string;

    isPackage: boolean;
    searchKey: string;
    hotel: Hotel;
    order: number;
    requestExternal: ExternalDetail;

    hotelSearch: any;
    hotelSearchKey: any;

    localTax: any;

    constructor(
        injector: Injector,
        public bsModalRef: BsModalRef,
        private hotelService: HotelService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.bsModalRef.setClass(this.modalCss);

        this.hotelSearch = JSON.parse(sessionStorage.getItem('hotelSearch'));
        this.hotelSearchKey = JSON.parse(sessionStorage.getItem('hotelSearchKey'));

        const requestRooms = [];

        this.hotel.rooms.forEach(el => {
            requestRooms.push({
                key: el.key,
                numberOfAdults: el.occupancy.MaxAdults ?? el.occupancy.maxAdults,
                numberOfInfant: el.occupancy.MaxInfant ?? el.occupancy.maxInfant,
                numberOfChild: el.occupancy.MaxChild ?? el.occupancy.maxChild,
                agesOfChild: el.quantityPopover
            });
        });

        this.requestExternal = {
            searchKey: this.searchKey,
            hotelId: this.hotel.hotelId,
            requestRooms,
            isPackage: this.isPackage
        };

        this.getExternalDetail();
    }

    public dismiss(): void {
        this.bsModalRef.content.callback(null, null);
        this.bsModalRef.hide();
    }

    getExternalDetail(): void {
        this.hotelService.getExternalDetail(this.requestExternal).subscribe((response: any) => {
            this.localTax = response.data.rooms.filter(room => room.order === this.order);
        }, err => console.log(err));

    }

}
