import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-order-hotel-mobile',
  templateUrl: './modal-order-hotel-mobile.component.html'
})
export class ModalOrderHotelMobileComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  search(): void {
    this.bsModalRef.content.callback(true);
  }

}
