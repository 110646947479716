<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{'modal-order-hotel.title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body--float">
  <div class="form-group">
    <div class="radio">
      <input id="radio-2" name="radio" type="radio">
      <label for="radio-2" class="radio-label f12em">{{'modal-order-hotel.label.promotion' | translate}}</label>
    </div>
    <div class="radio">
      <input id="radio-one-escala" name="radio" type="radio">
      <label for="radio-one-escala" class="radio-label f12em">{{'modal-order-hotel.label.best-price' | translate}}</label>
    </div>
    <div class="radio">
      <input id="radio-two-escalas" name="radio" type="radio">
      <label for="radio-two-escalas" class="radio-label f12em">{{'modal-order-hotel.label.best-rating' | translate}}</label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary ml-1" (click)="accept()">{{'modal-order-hotel.button.search' | translate}}</button>
</div>
