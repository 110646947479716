<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel" *ngIf="typeSearch === '3'">{{ isRoundTrip ? ('modal-date-range-inline.title-flight' | translate) : ('modal-date-range-inline.label.check-in' | translate) }}</h5>
    <h5 class="modal-title" id="exampleModalLabel" *ngIf="typeSearch === '2'">{{ isRoundTrip ? ('modal-date-range-inline.title' | translate) : ('modal-date-range-inline.label.check-in' | translate) }}</h5>
    <h5 class="modal-title" id="exampleModalLabel" *ngIf="typeSearch === '1'">{{ isRoundTrip ? ('modal-date-range-inline.title-flight' | translate) : ('modal-date-range-inline.title-flight-oneway' | translate) }}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body--float d-flex flex-column align-items-center justify-content-center pt-0">

    <div class="button-go-back mt-1" *ngIf="typeSearch === '1'">
        <button class="btn btn-go-back w-50 m-1" [ngClass]="{'active': isRoundTrip }" (click)="onIsRoundTrip(isRoundTrip)">
            {{ 'modal-date-range-inline.label.round-trip' | translate }}
        </button>
        <button class="btn btn-go-back w-50 m-1" [ngClass]="{'active': !isRoundTrip }"
            (click)="onIsRoundTrip(isRoundTrip)">
            {{ 'modal-date-range-inline.label.one-way' | translate }}
        </button>
    </div>

    <ngb-datepicker class="ngb-datepicker-custom" [minDate]="minDate" #dp *ngIf="isRoundTrip"
        (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" navigation="arrows"
        outsideDays="hidden">
    </ngb-datepicker>

    <ngb-datepicker class="ngb-datepicker-custom" [minDate]="minDate" #dp *ngIf="!isRoundTrip"
        (dateSelect)="onDateSelection($event)" [displayMonths]="1" navigation="arrows" outsideDays="hidden">
    </ngb-datepicker>

    <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
        </span>
    </ng-template>
</div>
<div class="modal-footer">
    <div class="col-sm-12 d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-primary btn-sm app-btn w-25 ml-auto font-weight-bold" (click)="accept()">
            {{ 'modal-date-range-inline.button.done' | translate }}
        </button>
    </div>
</div>