import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { DateLanguageFormatPipe } from './date-language-format.pipe';
import { FilterPipe } from './filter.pipe';
import { FormatTimePipe } from './pipeTransform.pipe';
import { TruncatePipe } from './truncate.pipe';
import { SanitizerPipe } from './sanitize.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TruncatePipe,
    CurrencyFormatPipe,
    DateLanguageFormatPipe,
    FilterPipe,
    FormatTimePipe,
    SanitizerPipe
  ],
  exports: [
    TruncatePipe,
    CurrencyFormatPipe,
    DateLanguageFormatPipe,
    FilterPipe,
    FormatTimePipe,
    SanitizerPipe
  ],
  providers: [DatePipe]
})
export class PipeModule { }
