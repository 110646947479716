import { FlightSearch } from './../../models/flight/flight-search.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, Injector, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-traveler',
  templateUrl: './modal-traveler.component.html'
})
export class ModalTravelerComponent extends BaseComponent implements OnInit {

  flightSearch: FlightSearch;
  flightSearchCopy: any;
  hasErros = false;
  message = '';

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bsModalRef.setClass(this.modalCss);
    this.flightSearchCopy = { ...this.flightSearch };
    this.verification();
  }

  addAdult(): void {
    this.flightSearch.adultsCount = this.flightSearch.adultsCount += 1;
    this.verification();
  }

  subtractAdult(): void {
    this.flightSearch.adultsCount = this.flightSearch.adultsCount -= 1;
    this.verification();
  }

  addChildren(): void {
    this.flightSearch.childCount = this.flightSearch.childCount += 1;
    this.verification();
  }

  subtractChildren(): void {
    this.flightSearch.childCount = this.flightSearch.childCount -= 1;
    this.verification();
  }

  addInfants(): void {
    this.flightSearch.infantCount = this.flightSearch.infantCount += 1;
    this.verification();
  }

  subtractInfants(): void {
    this.flightSearch.infantCount = this.flightSearch.infantCount -= 1;
    this.verification();
  }

  verification(): void {
    if ((this.flightSearch.adultsCount + this.flightSearch.childCount) < this.flightSearch.infantCount) {
      this.message = 'general.adult-to-child';
      this.hasErros = true;
      return;
    }
    if (this.getNumTravelersFlight() > 9) {
      this.message = 'general.max-traveler';
      this.hasErros = true;
      return;
    }
    if (this.getNumTravelersFlight() === 0) {
      this.message = 'general.min-traveler';
      this.hasErros = true;
      return;
    }
    this.hasErros = false;
  }

  getNumTravelersFlight(): any {
    return this.flightSearch.adultsCount + this.flightSearch.childCount + this.flightSearch.infantCount;
  }

  public accept(): void {
    this.bsModalRef.content.callback(this.flightSearchCopy);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false, this.flightSearchCopy);
    this.bsModalRef.hide();
  }

  search(): void {
    this.bsModalRef.content.callback(true, this.flightSearch);
  }
}
