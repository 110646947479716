import { Directive, HostListener, HostBinding, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[whenScrolled]',
    inputs: [
        "scrollCallback"
    ]
})
export class WhenScrolledDirective {
    constructor(private _el: ElementRef ) { }

    @Input() scrollCallback: Function;

    public scope: any = {
        onScrollCallback: '&'
    }

    @HostListener('scroll') whenScrolled(): void {
        let raw = this._el.nativeElement;

        if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
            if (this.scrollCallback) {
                this.scrollCallback();
            }
        }
    }
}