<div class="modal-header">
    <h5 class="modal-title font-weight-bold">{{'modal-traveler.label.travelers' | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body--float" style="min-height: 400px;">

    <div class="form-group w-100-mobile">

        <div class="d-flex" *ngIf="hasErros">
            <div class="alert alert-danger w-100" role="alert" style="border-radius: 20px;">
                {{ message | translate }}
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mt-1">
            <div class="label d-flex flex-column">
                <label class="app-f16 app-black p-0 mb-0">{{'modal-traveler.label.adults' | translate}}</label>
            </div>
            <div class="buttons">
                <button (click)="subtractAdult()" class="btn btn-sm btn-outline-secondary btn-outline-circle ml-3 mr-3" [disabled]="flightSearch.adultsCount === 0">
                    <img class="" src="assets/img/minus.svg" alt="">
                </button>
                <span>{{flightSearch.adultsCount}}</span>
                <button (click)="addAdult()" class="btn btn-sm btn-outline-secondary btn-outline-circle ml-3 mr-3">
                    <img class="" src="assets/img/plus.svg" alt="">
                </button>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mt-3">
            <div class="label d-flex flex-column">
                <label class="app-f16 app-black p-0 mb-0">{{'modal-traveler.label.children' | translate}}</label>
                <small class="app-grey app-f10">2 - 11 {{'modal-traveler.label.years' | translate}}</small>
            </div>
            <div class="buttons">
                <button (click)="subtractChildren()" class="btn btn-sm btn-outline-secondary btn-outline-circle ml-3 mr-3" [disabled]="flightSearch.childCount === 0">
                    <img class="" src="assets/img/minus.svg" alt="">
                </button>
                <span>{{flightSearch.childCount}}</span>
                <button (click)="addChildren()" class="btn btn-sm btn-outline-secondary btn-outline-circle ml-3 mr-3">
                    <img class="" src="assets/img/plus.svg" alt="">
                </button>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mt-3">
            <div class="label d-flex flex-column">
                <label class="app-f16 app-black p-0 mb-0">{{'modal-traveler.label.infants' | translate}}</label>
                <small class="app-grey app-f10">0 - 23 {{'modal-traveler.label.months' | translate}}
                    ({{'modal-traveler.label.lap' | translate}})</small>
            </div>
            <div class="buttons">
                <button (click)="subtractInfants()" class="btn btn-sm btn-outline-secondary btn-outline-circle ml-3 mr-3" [disabled]="flightSearch.infantCount === 0">
                    <img class="" src="assets/img/minus.svg" alt="">
                </button>
                <span>{{flightSearch.infantCount}}</span>
                <button (click)="addInfants()" class="btn btn-sm btn-outline-secondary btn-outline-circle ml-3 mr-3">
                    <img class="" src="assets/img/plus.svg" alt="">
                </button>
            </div>
        </div>

    </div>

</div>
<div class="modal-footer">
    <div class="col-sm-12 d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-primary btn-sm app-btn w-25 ml-auto font-weight-bold" (click)="search()" [disabled]="hasErros">
            {{'modal-traveler.button.done' | translate}}
        </button>
    </div>
</div>
