<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{'modal-validate-code.title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="form-group">
      <label>{{'modal-validate-code.label.description' | translate}}</label>
      <input type="text" class="form-control" placeholder="{{'modal-validate-code.placeholder.code' | translate}}"
        [(ngModel)]="code" name="code" id="code">
      <!-- <i class="icon_mail_alt"></i> -->
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-blue ml-1" (click)="resendCode()">{{'modal-validate-code.button.resend' | translate}}</button>
  <button type="button" class="btn btn-primary ml-1" (click)="validate()">{{'modal-validate-code.button.validate' | translate}}</button>
</div>
