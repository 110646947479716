import { Image } from './../../../components/hotel-list/models/image.model';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { NgxGalleryOptions, NgxGalleryImageSize } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
})
export class ModalGalleryComponent extends BaseComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];
  images: Image[] = [];

  constructor(injector: Injector, public bsModalRef: BsModalRef) {
    super(injector);
  }

  ngOnInit(): void {
    const sizeModal = this.isMobile ? 'modal-dialog-centered full-screen-modal' : 'modal-dialog-centered modal-xl';
    this.bsModalRef.setClass(sizeModal);

    this.setGalleryOptions();
    this.setImagesGallery();
  }

  private setImagesGallery(): void {
    this.images.forEach(image => {
      this.galleryImages.push({
        small: image.url,
        medium: image.url,
        big: image.url
      });
    });

  }

  private setGalleryOptions(): void {
    this.galleryOptions = [
      {
        width: '100%',
        height: '800px',
        arrowPrevIcon: 'carousel-gallery carousel-control-prev-icon',
        arrowNextIcon: 'carousel-gallery carousel-control-next-icon',
        closeIcon: 'fa fa-times-circle',
        imageSize: NgxGalleryImageSize.Cover,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnailsColumns: 5,
        thumbnailsRows: 1,
        thumbnailMargin: 2,
        thumbnailsMargin: 2,
        preview: false,
      },
      {
        breakpoint: 544,
        width: '100%',
        height: '100vh',
        arrowPrevIcon: 'carousel-gallery carousel-control-prev-icon',
        arrowNextIcon: 'carousel-gallery carousel-control-next-icon',
        closeIcon: 'fa fa-times-circle',
        imageSize: NgxGalleryImageSize.Cover,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnailsColumns: 2,
        thumbnailsRows: 2,
        thumbnailsPercent: 600,
        imagePercent: 900,
        thumbnailMargin: 2,
        thumbnailsMargin: 2,
        preview: false,
      },
    ];
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null, null);
    this.bsModalRef.hide();
  }
}
