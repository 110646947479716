<footer [style.background-color]="colorFooter" id="footer">
    <div class="container mw-100 pt-2 p-1">
        <div class="row d-flex flex-column">
            <div class="row">
                <div class="col-sm-12 text-white">
                    <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                        <span class="d-flex justify-content-center align-items-center" *ngIf="institution?.agent?.facebookUrl || institution?.agent?.instagramUrl || institution?.agent?.whatsApp">
                            <p class="find-p2d-footer">
                                {{'footer.text.find' | translate}} {{agentP2dName}} {{'footer.text.on-social' | translate}}
                            </p>
                            <a href="{{institution?.agent?.facebookUrl}}" target="_blank" class="pl-3 pr-1" *ngIf="institution?.agent?.facebookUrl">
                                <img src="./assets/img/facebook.png" class="picture-header-footer-social" alt="">
                            </a>
                            <a href="{{institution?.agent?.instagramUrl}}" target="_blank" class="pl-1 pr-1" *ngIf="institution?.agent?.instagramUrl">
                                <img src="./assets/img/instagram.png" class="picture-header-footer-social" alt="">
                            </a>
                            <a href="https://api.whatsapp.com/send?phone={{institution?.agent?.whatsApp}}" target="_blank" class="pl-1" *ngIf="institution?.agent?.whatsApp">
                                <img src="./assets/img/whatsapp.png" class="picture-header-footer-social" alt="">
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <ul class="links d-flex justify-content-center align-items-center">
                        <li class="text-white pl-3 pr-3 pl-sm-5 pr-sm-5" *ngFor="let item of termsArr; last as isLast">
                            <a href="{{item.url}}" target="_blank">
                                {{ getTermNameToTranslate(item.name) | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row opacityFooterLastLine" [style.background-color]="colorFooter">
                <div class="rights col-sm-12 mb-2 mt-2 d-flex flex-row justify-content-center align-items-center">
                    <div class="text-center text-sm-center">
                        <span class="text-white all-rights">
                            {{ copyRight }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>