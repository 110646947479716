import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retryWhen, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from './../../shared/components/loader/service/loader.service';

export class BaseService<T, ID> {

    protected base: string;
    protected http: HttpClient;
    protected loaderService: LoaderService;
    protected httpOptions: {
        headers: HttpHeaders
    };

    constructor(
        protected injector: Injector,
        protected baseUrl: string,
        protected options?: { headers: HttpHeaders }
    ) {
        this.http = injector.get(HttpClient);
        this.loaderService = injector.get(LoaderService);
        this.base = `${environment.apiBaseUrl}`;
        if (!!this.baseUrl) {
            this.base += `${baseUrl}`;
        }

        if (!options) {
            this.httpOptions = {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
        } else {
            this.httpOptions = options;
        }
    }

    get(complementUrl?: string, showLoader: boolean = false): Observable<T> {
        if (showLoader && this.loaderService) {
            this.loaderService.show();
        }
        return this.http.get<any>(this.base + this.getComplementUrl(complementUrl), this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    post(t: any, complementUrl?: string, showLoader: boolean = true): Observable<T> {
        if (showLoader && this.loaderService) {
            this.loaderService.show();
        }
        return this.http.post<any>(this.base + this.getComplementUrl(complementUrl), t, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    postRetry(t: any, complementUrl?: string, showLoader: boolean = true): Observable<any> {
        const retryLimit = 2;
        let attempt = 0;

        if (showLoader && this.loaderService) {
            this.loaderService.show();
        }
        return this.http.post<any>(this.base + this.getComplementUrl(complementUrl), t, this.httpOptions)
            .pipe(
                retryWhen(errors => errors.pipe(
                    tap(error => {
                        console.log(error.status);
                        if (++attempt >= retryLimit || (
                            error.status !== 500 &&
                            error.status !== 501 &&
                            error.status !== 502 &&
                            error.status !== 503 &&
                            error.status !== 504 &&
                            error.status !== 505 &&
                            error.status !== 506 &&
                            error.status !== 507 &&
                            error.status !== 508 &&
                            error.status !== 509 &&
                            error.status !== 510
                        )) {
                            throw error;
                        }
                    })
                )),
                catchError(this.handleError)
            );
    }

    postFormData(formData: FormData, complementUrl?: string): Observable<any> {
        if (this.loaderService) {
            this.loaderService.show();
        }
        return this.http.post<any>(this.base + this.getComplementUrl(complementUrl), formData)
            .pipe(
                catchError(this.handleError)
            );
    }

    put(id: ID, t: T, complementUrl?: string): Observable<any> {
        if (this.loaderService) {
            this.loaderService.show();
        }
        let complement = '';
        if (!!id) {
            complement = `${this.getComplementUrl(complementUrl)}/${id}`;
        } else {
            complement = this.getComplementUrl(complementUrl);
        }
        return this.http.put<T>(`${this.base}${complement}`, t, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    patch(id: ID, t: any, complementUrl?: string): Observable<T> {
        return this.http.patch<any>(`${this.base}${this.getComplementUrl(complementUrl)}/${id}`, t, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    delete(id: ID): Observable<T> {
        if (this.loaderService) {
            this.loaderService.show();
        }
        return this.http.delete<any>(`${this.base}/${id}`, this.options)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteStringParam(id: any): Observable<T> {
        if (this.loaderService) {
            this.loaderService.show();
        }
        return this.http.delete<any>(`${this.base}/${id}`, this.options)
            .pipe(
                catchError(this.handleError)
            );
    }

    findOne(id: ID): Observable<T> {
        if (this.loaderService) {
            this.loaderService.show();
        }
        return this.http.get<any>(`${this.base}/${id}`, this.options).pipe(
            catchError(this.handleError)
        );
    }

    search(page: any, queryString: any, complementUrl?: string): Observable<T> {
        if (this.loaderService) {
            this.loaderService.show();
        }
        return this.http.get<any>(
            `${this.base}${this.getComplementUrl(complementUrl)}/paginado/${page}?${queryString}`, this.options
        )
            .pipe(
                catchError(this.handleError)
            );
    }

    private getComplementUrl(complementUrl: string): string {
        return complementUrl ? complementUrl : '';
    }

    protected handleError(err: any): any {
        return throwError(err);
    }

}
