import { BaseComponent } from 'src/app/core/components/base/base-component';
import { Component, OnInit, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-3ds',
  templateUrl: './modal-3ds.component.html',
  styleUrls: ['./modal-3ds.component.scss']
})
export class Modal3dsComponent extends BaseComponent implements OnInit {

  data: any;
  safeUrl: SafeResourceUrl;

  constructor(injector: Injector, public bsModalRefUpdate: BsModalRef, private sanitizer: DomSanitizer) {
    super(injector);
  }

  ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.payments.payments[0].creditCard.threeDSecureLink);
  }

  public accept(): void {
    this.bsModalRefUpdate.content.callback(true);
    this.bsModalRefUpdate.hide();
  }

  public dismiss(): void {
    this.bsModalRefUpdate.content.callback(false);
    this.bsModalRefUpdate.hide();
  }
}
