import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { PointOfInterestService } from '@shared/services/point-of-interest.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/components/base/base-component';
import { CityHotel } from '../../models/city-hotel.model';

@Component({
  selector: 'app-autocomplete-city-hotel',
  templateUrl: './autocomplete-city-hotel.component.html',
  styleUrls: ['./autocomplete-city-hotel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutoCompleteCityHotelComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild('cityHotelSearchInput', { static: false }) cityHotelSearchInput: ElementRef;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() inputplaceholder: string;
  @Input() isInvalid: boolean;
  @Output() selectCityHotel = new EventEmitter();

  cityHotel: CityHotel;
  searchedCityHotels: CityHotel[] = [];
  isFetchingCityHotels = false;
  isOpenAutoComplete = false;
  isSelected = false;
  @ViewChildren('anchor') anchorList: QueryList<ElementRef>;

  public inputValue: string;

  constructor(
    private pointOfInterestService: PointOfInterestService,
    injector: Injector
    ) {
      super(injector);
  }

  ngOnInit(): void { }

  focusOnCity(e): void {
    this.anchorList.first.nativeElement.focus();
    e.preventDefault();
  }

  focusListCity(e, i): void {
    const elementRef = this.anchorList.find((item, index) => index === i);
    if (elementRef) {
      elementRef.nativeElement.focus();
      e.preventDefault();
    }
  }

  onKeyUp(): void {
    fromEvent(this.cityHotelSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      if (text.length > 3) {
        this.isSelected = false;
        this.isFetchingCityHotels = true;
        this.pointOfInterestService.searchPointOfInterest(text).subscribe((result: any) => {
          this.isOpenAutoComplete = true;
          this.isFetchingCityHotels = false;
          this.searchedCityHotels = result.data;

        });
      } else {
        this.isOpenAutoComplete = false;
        this.searchedCityHotels = [];
      }
    });
  }

  selectCity(cityHotel: CityHotel): void {
    this.isSelected = true;
    this.isOpenAutoComplete = false;
    this.cityHotel = cityHotel;
    this.selectCityHotel.emit(cityHotel);
  }

  onChanged(): void {
    if (!this.inputValue) {
      this.selectCityHotel.emit('');
    }
  }

  removeCity(): void {
    this.cityHotel = null;
  }

  ngAfterViewInit(): void {
    this.onKeyUp();
    if (this.isMobile) {
      setTimeout(() => {
        this.cityHotelSearchInput.nativeElement.focus();
      }, 500);
    }
  }

}
