<div class="modal-body modal-body--float mb-3" style="min-height: 400px;">
  <div class="column">
    <div class="d-flex mb-3">
      <p class="mb-0 app-f16-22 m-3 text-center font-weight-bold">
        {{'choose-agent.title' | translate}}
      </p>
    </div>
    <div class="col-sm-12">
      <div class="content" role="main">
        <div class="card card-round">
          <div class="form-group">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>{{'choose-agent.input-search' | translate}}</mat-label>
              <input #searchTextElement matInput id="search-text" aria-describedby="search-text" [(ngModel)]="searchText" autofocus>
            </mat-form-field>
          </div>
          <ul whenScrolled [scrollCallback]="loadMore.bind(this)" class="list-group list-group-flush div-scroll" style="max-height: 400px;overflow-y: scroll;">
            <li class="list-group-item app-li-border-first-last border-0 d-flex align-items-center app-pointer"
              *ngFor="let c of characters" (click)="goAgency(c)">
              <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-center">
                  <img class="picture-header" [src]="c.agencyLogo" />
                  <p class="mb-0 app-f16 ml-2">{{c.agencyName}}</p>
                </div>
                <div class="d-flex align-items-center">
                  <span class="icon-angle-right app-f24" (click)="goAgency(c)"></span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</div>