<div id="sign-in-dialog" class="zoom-anim-dialog ">
  <div class="sign-in-wrapper mt-sm-4 mt-5">
    <div class="d-flex flex-row justify-content-center mt-3">
      <img src="assets/img/terms.svg" width="150px" alt="">
    </div>

    <div class="d-flex flex-row justify-content-center mt-3">
      <h4 class="app-f18 text-center">
        {{'modal-terms.title-text' | translate}}
        <a class="font-weight-bold" style="border: none;font-size: 20px;" *ngFor="let item of terms; last as isLast"
          href="{{item.url}}" target="_blank">
          {{ getTermNameToTranslate(item.name) | translate }}<span *ngIf="!isLast">, </span>
        </a>
      </h4>
    </div>

    <div class="d-flex flex-row justify-content-center mt-3">
      <input class="form-check-input" type="checkbox" [checked]="checkedTerms" (click)="setAcceptedTerms()">
      <label class="form-check-label" (click)="setAcceptedTerms()">
        {{'modal-terms.confirm-check' | translate}}
      </label>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-danger w-50 font-weight-bold app-uppercase mr-1" (click)="onOpenWhatsApp()">
          {{'modal-terms.not-now' | translate}}
        </button>
        <button type="button" class="btn btn-success w-50 font-weight-bold app-uppercase ml-1" (click)="onAcceptTerms()"
          [disabled]="!checkedTerms">
          {{'modal-terms.agree' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>