<div class="modal-header">
    <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{'modal-amenities-mobile.detail' | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body--float mb-3">
    <div class="column mt-3" *ngIf="amenities.length > 0">
        <div class="col-sm-12">
            <div class="app-f20 font-weight-bold">{{'modal-amenities-mobile.title' | translate}}</div>
            <div class="row mr-0 ml-0 d-flex justify-content-between mt-3">
                <span class="d-flex justify-content-center" *ngFor="let item of amenities">
                    {{ item | translate }} &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </div>
        </div>
    </div>

    <div class="column mt-3">
        <div class="col-sm-12">
            <hr>
            <div class="app-f20 font-weight-bold">{{'modal-amenities-mobile.label.about' | translate}}</div>
            <div class="app-f16 text-justify mt-3">
                {{ about }}
            </div>
        </div>
    </div>
    <div class="column mt-3" *ngIf="isPolicies">
        <div class="col-sm-12">
            <hr>
            <div class="app-f20 font-weight-bold">{{'modal-amenities-mobile.label.accommodation-conditions' |
                translate}}</div>
            <div class="app-f16 text-justify mt-3">
                {{'modal-amenities-mobile.label.policies-checkin' | translate: { checkIn: this.policiesCheckIn} }}
                <br />
                {{'modal-amenities-mobile.label.policies-checkout' | translate: { checkOut: this.policiesCheckOut} }}
            </div>
        </div>
    </div>
    <div class="column mt-3" *ngIf="localTax">
        <div class="col-sm-12" *ngIf="localTax[0]?.providerTextDescripion || localTax[0]?.localTaxValue > 0">
            <hr>

            <span *ngIf="localTax[0]?.providerTextDescripion">
                <div class="app-f20 font-weight-bold">
                    {{'modal-amenities-mobile.label.term-conditions' | translate}}
                </div>
                <div class="app-f16 text-justify mt-3">
                    <div [innerHTML]="localTax[0]?.providerTextDescripion"></div>
                </div>
            </span>

            <span *ngIf="localTax[0]?.localTaxValue > 0">
                <div class="app-f20 font-weight-bold mt-3">
                    {{'modal-amenities-mobile.label.local-tariff' | translate}}
                </div>
                <div class="app-f16 text-justify mt-3">
                    {{'modal-amenities-mobile.label.local-value' | translate}} {{localTax[0]?.localTaxCurrency}} {{ localTax[0]?.localTaxValue  | currency:'':'' }}
                </div>
            </span>

        </div>
    </div>
</div>