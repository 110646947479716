<div class="modal-header d-flex flex-row justify-content-between">
  <h5 class="modal-title">{{'modal-filter-hotel.title' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>
<div class="modal-body modal-body--float ml-3 mr-3">

  <div class="row">
    <div class="col-sm-12">
      <div class="app-f16 app-black">{{'modal-filter-hotel.label.name' | translate}}</div>
      <div class="d-flex mt-3">
        <!-- <input type="text" class="form-control app-input" placeholder="{{'modal-filter-hotel.label.name' | translate}}"
          [(ngModel)]="hotelSearchKey.filter.hotelName" /> -->

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'modal-filter-hotel.label.name' | translate}}
            </mat-label>
            <input matInput [(ngModel)]="hotelSearchKey.filter.hotelName">
          </mat-form-field>
      </div>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-sm-12">
      <div class="app-f16 app-black">{{'hotel-list.label.order' | translate}}</div>
      <div class="d-flex flex-column mt-3">
        <div class="custom-control custom-radio d-flex justify-content-start">
          <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input"
            (click)="priceOrdenation('lowest')" [checked]="hotelSearchKey?.filter?.lowestPriceOrdenation">
          <label class="custom-control-label" for="customRadio3">{{'hotel-list.label.order-lowest-price' |
            translate}}</label>
        </div>
        <div class="custom-control custom-radio d-flex justify-content-start">
          <input type="radio" id="customRadio4" name="customRadio" class="custom-control-input"
            (click)="priceOrdenation('highest')" [checked]="hotelSearchKey?.filter?.highestPriceOrdenation">
          <label class="custom-control-label" for="customRadio4">{{'hotel-list.label.order-biggest-price' |
            translate}}</label>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-sm-12">
      <div class="app-f16 app-black">{{'modal-filter-hotel.label.stars' | translate}}</div>
      <div class="row mt-3">
        <div class="col-sm-12">
          <div class="stars d-flex flex-row justify-content-between">
            <span class="item b-light d-flex align-items-center" (click)="setNumStar(1)"
              [ngClass]="{'active-star' : this.hotelSearchKey.filter.stars.includes(1)}">
              1 <i class="app-icon-star"></i>
            </span>
            <span class="item b-light d-flex align-items-center" (click)="setNumStar(2)"
              [ngClass]="{'active-star' : this.hotelSearchKey.filter.stars.includes(2)}">
              2 <i class="app-icon-star"></i>
            </span>
            <span class="item b-light d-flex align-items-center" (click)="setNumStar(3)"
              [ngClass]="{'active-star' : this.hotelSearchKey.filter.stars.includes(3)}">
              3 <i class="app-icon-star"></i>
            </span>
            <span class="item b-light d-flex align-items-center" (click)="setNumStar(4)"
              [ngClass]="{'active-star' : this.hotelSearchKey.filter.stars.includes(4)}">
              4 <i class="app-icon-star"></i>
            </span>
            <span class="item b-light d-flex align-items-center" (click)="setNumStar(5)"
              [ngClass]="{'active-star' : this.hotelSearchKey.filter.stars.includes(5)}">
              5 <i class="app-icon-star"></i>
            </span>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="app-f16 app-black">{{'modal-filter-hotel.label.price-per-night' | translate}}</div>
      <div class="row">
        <div class="col-sm-12">
          <div class="price-hotel mt-3">
            <ngx-slider (userChangeEnd)="onUserChange($event)" [(value)]="priceRangeStart"
              [(highValue)]="priceRangeStop" [options]="optionsSlider"></ngx-slider>

            <!-- <div class="row d-flex flex-row justify-content-around align-items-center text-center">
              <div class="form-group col-sm-6 w-35">
                <label for="from" class="app-f10">De</label>
                <input class="form-control form-control-sm app-input-sm-outline text-center app-f10" id="from"
                  type="text" placeholder="" value="">
                <span class="unit app-f10">{{currencie.currencie}}</span>
              </div>
              <div class="form-group col-sm-6 w-35">
                <label for="until" class="app-f10">Até</label>
                <input class="form-control form-control-sm app-input-sm-outline text-center app-f10" id="until"
                  type="text" placeholder="" value="">
                <span class="unit app-f10">{{currencie.currencie}}</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="app-f16 app-black">{{'modal-filter-hotel.label.meals' | translate}}</div>
      <div class="row mt-3">
        <div class="col-sm-12">
          <div class="meal">
            <div class="form-check pl-0 pb-1">
              <input class="form-check-input" type="checkbox" value="" id="breakfast-mobile"
                [(ngModel)]="hotelSearchKey.filter.bedBreakfast">
              <label class="form-check-label" for="breakfast-mobile">
                {{'modal-filter-hotel.label.breakfast' | translate}}
              </label>
            </div>
            <div class="form-check p-0 pb-1">
              <input class="form-check-input" type="checkbox" value="" id="halfpension-mobile"
                [(ngModel)]="hotelSearchKey.filter.halfBoard">
              <label class="form-check-label" for="halfpension-mobile">
                {{'modal-filter-hotel.label.half-board' | translate}}
              </label>
            </div>
            <div class="form-check p-0 pb-1">
              <input class="form-check-input" type="checkbox" value="" id="allinclusive-mobile"
                [(ngModel)]="hotelSearchKey.filter.allInclusive">
              <label class="form-check-label" for="allinclusive-mobile">
                {{'modal-filter-hotel.label.all-inclusive' | translate}}
              </label>
            </div>
            <div class="form-check p-0 pb-1">
              <input class="form-check-input" type="checkbox" id="fullpension-mobile"
                [(ngModel)]="hotelSearchKey.filter.fullBoard">
              <label class="form-check-label" for="fullpension-mobile">
                {{'modal-filter-hotel.label.full-board' | translate}}
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-sm-12">
      <div class="app-f16 app-black">{{'modal-filter-hotel.label.amenities' | translate}}</div>
      <div class="row mt-3">
        <div class="col-sm-12">
          <div class="meal">
            <div class="form-check p-0 pb-1">
              <input class="form-check-input" type="checkbox" value="" id="parking-mobile"
                [(ngModel)]="hotelSearchKey.filter.parking">
              <label class="form-check-label" for="parking-mobile">
                {{'modal-filter-hotel.label.parking' | translate}}
              </label>
            </div>
            <div class="form-check p-0 pb-1">
              <input class="form-check-input" type="checkbox" value="" id="wifi-mobile"
                [(ngModel)]="hotelSearchKey.filter.wifi">
              <label class="form-check-label" for="wifi-mobile">
                {{'modal-filter-hotel.label.wifi' | translate}}
              </label>
            </div>
            <div class="form-check p-0 pb-1">
              <input class="form-check-input" type="checkbox" value="" id="pub-mobile"
                [(ngModel)]="hotelSearchKey.filter.bar">
              <label class="form-check-label" for="pub-mobile">
                {{'modal-filter-hotel.label.bar' | translate}}
              </label>
            </div>
            <div class="form-check p-0 pb-1">
              <input class="form-check-input" type="checkbox" value="" id="pool-mobile"
                [(ngModel)]="hotelSearchKey.filter.pool">
              <label class="form-check-label" for="pool-mobile">
                {{'modal-filter-hotel.label.pool' | translate}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

</div>
<div class="modal-footer">
  <div class="col-sm-12 d-flex justify-content-between align-items-center p-0">
    <button type="button" class="btn btn-primary app-btn-orange btn-sm app-btn-round w-50 mr-1 ml-auto font-weight-bold"
      (click)="clear()">
      {{'modal-filter-hotel.button.clear' | translate}}
    </button>
    <button type="button" class="btn btn-primary btn-sm app-btn w-50 ml-auto mr-1 font-weight-bold" (click)="search()">
      {{'modal-filter-hotel.button.search' | translate}}
    </button>
  </div>
</div>