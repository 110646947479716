import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Institution } from 'src/app/shared/models/institution.model';
import { LoaderService } from './../components/loader/service/loader.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router, private loaderService: LoaderService) {
  }

  public navigation(path: string[], showLoader: boolean = false, extras?: any): void {
    const institution: Institution = JSON.parse(sessionStorage.getItem('institution'));
    this.loaderService.setInstitution(institution);
    if (showLoader) {
      this.loaderService.show();
    }

    setTimeout(() => {
      let agentPath = institution.agent.agencyPath;
      agentPath = agentPath.concat(path[0]);
      this.router.navigate([agentPath], { queryParams: extras });
    }, 3500);
  }

}
