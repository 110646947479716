import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  messageSuccess(message: string): void {
    this.toastr.success(message, '');
  }

  messageError(message: string): void {
    this.toastr.error(message, '', { toastClass: 'app-toast-class ngx-toastr' });
  }

  messageInfo(message: string): void {
    this.toastr.info(message, '');
  }

  messageWarn(message: string): void {
    this.toastr.warning(message, '');
  }


}
