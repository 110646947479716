<style>
    ::-webkit-scrollbar {
        width:15px;
        
    }

    ::-webkit-scrollbar-track {
        background:rgb(255, 255, 255);
        border:1px solid black;
    }

    ::-webkit-scrollbar-thumb {
        background: #868686;
    }
</style>
<div class="modal-header">
    <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">
        {{ (informations.length > 0 && informations[0].type == 'Hotel') ? ('checkout-cart.label.hotel-fare' | translate) :
    ('checkout-cart.label.package-fare' | translate) }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body modal-body--float mb-3" style="min-height: 400px;">

    <div class="fixed-header">
        <div class="modal-header">
            <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">
                {{ (informations.length > 0 &&  informations[0].type == 'Hotel') ? ('checkout-cart.label.hotel-fare' | translate) :
        ('checkout-cart.label.package-fare' | translate) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>

    <div style="float:left;" class="column" *ngIf="informations.length > 0">
        <div style="float:left;" class="col-sm-12" *ngFor="let info of informations; let idx = index; let last = last">

            <div style="float: left;">
                <h5 style="float:left; width:100%; margin:5% 0 3% 0" class="modal-title font-weight-bold app-f20" *ngIf="info.type=='Flight' && idx == 0" width="15%" id="fareRulesTitle">{{'cancellation-methods.title' | translate}}</h5>
                <div style="float:left;width:100%;margin:3% 0 3% 0;">
                    <img *ngIf="info.type=='Flight'" width="15%" height="30%" alt="" src="{{info.pathLogo}}">
                    <span *ngIf="info.type=='Flight'" style="margin:0 0 0 2%;font-weight: bold;">{{'cancellation-methods.going' | translate}} {{ info.airlineName }} 
                        <a *ngIf="info.type=='Flight'" href="{{info.linkToAirlineFareRules}}" target="blank" style=" cursor:pointer; color:blue; text-decoration:underline;">{{'cancellation-methods.link' | translate}}</a>
                    </span>
                    
                </div>
            </div>
            <div class="app-divider" *ngIf="info.linkToAirlineFareRules != null && info.type=='Hotel'"></div>
            <div style="float: left;" *ngIf="info.type=='Hotel' && (info.htmlText != null || info.localTaxValue != null)">
                <h5 style="float:left; width:100%; margin:5% 0 3% 0" class="modal-title font-weight-bold app-f20" *ngIf="info.type=='Hotel'" width="15%" id="fareRulesTitle">{{'checkout-cart.label.hotel-fare' | translate}}</h5>
                <div style="float: left;" class="app-f20 font-weight-bold text-justify" *ngIf="info.plainText">
                    {{info.order + 1}} - {{ info.plainText }}
                </div>

                <div style="float: left;overflow:auto; max-height: 40vh;" [innerHTML]="info.htmlText" *ngIf="info.htmlText" class="mt-3 custon-scroll"></div>

                <span style="float: left;" *ngIf="info.localTaxValue > 0">
                    <div class="app-f20 font-weight-bold mt-3">
                        {{'modal-amenities-mobile.label.local-tariff' | translate}}
                    </div>
                    <div style="margin:0 0 2% 0" class="app-f16 text-justify mt-3">
                        {{'modal-amenities-mobile.label.local-value' | translate}} {{info.localTaxCurrency}} {{ info.localTaxValue  | currency:'':'' }}
                    </div>
                </span>

                

                <div style="float: left;" class="app-divider" *ngIf="!last"></div>
            </div>
        </div>
    </div>
</div>