<div class="modal-header">
    <h5 class="modal-title font-weight-bold app-f20" id="exampleModalLabel">{{'modal-hosting-policies.title' | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body--float mb-3">
    <div class="col-sm-12">
        <div class="app-f16 text-justify">
            {{'modal-hosting-policies.label.policies-checkin' | translate: { checkIn: this.policiesCheckIn} }}
            <br/>
            {{'modal-hosting-policies.label.policies-checkout' | translate: { checkOut: this.policiesCheckOut} }} 
        </div>
    </div>
</div>